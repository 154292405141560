import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import {
  ROUTER_ROOT,
  ROUTER_SIGNIN_ROOT,
  ROUTER_AUTH_WELCOME,
  ROUTER_AUTH_PASSWORD_RECOVERY,
  ROUTER_ADMIN_ROOT,
  ROUTER_ADMIN_USERS_NEW,
  ROUTER_PROFILE_PASSWORD,
  ROUTER_PROFILE_SETTING,
  // ROUTER_ROBOT_ROOT,
  ROUTER_ROBOT_ANALYTICS,
  ROUTER_ROBOT_SEARCH,
  ROUTER_ROBOT_VIDEOS,
  ROUTER_ROBOT_REPORTS,
  ROUTER_PERFORMANCE_ROOT,
  // ROUTER_ROBOT_PERFORMANCE,
  ROUTER_ROBOT_STATISTICS,
  ROUTER_ROBOT_HEALTH,
  ROUTER_ROBOT_NOVEYE_LOGS,
  ROUTER_ROBOT_NOVDATA_LOGS,
  ROUTER_ROBOT_WELD_RESULTS,
  ROUTER_ROBOT_SUPPORT,
  ROUTER_WRIKE_KPI,
  ROUTER_ROBOT_ALARM_LIST,
} from "../libs/constants";
import { isLoggedIn, isAdmin, isSupport, isCustomer } from "../libs/auth";

// ##############################
// General Page
// ##############################
import AppLayout from "../components/layout/AppLayout";
import DashboardPage from "../components/DashboardPage";
// import RobotPage from "../components/RobotPage";
import AnalyticsPage from "../components/AnalyticsPage";
import SearchFilesPage from "../components/SearchFilesPage";
import WeldTestReportsPage from "../components/WeldTestReportsPage";

// ##############################
// Support Page
// ##############################
import VideosPage from "../components/VideosPage";
import ReportsPage from "../components/ReportsPage";
import HealthPage from "../components/HealthPage";
import AlarmListPage from "../components/AlarmListPage";
import PerformancePage from "../components/PerformancePage";
import StatisticsPage from "../components/StatisticsPage";
import NovEyeLogsPage from "../components/NovEyeLogsPage";
import NovDataLogsPage from "../components/NovDataLogsPage";
import WrikePage from "../components/WrikePage";
import SupportPage from "../components/SupportPage";

// ##############################
// Admin Page
// ##############################
import CreateUserPage from "../components/admin/CreateUserPage";
import AdminDashboardPage from "../components/admin/AdminDashboardPage";

// ##############################
// Auth Page
// ##############################
import AuthLayout from "../components/layout/AuthLayout";
import SignInPage from "../components/SignInPage";
import WelcomePage from "../components/WelcomePage";
import PasswordResetPage from "../components/PasswordResetPage";
import PasswordChangePage from "../components/PasswordChangePage";
import SettingsPage from "../components/SettingsPage";

// ##############################
// Error Page
// ##############################
import Error401Page from "../components/Error401Page";
import Error404Page from "../components/Error404Page";

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <AuthLayout>
        <Component {...props}></Component>
      </AuthLayout>
    )}
  />
);

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLoggedIn() ? (
        <AppLayout isCustomer={isCustomer()}>
          <Component {...props} isCustomer={isCustomer()} />
        </AppLayout>
      ) : (
        <Redirect to="/signin" />
      )
    }
  />
);

const AdminRoute = ({ ...rest }) =>
  isAdmin() ? (
    <AuthRoute {...rest} />
  ) : (
    <AuthRoute {...rest} component={() => <Error401Page />} />
  );

const NovarcRoute = ({ ...rest }) =>
  isAdmin() || isSupport() ? (
    <AuthRoute {...rest} />
  ) : (
    <AuthRoute {...rest} component={() => <Error401Page />} />
  );

const NoMatch = ({ ...rest }) =>
  isLoggedIn() ? (
    <AuthRoute {...rest} component={() => <Error404Page />} />
  ) : (
    <PublicRoute {...rest} component={() => <Error404Page />} />
  );

const Router = () => (
  <Switch>
    {/* Public Route */}
    <PublicRoute exact path={ROUTER_SIGNIN_ROOT} component={SignInPage} />
    <PublicRoute exact path={ROUTER_AUTH_WELCOME} component={WelcomePage} />
    <PublicRoute
      exact
      path={ROUTER_AUTH_PASSWORD_RECOVERY}
      component={PasswordResetPage}
    />
    <AdminRoute exact path={ROUTER_ADMIN_ROOT} component={AdminDashboardPage} />
    <AdminRoute
      exact
      path={ROUTER_ADMIN_USERS_NEW}
      component={CreateUserPage}
    />

    {/* Private Authenticated Route */}
    <AuthRoute
      exact
      path={ROUTER_PROFILE_PASSWORD}
      component={PasswordChangePage}
    />
    <AuthRoute exact path={ROUTER_ROOT} component={DashboardPage} />
    <AuthRoute exact path={ROUTER_ROBOT_ANALYTICS} component={AnalyticsPage} />
    <AuthRoute exact path={ROUTER_ROBOT_SEARCH} component={SearchFilesPage} />
    <AuthRoute exact path={ROUTER_ROBOT_HEALTH} component={HealthPage} />
    <AuthRoute exact path={ROUTER_ROBOT_ALARM_LIST} component={AlarmListPage} />
    <AuthRoute exact path={ROUTER_ROBOT_SUPPORT} component={SupportPage} />

    <AuthRoute
      exact
      path={ROUTER_ROBOT_WELD_RESULTS}
      component={WeldTestReportsPage}
    />

    <AuthRoute exact path={ROUTER_PROFILE_SETTING} component={SettingsPage} />

    <>
      {/* Admin / Support Authenticated Route */}
      <NovarcRoute
        exact
        path={ROUTER_PERFORMANCE_ROOT}
        component={PerformancePage}
      />
      <NovarcRoute exact path={ROUTER_WRIKE_KPI} component={WrikePage} />

      {/* <AuthRoute exact path={ROUTER_ROBOT_ROOT} component={RobotPage} /> */}
      <NovarcRoute exact path={ROUTER_ROBOT_VIDEOS} component={VideosPage} />
      <NovarcRoute exact path={ROUTER_ROBOT_REPORTS} component={ReportsPage} />

      <NovarcRoute
        exact
        path={ROUTER_ROBOT_NOVEYE_LOGS}
        component={NovEyeLogsPage}
      />
      <NovarcRoute
        exact
        path={ROUTER_ROBOT_NOVDATA_LOGS}
        component={NovDataLogsPage}
      />
      <NovarcRoute
        exact
        path={ROUTER_ROBOT_STATISTICS}
        component={StatisticsPage}
      />
    </>

    <NoMatch path="*" component={Error401Page} />
  </Switch>
);

export default Router;
