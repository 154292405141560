import { createSlice } from "@reduxjs/toolkit";

import { push } from "connected-react-router";

import { getUser, clearUser, setToken } from "../../libs/auth";

import {
  login,
  resetPassword,
  recoverPassword,
  updatePassword,
  register,
} from "../../services/api/auth";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,

    loggingIn: false,
    loggingInError: null,

    resetting: false,
    resettingError: null,

    recovering: false,
    recoveringError: null,

    registering: false,
    registeringError: null,
  },

  reducers: {
    loginRequest: (state) => ({
      ...state,
      loggingIn: true,
      loggingInError: null,
    }),

    loginFailure: (state, action) => ({
      ...state,
      loggingIn: false,
      loggingInError: action.payload,
    }),

    loginSuccess: (state, action) => ({
      ...state,
      loggingIn: false,
      loggingInError: null,
      user: action.payload.user,
    }),

    logout: (state) => ({ ...state, user: null }),

    resetRequest: (state) => ({
      ...state,
      resetting: true,
    }),
    resetFailure: (state, action) => ({
      ...state,
      resetting: false,
      resettingError: action.payload,
    }),
    resetSuccess: (state) => ({
      ...state,
      resetting: false,
      resettingError: null,
    }),

    recoveryRequest: (state) => ({
      ...state,
      recovering: true,
    }),
    recoveryFailure: (state, action) => ({
      ...state,
      recovering: false,
      recoveringError: action.payload,
    }),
    recoverySuccess: (state) => ({
      ...state,
      recovering: false,
    }),

    registerRequest: (state) => ({
      ...state,
      registering: true,
    }),
    registerFailure: (state, action) => ({
      ...state,
      registering: false,
      registeringError: action.payload,
    }),
    registerSuccess: (state) => ({
      ...state,
      registering: false,
    }),
  },
});

export const {
  loginRequest,
  loginFailure,
  loginSuccess,

  logout,

  resetRequest,
  resetFailure,
  resetSuccess,

  recoveryRequest,
  recoveryFailure,
  recoverySuccess,

  registerRequest,
  registerFailure,
  registerSuccess,
} = authSlice.actions;

export default authSlice.reducer;

export const authUser = () => async (dispatch) => {
  const user = getUser();
  dispatch(loginSuccess({ user }));
};

export const loginUser = (email, password) => async (dispatch) => {
  try {
    dispatch(loginRequest());

    const data = await login({ email, password });

    setToken(data.token);

    const user = getUser();

    dispatch(loginSuccess({ user }));
    dispatch(push("/"));
  } catch (err) {
    dispatch(loginFailure(err.toString()));
  }
};

export const logoutUser = () => async (dispatch) => {
  dispatch(logout());
  clearUser();
  dispatch(push("/"));
};

export const resetUserPassword = (token, new_password) => async (dispatch) => {
  try {
    dispatch(resetRequest());

    await resetPassword({ token, new_password });

    setToken(token);

    dispatch(resetSuccess());
    dispatch(push("/"));
  } catch (err) {
    dispatch(resetFailure(err.toString()));
  }
};

export const updateUserPassword =
  (password, new_password) => async (dispatch) => {
    try {
      dispatch(resetRequest());

      await updatePassword({ password, new_password });

      dispatch(resetSuccess());
    } catch (err) {
      dispatch(resetFailure(err.toString()));
    }
  };

export const recoverUserPassword = (email) => async (dispatch) => {
  try {
    dispatch(recoveryRequest());

    await recoverPassword({ email });

    dispatch(recoverySuccess());
  } catch (err) {
    dispatch(recoveryFailure(err.toString()));
  }
};

export const registerUser =
  (email, name, company, group, role) => async (dispatch) => {
    try {
      dispatch(registerRequest());

      await register({ email, name, company, group, role });

      dispatch(registerSuccess());
    } catch (err) {
      dispatch(registerFailure(err.toString()));
    }
  };
