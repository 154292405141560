import React, { Component } from "react";

import { Layout } from "antd";

const { Content } = Layout;

class AuthLayout extends Component {
  render() {
    const { children } = this.props;
    return (
      <Layout
        style={{
          padding: 24,
          height: "inherit",
        }}
      >
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
          }}
        >
          {children}
        </Content>
      </Layout>
    );
  }
}

export default AuthLayout;
