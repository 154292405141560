import axios from "axios";

import {
  API_AUTH_LOGIN,
  API_AUTH_RESET_PASSWORD,
  API_AUTH_REGISTER,
} from "../../libs/constants";

import { getToken } from "../../libs/auth";

// ##############################
// Auth
// ##############################
export async function login(payload) {
  const url = `${API_AUTH_LOGIN}`;
  try {
    const { data, status } = await axios.post(url, { ...payload });

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function resetPassword({ token, new_password }) {
  const url = `${API_AUTH_RESET_PASSWORD}`;

  const options = {
    method: "post",
    headers: { Authorization: `bearer ${token}` },
    url,
    data: { new_password },
  };

  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function updatePassword(payload) {
  const url = `${API_AUTH_RESET_PASSWORD}`;

  const options = {
    method: "put",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
    data: { ...payload },
  };

  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function recoverPassword({ email }) {
  const url = `${API_AUTH_RESET_PASSWORD}?email=${email}`;

  try {
    const { data, status } = await axios.get(url);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}

export async function register(payload) {
  const url = `${API_AUTH_REGISTER}`;

  const options = {
    method: "post",
    headers: { Authorization: `bearer ${getToken()}` },
    url,
    data: { ...payload },
  };

  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}
