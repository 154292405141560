import React from "react";

import Icon from "@ant-design/icons";

import { ReactComponent as AddSvg } from "../../assets/icons/add.svg";
import { ReactComponent as AttachmentSvg } from "../../assets/icons/attachment.svg";
import { ReactComponent as CalendarBackSvg } from "../../assets/icons/calendar_back.svg";
import { ReactComponent as CalendarForthSvg } from "../../assets/icons/calendar_forth.svg";
import { ReactComponent as CloseSvg } from "../../assets/icons/close.svg";
import { ReactComponent as DeleteSvg } from "../../assets/icons/delete.svg";
import { ReactComponent as DownOutlineSvg } from "../../assets/icons/down_outline.svg";
import { ReactComponent as DownloadSvg } from "../../assets/icons/download.svg";
import { ReactComponent as InformationSvg } from "../../assets/icons/information.svg";
import { ReactComponent as MenuClosedSvg } from "../../assets/icons/menu_closed.svg";
import { ReactComponent as MenuOpenSvg } from "../../assets/icons/menu_open.svg";
import { ReactComponent as PlaceholderSvg } from "../../assets/icons/placeholder.svg";
import { ReactComponent as PreviewSvg } from "../../assets/icons/preview.svg";
import { ReactComponent as ProfileSvg } from "../../assets/icons/profile.svg";
import { ReactComponent as RemoveSvg } from "../../assets/icons/remove.svg";

const DEFAULT_STYLE = {
  filter: "invert(1)",
  fontSize: 20,
  verticalAlign: "middle",
};

export const AddIcon = (props) => <Icon component={AddSvg} {...props} />;
export const AttachmentIcon = (props) => (
  <Icon component={AttachmentSvg} {...props} />
);
export const CalendarBackIcon = (props) => (
  <Icon component={CalendarBackSvg} {...props} />
);
export const CalendarForthIcon = (props) => (
  <Icon component={CalendarForthSvg} {...props} />
);
export const CloseIcon = (props) => <Icon component={CloseSvg} {...props} />;
export const DeleteIcon = (props) => <Icon component={DeleteSvg} {...props} />;
export const DownOutlineIcon = (props) => (
  <Icon component={DownOutlineSvg} {...props} />
);
export const DownloadIcon = (props) => (
  <Icon component={DownloadSvg} {...props} />
);
export const InformationIcon = (props) => (
  <Icon component={InformationSvg} {...props} />
);
export const MenuClosedIcon = (props) => (
  <Icon component={MenuClosedSvg} style={DEFAULT_STYLE} {...props} />
);
export const MenuOpenIcon = (props) => (
  <Icon component={MenuOpenSvg} style={DEFAULT_STYLE} {...props} />
);
export const PlaceholderIcon = (props) => (
  <Icon component={PlaceholderSvg} {...props} />
);
export const PreviewIcon = (props) => (
  <Icon component={PreviewSvg} {...props} />
);
export const ProfileIcon = (props) => (
  <Icon component={ProfileSvg} style={DEFAULT_STYLE} {...props} />
);
export const RemoveIcon = (props) => <Icon component={RemoveSvg} {...props} />;
