import { Alert } from "antd";
import Moment from "moment";
import { extendMoment } from "moment-range";
import {useRobotAlarms} from "../../libs/useAlarms";
import {normalizeRobotId} from "../../libs/utils";
const moment = extendMoment(Moment);

export default function StatusBanner(props) {
  const { weldData, showLink, robotName, dateRange } = props;
  const robotId = normalizeRobotId(robotName);
  const data = useRobotAlarms(robotId);

  if (!data) {
    // since this is a banner on the top,
    // hiding it is probably better than showing a loader
    return null;
  }

  const statsData = {
    warning:
      data.filter(
        (item) =>
          moment(item.received_at).isSameOrAfter(
            dateRange.start
          ) &&
          item.type === "warning" &&
          !item.cleared_at
      ).length || 0,
    error:
      data.filter(
        (item) =>
          moment(item.received_at).isSameOrAfter(
            dateRange.start
          ) &&
          item.type === "error" &&
          !item.cleared_at
      ).length || 0,
    crash:
      data.filter(
        (item) =>
          moment(item.received_at).isSameOrAfter(
            dateRange.start
          ) &&
          item.type === "crash" &&
          !item.cleared_at
      ).length || 0,

    weld_done:
      (weldData?.length &&
        weldData.filter((item) =>
          // arc_on_utc currently formatted 2022-09-27T20:41:38.713Z
          moment(item.arc_on_utc).isSameOrAfter(
            dateRange.start
          )
        ).length) ||
      0,
  };

  return (
    <Alert
      style={{ marginBottom: 10 }}
      message={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>
            {statsData.error + statsData.crash + statsData.warning > 0
              ? `SWR status: ${
                  statsData.error + statsData.crash || 0
                } errors, ${
                  statsData.warning || 0
                } warnings reported since yesterday`
              : "SWR status: OK"}
          </span>
          {showLink}
        </div>
      }
      description={" "}
      showIcon
      type={
        statsData.crash + statsData.error > 0
          ? "error"
          : statsData.warning > 0
          ? "warning"
          : "success"
      }
      banner
      closable
    />
  );
}
