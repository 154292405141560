import React, { Component } from "react";

import { Layout } from "antd";

import Sider from "../Sider";
import Header from "../Header";
import Footer from "../Footer";

const { Content } = Layout;

class AppLayout extends Component {
  render() {
    const { children } = this.props;

    return (
      <Layout>
        <Sider {...this.props} />
        <Layout className="site-layout">
          <Header />
          <Content
            className="site-layout-background"
            style={{
              // margin: "24px 16px",
              padding: 8,
              minHeight: 280,
            }}
          >
            {children}
          </Content>
          <Footer />
        </Layout>
      </Layout>
    );
  }
}

export default AppLayout;
