import React from "react";

import { Form } from "antd";

import CreateUser from "../../features/auth/CreateUser";

function CreateUserPage() {
  const [form] = Form.useForm();
  return (
    <div
      style={{
        textAlign: "center",
        verticalAlign: "middle",
        maxWidth: 400,
        top: "50%",
        left: "50%",
        position: "absolute",
        MsTransform: "translate(-50%, -50%)",
        transform: "translate(-50%, -50%)",
      }}
    >
      <CreateUser form={form} />
    </div>
  );
}

export default CreateUserPage;
