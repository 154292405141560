import type { WeldRunWithFiles } from "./robotSlice";
import type { S3File } from "./robotSearchUtils";
import { AutoComplete, Button, Descriptions, Form, Modal } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateRobotWeldFiles } from "./robotSlice";

export default function SubmitWeldFileChange(props: {
  open: boolean;
  close: () => void;
  entry: WeldRunWithFiles;
  videoList: S3File[];
  logList: S3File[];
}) {
  const dispatch = useDispatch();
  const { open, close, entry, videoList, logList } = props;
  const videoNames = videoList.map((e) => ({ value: e.fileName }));
  const logNames = logList.map((e) => ({ value: e.fileName }));
  const [newVidName, setNewVidName] = useState("");
  const [newLogName, setNewLogName] = useState("");
  const [vidNameChangeSuccess, setVidNameChangeSuccess] = useState(false);
  const [logNameChangeSuccess, setLogNameChangeSuccess] = useState(false);
  const newVidLookup = videoList.find((e) => e.fileName === newVidName);
  const newLogLookup = logList.find((e) => e.fileName === newLogName);

  return (
    <>
      <Modal
        className="weld-file-change-modal"
        title={`Change attached video/log file`}
        open={open}
        onOk={close}
        onCancel={close}
        destroyOnClose={true}
        style={{ width: "80%", height: "80%" }}
      >
        {entry ? (
          <>
            <Descriptions>
              <Descriptions.Item label="Weld start">
                {entry.arc_on_utc}
              </Descriptions.Item>
              <Descriptions.Item label="Weld end">
                {entry.arc_off_utc}
              </Descriptions.Item>
              <Descriptions.Item label="Job">
                {entry.work_order_ref}
              </Descriptions.Item>
              <Descriptions.Item label="Spool">
                {entry.spool_ref}
              </Descriptions.Item>
              <Descriptions.Item label="Weld ref">
                {entry.welder_id_ref}
              </Descriptions.Item>
              <Descriptions.Item label="Run num">
                {entry.run_number}
              </Descriptions.Item>
              <Descriptions.Item label="Linked video">
                {entry.vid_filename ?? "None"}
              </Descriptions.Item>
              <Descriptions.Item label="Linked log">
                {entry.log_filename ?? "None"}
              </Descriptions.Item>
            </Descriptions>
            <Form name="submitNewVidName">
              <Form.Item<string>
                label="Change linked video file to"
                name="videoFile"
                rules={[
                  { required: true, message: "Enter valid video filename" },
                ]}
              >
                <AutoComplete
                  options={videoNames}
                  onChange={(val) => {
                    setNewVidName(val);
                    setVidNameChangeSuccess(false);
                  }}
                />
              </Form.Item>

              <Descriptions title="Preview changes">
                <Descriptions.Item label="New filename">
                  {newVidName}
                </Descriptions.Item>
                <Descriptions.Item label="File exists">
                  {newVidLookup ? "YES" : "NO"}
                </Descriptions.Item>
                <Descriptions.Item label="File size">
                  {newVidLookup?.size && newVidLookup.size + " bytes"}
                </Descriptions.Item>
                <Descriptions.Item label="File upload date">
                  {newVidLookup?.uploadedAt}
                </Descriptions.Item>
                <Descriptions.Item label="File preview">
                  {newVidLookup?.url && (
                    <a href={newVidLookup.url} target="_blank">
                      View/Download
                    </a>
                  )}
                </Descriptions.Item>
              </Descriptions>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    dispatch(
                      updateRobotWeldFiles({
                        id: entry.weld_files_id,
                        robot_id: entry.robot_id,
                        vid_filename: newVidName,
                      })
                    );
                    setNewVidName("");
                    setVidNameChangeSuccess(true);
                  }}
                >
                  Change video file
                </Button>
                <div style={{ color: "green" }}>
                  {vidNameChangeSuccess && "Updated!"}
                </div>
              </Form.Item>
            </Form>
            <Form name="submitNewLogName">
              <Form.Item<string>
                label="Change linked log file to"
                name="logFile"
                rules={[
                  {
                    required: true,
                    message: "Enter valid Novdata log filename",
                  },
                ]}
              >
                <AutoComplete
                  options={logNames}
                  onChange={(val) => {
                    setNewLogName(val);
                    setLogNameChangeSuccess(false);
                  }}
                />
              </Form.Item>

              <Descriptions title="Preview changes">
                <Descriptions.Item label="New filename">
                  {newLogName}
                </Descriptions.Item>
                <Descriptions.Item label="File exists">
                  {newLogLookup ? "YES" : "NO"}
                </Descriptions.Item>
                <Descriptions.Item label="File size">
                  {newLogLookup?.size && newLogLookup.size + " bytes"}
                </Descriptions.Item>
                <Descriptions.Item label="File upload date">
                  {newLogLookup?.uploadedAt}
                </Descriptions.Item>
                <Descriptions.Item label="File preview">
                  {newLogLookup?.url && (
                    <a href={newLogLookup.url} target="_blank">
                      View/Download
                    </a>
                  )}
                </Descriptions.Item>
              </Descriptions>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    dispatch(
                      updateRobotWeldFiles({
                        id: entry.weld_files_id,
                        robot_id: entry.robot_id,
                        log_filename: newLogName,
                      })
                    );
                    setNewLogName("");
                    setLogNameChangeSuccess(true);
                  }}
                >
                  Change log file
                </Button>
                <div style={{ color: "green" }}>
                  {logNameChangeSuccess && "Updated!"}
                </div>
              </Form.Item>
            </Form>
          </>
        ) : (
          <div>Error: data not found</div>
        )}
      </Modal>
    </>
  );
}
