import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getProgramData } from "../../services/api/robot";
import { getFromS3URL } from "../../services/api";

import { novDataLogs } from "../../libs/utils";
import * as FetchingHelper from "../../redux/fetchingHelper";
import { removeUndefinedKeys } from "../../libs/utilsTyped";

const sliceName = "novdata_logs";

const ndLogsSlice = createSlice({
  name: sliceName,
  initialState: {
    ...FetchingHelper.getInitialState(),
    logs: null,
    selectedItem: null,
  },

  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(...FetchingHelper.createBasicPendingCase(getRobotNovDataLogs))
      .addCase(
        ...FetchingHelper.createBasicFulfilledCase(getRobotNovDataLogs, "logs")
      )
      .addCase(...FetchingHelper.createBasicRejectedCase(getRobotNovDataLogs))
      .addCase(...FetchingHelper.createBasicPendingCase(getNovDataLogContents))
      .addCase(
        ...FetchingHelper.createBasicFulfilledCase(
          getNovDataLogContents,
          "selectedItem"
        )
      )
      .addCase(
        ...FetchingHelper.createBasicRejectedCase(getNovDataLogContents)
      );
  },
});

export default ndLogsSlice.reducer;

export const getRobotNovDataLogs = createAsyncThunk<
  any,
  { id: string; start?: string; end?: string }
>(`${sliceName}/getRobotNovDataLogs`, async ({ id, start, end }) => {
  const query = removeUndefinedKeys(
    {
      key: "novdata_logs",
      start,
      end,
    },
    ["start", "end"]
  );
  const { data } = await getProgramData(id, query);
  const logs = novDataLogs(data);
  return { data: logs };
});

export const getNovDataLogContents = createAsyncThunk<any, string>(
  `${sliceName}/getNovEyeLogContents`,
  async (url) => {
    return { data: await getFromS3URL(url) };
  }
);
