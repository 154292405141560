import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import {
  getAdminRobotsList,
  createRobotTier,
  updateRobotTier,
  getGroupsList,
} from "./adminSlice";

import { fileSize } from "../../libs/utils";

import {
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Popover,
  Select,
  Table,
  Tag,
  Tooltip,
} from "antd";

import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  InfoCircleOutlined,
  KeyOutlined,
  PlusSquareOutlined,
  WarningOutlined,
} from "@ant-design/icons";

import { isNil } from "lodash";
import { createConsentForm, getConsentForm } from "../../services/api/admin";
import { getRobotInfo } from "../robot/robotSlice";

const { Option } = Select;

const defaultPageSize = 100; // default number of Robots to display
const BYTE_IN_GB = 1073741824; // byte in GB for bandwidth conversion

function SelectTier(props) {
  const { record } = props;
  return (
    <Select
      defaultValue={record.tier && record.tier.tier}
      onChange={props.onChange}
    >
      <Option value="lite">lite (1GB)</Option>
      <Option value="basic">basic (10GB)</Option>
      <Option value="advanced">advanced (100GB)</Option>
    </Select>
  );
}

function SetCapacity(props) {
  const { record } = props;

  const capacity =
    record.tier && record.tier.capacity ? record.tier.capacity / BYTE_IN_GB : 0;

  return (
    <InputNumber
      defaultValue={capacity}
      max={100}
      min={1}
      onChange={props.onChange}
    />
  );
}

function SelectGroups(props) {
  const { record, groups } = props;

  const defaultValues = record.groups
    ? record.groups.map((item) => ({
        ...item,
        label: item.name,
        value: item.id,
        key: item.id,
      }))
    : [];

  const groupsOptions = groups.map((item) => ({
    ...item,
    label: item.name,
    value: item.id,
    key: item.id,
  }));

  return (
    <Select
      mode="multiple"
      placeholder="Please select user(s) to add to the group"
      defaultValue={defaultValues}
      onChange={props.onChange}
      style={{ width: "100%" }}
      labelInValue
    >
      {groupsOptions.map((item) => (
        <Option key={item.id} value={item.id}>
          {item.name}
        </Option>
      ))}
    </Select>
  );
}

function getInputNode(dataIndex, record, options) {
  switch (dataIndex) {
    case "group":
      return <SelectGroups record={record} groups={options.groups} />;

    case "tier.tier":
      return <SelectTier record={record} />;

    case "tier.capacity":
      return <SetCapacity record={record} />;

    default:
      return <Input />;
  }
}

function getTierColor(role) {
  switch (role) {
    case "advanced":
      return "#108ee9";
    case "basic":
      return "#2db7f5";
    default:
      return "#87d068";
  }
}

function NovarcSoftware(props) {
  const { id, text } = props;
  const dispatch = useDispatch();
  const programsList = useSelector((state) => state.robot.info[id]?.versions);

  useEffect(() => {
    if (!programsList) {
      // date range missing (only versions needed, not healthlogs)
      dispatch(getRobotInfo({ id }));
    }
  }, [dispatch, id, programsList]);

  if (!programsList) {
    return null;
  }

  if (text === "NovEye") {
    return (
      <>
        {programsList
          .filter((i) => i.name === text || i.name === "WeldVision")
          .map((program) => {
            return (
              <>
                {program.name}: v{program.version}
                <br />
              </>
            );
          })}
      </>
    );
  } else if (text === "Zenon") {
    return (
      <>
        {programsList
          .filter((i) => i.name.includes(text.toLowerCase()))
          .map((program) => {
            return (
              <>
                {program.name}: v{program.version} <br />
              </>
            );
          })}
      </>
    );
  } else {
    return (
      <>
        {programsList
          .filter((i) => i.name === text)
          .map((program) => {
            return <>v{program.version}</>;
          })}
      </>
    );
  }
}

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  options,
  ...restProps
}) => {
  const inputNode = getInputNode(dataIndex, record, options);
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex.includes(".") ? dataIndex.split(".") : dataIndex}
          style={{
            margin: 0,
          }}
          rules={
            [
              // {
              //   // required: true,
              //   message: `Please Input ${title}!`,
              // },
            ]
          }
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = () => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [showingKey, setShowingKey] = useState("");

  const dispatch = useDispatch();
  const adminRobotsList = useSelector((state) => state.admin.robots);
  const groups = useSelector((state) => state.admin.groups);
  const info = useSelector((state) => state.robot.info);

  useEffect(() => {
    dispatch(getAdminRobotsList());
    dispatch(getGroupsList());
  }, [dispatch]);

  useEffect(() => {
    if (!adminRobotsList?.length) return;
    for (const robot of adminRobotsList) {
      dispatch(getRobotInfo({ id: robot.id }));
    }
    // the admin.robots object uses the old Robot[] shape
    // currently unknown whether this is safe to replace with RobotList
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, adminRobotsList?.length]);

  const isEditing = (record) => record.key === editingKey;
  const isShowingAPIKey = (record) => record.key === showingKey;

  if (!adminRobotsList && !groups) {
    return <></>;
  }

  const robotLists = adminRobotsList && [
    ...adminRobotsList.map((robot, index) => {
      const id = robot.id;
      const robotInfo = info[id];
      return {
        ...robot,
        ...robotInfo,
        key: index,
        controlsVersions: robot.versions,
      };
    }),
  ];

  const edit = (record) => {
    form.setFieldsValue({
      tier: {
        tier: "",
        used: "",
        capacity: "",
      },
      ...record,
    });

    setEditingKey(record.key);
  };

  const showAPIKey = (record) => {
    setShowingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const add = (record) => {
    dispatch(createRobotTier(record.id));
  };

  const save = async (key, id) => {
    try {
      const row = await form.validateFields();

      const { tier } = row;

      const requestData = {
        ...(!isNil(tier.tier) && { tier: tier.tier }),
        ...(!isNil(tier.used) && { used: tier.used }),
        ...(!isNil(tier.capacity) && {
          // converting back to byte value
          capacity:
            tier.capacity > 100 ? tier.capacity : tier.capacity * BYTE_IN_GB,
        }),
      };

      dispatch(updateRobotTier({ id, requestData }));

      setEditingKey("");
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // editable: true,
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      // editable: true,
    },
    {
      title: "Novarc S/W versions",
      children: [
        {
          title: "NovEye",
          render: (text, record, index) => {
            return (
              <NovarcSoftware id={record.id} text={"NovEye"}></NovarcSoftware>
            );
          },
        },
        {
          title: "NovData",
          render: (text, record, index) => {
            return (
              <NovarcSoftware id={record.id} text={"NovData"}></NovarcSoftware>
            );
          },
        },
        {
          title: "NovSync",
          render: (text, record, index) => {
            return (
              <NovarcSoftware id={record.id} text={"NovSync"}></NovarcSoftware>
            );
          },
        },
      ],
    },
    {
      title: "Controls versions",
      children: [
        {
          title: "MSG",
          render: (text, record, index) => {
            const controlsVersion = record?.controlsVersions;
            if (controlsVersion)
              return (
                <span>
                  v{record?.controlsVersions?.msg_major_version}.
                  {record?.controlsVersions?.msg_minor_version}
                </span>
              );
          },
        },
        {
          title: "PLC",
          render: (text, record, index) => {
            const controlsVersion = record?.controlsVersions;
            if (controlsVersion)
              return (
                <span>
                  v{record?.controlsVersions?.plc_major_version}.
                  {record?.controlsVersions?.plc_minor_version}
                </span>
              );
          },
        },
        {
          title: "HMI",
          render: (text, record, index) => {
            const controlsVersion = record?.controlsVersions;
            if (controlsVersion)
              return (
                <span>
                  v{record?.controlsVersions?.hmi_major_version}.
                  {record?.controlsVersions?.hmi_minor_version}
                </span>
              );
          },
        },
      ],
    },
    {
      title: "Other S/W versions",
      children: [
        {
          title: "Zenon",
          render: (text, record, index) => {
            return (
              <NovarcSoftware id={record.id} text={"Zenon"}></NovarcSoftware>
            );
          },
        },
      ],
    },

    {
      title: "Group",
      dataIndex: "group",
      key: "groups",
      // editable: true,
      render: (text, record, index) => {
        return (
          <>
            {record.groups &&
              record.groups.map((item) => <Tag>{item.name}</Tag>)}
          </>
        );
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      // editable: true,
    },
    {
      title: "Tier",
      dataIndex: "tier.tier",
      key: "tier.tier",
      render: (text, record, index) => {
        return (
          <>
            {record.tier && (
              <Tag color={getTierColor(record.tier.tier)}>
                {record.tier.tier}
              </Tag>
            )}
          </>
        );
      },
      editable: true,
      filters:
        robotLists &&
        robotLists.length > 0 &&
        robotLists
          .map((item) => {
            return item.tier && item.tier.tier;
          })
          .filter((value, index, self) => self.indexOf(value) === index)
          .map((item) => {
            return { text: item, value: item };
          }),
      onFilter: (value, record) =>
        record.tier && record.tier && record.tier.tier.indexOf(value) === 0,
    },

    // {
    //   title: "Subscription Start Date",
    //   dataIndex: "tier.start_at",
    //   key: "tier.start_at",
    //   render: (_, record) => {
    //     return <>{record.tier && <span>{record.tier.start_at}</span>}</>;
    //   },
    //   // editable: true,
    // },

    // {
    //   title: "Subscription End Date",
    //   dataIndex: "tier.end_at",
    //   key: "tier.end_at",
    //   render: (_, record) => {
    //     return <>{record.tier && <span>{record.tier.end_at}</span>}</>;
    //   },
    //   // editable: true,
    // },

    {
      title: (
        <span style={{ cursor: "pointer" }}>
          Download Limit{" "}
          <Popover
            content={
              <div>
                <p>Total Allowed Download bandwidth for current month</p>
                <p>You can manually set download limit as well.</p>
                <p>
                  When it is renewed, it will be renewed to the corresponding
                  default value for the tier
                  <ul>
                    <li>Lite: 1GB</li>
                    <li>Basic: 10GB</li>
                    <li>Advanced: 100GB</li>
                  </ul>
                </p>
              </div>
            }
            title="Capacity"
          >
            <InfoCircleOutlined />
          </Popover>
        </span>
      ),
      dataIndex: "tier.capacity",
      key: "tier.capacity",
      render: (_, record) => {
        return (
          <>{record.tier && <span>{fileSize(record.tier.capacity)}</span>}</>
        );
      },
      editable: true,
    },

    {
      title: "Used",
      dataIndex: "tier.used",
      key: "tier.used",
      render: (_, record) => {
        return <>{record.tier && <span>{fileSize(record.tier.used)}</span>}</>;
      },
      editable: true,
    },

    {
      title: (
        <span style={{ cursor: "pointer" }}>
          API Key{" "}
          <Popover
            content={
              <div>
                <p>
                  API Key is hidden. To reveal it click on <KeyOutlined />. It
                  is only available for basic & advanced
                </p>
                <p>
                  This can be used in{" "}
                  <a
                    href="https://api.novarctech.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    API doc
                  </a>
                  <ul>
                    <li>
                      <i>X-APP-ID:</i> <b>SWR-####</b>
                    </li>
                    <li>
                      <i>X-API-KEY:</i>{" "}
                      <b>********-****-****-****-**********</b>
                    </li>
                  </ul>
                </p>
              </div>
            }
            title="API Key"
          >
            <InfoCircleOutlined />
          </Popover>
        </span>
      ),
      dataIndex: "api_key.secret_key",
      key: "api_key.secret_key",
      render: (_, record) => {
        const showingAPI = isShowingAPIKey(record);

        return showingAPI ? (
          <>
            {record.api_key && record.api_key.secret_key && (
              <span>{record.api_key.secret_key}</span>
            )}
          </>
        ) : (
          <></>
        );
      },
    },

    {
      title: "Consent",
      render: (_, record) => {
        return <ConsentCell id={record.id} />;
      },
      editable: false,
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Popconfirm
              title="Sure to Save?"
              onConfirm={() => save(record.key, record.id)}
            >
              <Button icon={<CheckOutlined />} />
            </Popconfirm>

            <Button icon={<CloseOutlined />} onClick={cancel} />
          </span>
        ) : (
          <>
            {!record.tier ? (
              <Button
                icon={<PlusSquareOutlined />}
                disabled={editingKey !== ""}
                // disabled={true}
                onClick={() => add(record)}
              />
            ) : (
              <>
                <Button
                  icon={<EditOutlined />}
                  disabled={editingKey !== ""}
                  // disabled={true}
                  onClick={() => edit(record)}
                />
                {record.tier && record.tier.tier !== "lite" && (
                  <Tooltip title={"Click to reveal API for basic & advanced"}>
                    <Button
                      icon={<KeyOutlined />}
                      disabled={editingKey !== ""}
                      // disabled={true}
                      onClick={() => showAPIKey(record)}
                    />
                  </Tooltip>
                )}{" "}
              </>
            )}
          </>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        options: { groups },
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={robotLists}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          defaultPageSize: defaultPageSize,
          onChange: cancel,
          hideOnSinglePage: true,
        }}
      />
    </Form>
  );
};

function ConsentCell(props) {
  const { id } = props;
  const [consent, setConsent] = useState(null);
  const [editing, setEditing] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  useEffect(() => {
    (async () => {
      const response = await getConsentForm(id);
      setConsent(response?.data?.[0]);
      // console.log("async data", response, id)
    })();
  }, [id]);

  const formSigned = consent?.signed_by;
  const formValid = consent && consent.name && consent.email && consent.agreed;

  return consent ? (
    showDetails ? (
      <div>
        <table>
          <tr>
            <td>name</td>
            <td>{String(consent.name)}</td>
          </tr>
          <tr>
            <td>email</td>
            <td>{String(consent.email)}</td>
          </tr>
          <tr>
            <td>link</td>
            <td>{String(consent.link)}</td>
          </tr>
          <tr>
            <td>agreed</td>
            <td>{String(consent.agreed)}</td>
          </tr>
          <tr>
            <td>date</td>
            <td>{String(consent.updated_at || consent.created_at)}</td>
          </tr>
          <tr>
            <td>user_id</td>
            <td>{String(consent.signed_by)}</td>
          </tr>
        </table>
        <button onClick={() => setShowDetails(false)}>Hide</button>
      </div>
    ) : (
      <div>
        {formSigned ? (
          formValid ? (
            <p style={{ color: "#3ba507" }}>
              Signed <CheckOutlined />
            </p>
          ) : (
            <p style={{ color: "#f5222d" }}>
              Invalid <CloseOutlined />
            </p>
          )
        ) : (
          <p style={{ color: "#e27500" }}>
            Not signed <WarningOutlined />
          </p>
        )}
        <button onClick={() => setShowDetails(true)}>Details</button>
      </div>
    )
  ) : editing ? (
    <div>
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          const url = ev.target[0].value;

          // cancel if blank
          if (!url) {
            setEditing(false);
            return;
          }

          // console.log("url saved",url);
          createConsentForm(id, { link: url });
          ev.target[0].setAttribute("disabled", true);
          ev.target[1].setAttribute("disabled", true);
          ev.target[1].setAttribute("value", "Saved!");
        }}
      >
        <label>
          <span>Paste link to consent form</span>
          <input type={"url"} />
        </label>
        <input type={"submit"} value="Save" />
      </form>
    </div>
  ) : (
    <div>
      <p>Not available</p>
      <button onClick={() => setEditing(true)}>Provide Link</button>
    </div>
  );
}

export default EditableTable;
