import moment from "moment";
import { stringify as csvStringify } from "csv-stringify/sync";
import {
  convertInchesTomm,
  getPipeSchedule,
  getTimeZone,
} from "../../libs/utils";
import React from "react";
function parseWeldTime(duration) {
  const hours = duration.hours() || 0;
  const minutes = duration.minutes() || 0;
  const seconds = duration.seconds() || 0;
  const hh = hours < 10 ? "0" + hours : "" + hours;
  const mm = minutes < 10 ? "0" + minutes : "" + minutes;
  const ss = seconds < 10 ? "0" + seconds : "" + seconds;

  // note - this is a duration, not a time of day
  // we cannot use moment.format("HH:mm:ss")
  // because the .format range only goes from 00 to 23 hours
  return `${hh}:${mm}:${ss}`;
}

export function generateCsvReports(robotWelds) {
  const weldData = robotWelds
    .map((item) => {
      const isMetric = item.units_system === "metric";
      const idx = robotWelds.findIndex((i) => i === item);

      const result = {
        ...item,
        weld_inches: item.weld_inches,
        weld_fdi: item.weld_fdi,
        weld_di: item.weld_di,
      };

      if (
        idx < robotWelds.length - 1 &&
        moment(
          moment(robotWelds[idx + 1].arc_on_utc).utc(true),
          "YYYY-MM-DD"
        ).diff(
          moment(moment(item.arc_on_utc).utc(true), "YYYY-MM-DD"),
          "hour"
        ) >= -8
      ) {
        result.duration = moment.duration(
          moment(item.arc_on_utc).diff(moment(robotWelds[idx + 1].arc_off_utc))
        );
      }
      return result;
    })
    .reverse();

  const headers = [
    "Date",
    " ",
    "Job Id",
    "Spool",
    "Weld Id",
    "Run",
    "Start Time",
    "Arc Off Time",
    "Arc On Time",
    "Operator",
    "Size-Sched",
    "Procedure",
  ];

  const rows = weldData.map((item, idx) => {
    const isMetric = item.units_system === "metric";
    return [
      moment.tz(item.arc_on_at, getTimeZone(item.time_zone)).format("DD/MM/YY"),
      idx + 1,
      item.work_order_ref,
      item.spool_ref,
      item.welder_id_ref,
      item.run_number,

      moment.tz(item.arc_on_at, getTimeZone(item.time_zone)).format("HH:mm:ss"),
      //   arc off time - may sometimes be null for some reason
      parseWeldTime(moment.duration({ ...item.duration?._data })),
      //   arc on time
      parseWeldTime(moment.duration({ ...item.weld_time })),
      item.operator_name,

      `${
        isMetric
          ? convertInchesTomm(item.pipe_size_inches).toFixed(0) + "mm"
          : item.pipe_size_inches + "in"
      }-${getPipeSchedule(item.pipe_schedule)}`,
      item.procedure_name,
    ];
  });

  const csvText = csvStringify([headers, ...rows]);
  // console.log("csv output", csvText)

  return csvText;
}
