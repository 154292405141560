import { createSlice } from "@reduxjs/toolkit";

import { getWrikeData } from "../../services/api/wrike";

const wrikeSlice = createSlice({
  name: "wrike",
  initialState: {
    fetching: false,
    fetchingError: null,
  },

  reducers: {
    fetchWorkFlowsRequest: (state) => ({
      ...state,
      fetching: true,
      fetchingError: null,
    }),
    fetchWorkFlowsFailure: (state, action) => ({
      ...state,
      fetching: false,
      fetchingError: action.error,
    }),
    fetchWorkFlowsSuccess: (state, action) => ({
      ...state,
      fetching: false,
      fetchingError: null,
      ...action.payload,
    }),

    fetchListRequest: (state) => ({
      ...state,
      fetching: true,
      fetchingError: null,
    }),
    fetchListFailure: (state, action) => ({
      ...state,
      fetching: false,
      fetchingError: action.error,
    }),
    fetchListSuccess: (state, action) => ({
      ...state,
      fetching: false,
      fetchingError: null,
      ...action.payload,
    }),

    fetchSubTasksRequest: (state) => ({
      ...state,
      fetching: true,
      fetchingError: null,
    }),
    fetchSubTasksFailure: (state, action) => ({
      ...state,
      fetching: false,
      fetchingError: action.error,
    }),
    fetchSubTasksSuccess: (state, action) => ({
      ...state,
      fetching: false,
      fetchingError: null,
      subtasks: {
        ...state.subtasks,
        ...action.payload.subtasks,
      },
    }),

    fetchEventsListRequest: (state) => ({
      ...state,
      fetching: true,
      fetchingError: null,
    }),
    fetchEventsListFailure: (state, action) => ({
      ...state,
      fetching: false,
      fetchingError: action.error,
    }),
    fetchEventsListSuccess: (state, action) => ({
      ...state,
      fetching: false,
      fetchingError: null,
      ...action.payload,
    }),

    fetchSubEventsRequest: (state) => ({
      ...state,
      fetching: true,
      fetchingError: null,
    }),
    fetchSubEventsFailure: (state, action) => ({
      ...state,
      fetching: false,
      fetchingError: action.error,
    }),
    fetchSubEventsSuccess: (state, action) => ({
      ...state,
      fetching: false,
      fetchingError: null,
      subEvents: {
        ...state.subEvents,
        ...action.payload.subEvents,
      },
    }),

    fetchTimeLogsRequest: (state) => ({
      ...state,
      fetching: true,
      fetchingError: null,
    }),
    fetchTimeLogsFailure: (state, action) => ({
      ...state,
      fetching: false,
      fetchingError: action.error,
    }),
    fetchTimeLogsSuccess: (state, action) => ({
      ...state,
      fetching: false,
      fetchingError: null,
      ...action.payload,
    }),

    fetchCustomFieldsRequest: (state) => ({
      ...state,
      fetching: true,
      fetchingError: null,
    }),
    fetchCustomFieldsFailure: (state, action) => ({
      ...state,
      fetching: false,
      fetchingError: action.error,
    }),
    fetchCustomFieldsSuccess: (state, action) => ({
      ...state,
      fetching: false,
      ...action.payload,
    }),
  },
});

export const {
  fetchWorkFlowsRequest,
  fetchWorkFlowsFailure,
  fetchWorkFlowsSuccess,
  fetchListRequest,
  fetchListFailure,
  fetchListSuccess,
  fetchSubTasksRequest,
  fetchSubTasksFailure,
  fetchSubTasksSuccess,
  fetchEventsListRequest,
  fetchEventsListFailure,
  fetchEventsListSuccess,
  fetchSubEventsListRequest,
  fetchSubEventsListSuccess,
  fetchSubEventsListFailure,
  fetchTimeLogsRequest,
  fetchTimeLogsFailure,
  fetchTimeLogsSuccess,
  fetchCustomFieldsRequest,
  fetchCustomFieldsSuccess,
  fetchCustomFieldsFailure,
} = wrikeSlice.actions;

export default wrikeSlice.reducer;

export const getTickets = (url, query) => async (dispatch) => {
  try {
    dispatch(fetchListRequest());

    const { data, kind } = await getWrikeData(url, query);

    dispatch(fetchListSuccess({ [kind]: data }));
  } catch (err) {
    dispatch(fetchListFailure(err.toString()));
  }
};

export const getSubTasks = (parentId, url) => async (dispatch) => {
  try {
    dispatch(fetchSubTasksRequest());

    const { data } = await getWrikeData(url);

    dispatch(fetchSubTasksSuccess({ subtasks: { [parentId]: data } }));
  } catch (err) {
    dispatch(fetchSubTasksFailure(err.toString()));
  }
};

export const getCustomStatuses = () => async (dispatch) => {
  try {
    dispatch(fetchWorkFlowsRequest());

    const { data, kind } = await getWrikeData("workflows");

    dispatch(fetchWorkFlowsSuccess({ [kind]: data }));
  } catch (err) {
    dispatch(fetchWorkFlowsFailure(err.toString()));
  }
};

export const getCustomFields = () => async (dispatch) => {
  try {
    dispatch(fetchCustomFieldsRequest());

    const { data, kind } = await getWrikeData("customfields");

    dispatch(fetchCustomFieldsSuccess({ [kind]: data }));
  } catch (err) {
    dispatch(fetchCustomFieldsFailure(err.toString()));
  }
};

export const getTimeLogs = (url, query) => async (dispatch) => {
  try {
    dispatch(fetchTimeLogsRequest());

    const { data, kind } = await getWrikeData(url, query);

    dispatch(fetchTimeLogsSuccess({ [kind]: data }));
  } catch (err) {
    dispatch(fetchTimeLogsFailure(err.toString()));
  }
};

export const getUpcomingEvents = (url, query) => async (dispatch) => {
  try {
    dispatch(fetchEventsListRequest());

    const { data } = await getWrikeData(url, query);

    dispatch(fetchEventsListSuccess({ events: data }));
  } catch (err) {
    dispatch(fetchEventsListFailure(err.toString()));
  }
};

export const getUpcomingSubEvents = (parentId, url) => async (dispatch) => {
  try {
    dispatch(fetchSubEventsListRequest());

    const { data } = await getWrikeData(url);

    dispatch(fetchSubEventsListSuccess({ subEvents: { [parentId]: data } }));
  } catch (err) {
    dispatch(fetchSubEventsListFailure(err.toString()));
  }
};
