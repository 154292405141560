import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  Button,
  DatePicker,
  // Divider,
  Empty,
  Form,
  Input,
  List,
  Popconfirm,
  Radio,
  Select,
  Space,
  Table,
  Tabs,
  Tag,
  Tooltip,
  message,
  Typography,
  Upload,
} from "antd";
import {
  DeleteOutlined,
  // EditOutlined,
  FileOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  // UploadOutlined,
} from "@ant-design/icons";

import { AttachmentIcon } from "../../components/icons/NovarcIcons";

import Moment from "moment";
import "moment-timezone";
import { extendMoment } from "moment-range";
import LoadingPage from "../../components/LoadingPage";

import { getToken } from "../../libs/auth";
import { API_UPLOAD_ROBOTS } from "../../libs/constants";
import { getPipeSchedule } from "../../libs/utils";

import {
  getRobotWeldTestReportsList,
  createRobotWeldTestReport,
  // updateRobotWeldTestReport,
  removeRobotWeldTestReport,
} from "./robotSlice";

const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const moment = extendMoment(Moment);

const FAIL_LOCATION_OPTIONS = ["Root", "Fill", "Cap"];
const DEFECT_TYPE_OPTIONS = [
  "Lack of Fusion",
  "Porosity",
  "Incomplete Penetration",
  "Undercut",
  "Spatter",
  "Slag Inclusions",
  "Cracks",
  "Overlap",
  "Burn Through",
  "Excess Reinforcement",
  "Under-fill",
  "Other",
];
const FILE_SIZE_LIMIT = 50000000; // 50MB

// let index = 0;

function RobotWeldTestReportForm(props) {
  const {
    job: {
      work_order_ref,
      spool_ref,
      welder_id_ref,
      start,
      end,
      children,
      pipe_size_inches,
      pipe_schedule,
      units_system,
    },
    // job,
    // report,
    robotId,
  } = props;

  const [componentSize, setComponentSize] = useState("default");
  const [selectedFailLocationItems, setSelectedFailLocationItems] = useState(
    []
  );

  // eslint-disable-next-line
  const [items, setItems] = useState(DEFECT_TYPE_OPTIONS);
  // const [name, setName] = useState("");

  const [form] = Form.useForm();

  // const onNameChange = (event) => {
  //   setName(event.target.value);
  // };

  // const addItem = (e) => {
  //   e.preventDefault();
  //   setItems([...items, name || `New item ${index++}`]);
  //   setName("");
  // };

  const onFormLayoutChange = ({ size }) => {
    setComponentSize(size);
  };
  const handleChange = (selectedFailLocationItems) => {
    setSelectedFailLocationItems(selectedFailLocationItems);
  };

  const filteredOptions = FAIL_LOCATION_OPTIONS.filter(
    (o) => !selectedFailLocationItems.includes(o)
  );

  const filteredDefectOptions = items;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };

  const onFinish = (values) => {
    if (values.attachments && values.attachments.length > 0) {
      values.attachment_urls = [...values.attachments].map(
        (item) => item.response.data
      );
    }
    props.onSubmit(values);
  };

  const uploadProps = {
    name: "weld_test_reports",
    accept:
      ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*,.pdf",
    action: `${API_UPLOAD_ROBOTS}/${robotId}/weld_test_reports`,
    headers: {
      authorization: `bearer ${getToken()}`,
      "X-WORK-ORDER-REF": work_order_ref,
      "X-SPOOL-REF": spool_ref,
      "X-WELDER-ID-REF": welder_id_ref,
    },
    beforeUpload: (file, fileList) => {
      const isFileSizeOk = file.size < FILE_SIZE_LIMIT;
      if (!isFileSizeOk) {
        message.error("file is too big");
      }
      return isFileSizeOk || Upload.LIST_IGNORE;
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        console.log(`${info.file.name} file uploaded successfully`);
        // message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        console.log(`${info.file.name} file uploaded failed`);
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Form
      form={form}
      name="report"
      initialValues={{
        work_order_ref: work_order_ref,
        spool_ref: spool_ref,
        welder_id_ref: welder_id_ref,
      }}
      onFinish={onFinish}
      preserve={false}
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 14,
      }}
      layout="horizontal"
      onValuesChange={onFormLayoutChange}
      size={componentSize}
    >
      <Form.Item label="Job Id" name="work_order_ref">
        <Input disabled={true} />
      </Form.Item>
      <Form.Item label="Spool" name="spool_ref">
        <Input disabled={true} />
      </Form.Item>
      <Form.Item label="Weld Id" name="welder_id_ref">
        <Input disabled={true} />
      </Form.Item>
      <Form.Item label="# of Runs">
        <Input
          disabled={true}
          placeholder="# of Runs associated with this weld"
          defaultValue={children.length}
        />
      </Form.Item>
      <Form.Item label="Weld Done Time">
        <RangePicker
          disabled={true}
          defaultValue={[moment(start), moment(end)]}
          format="YYYY/MM/DD HH:mm:ss"
        />
      </Form.Item>
      <Form.Item label={"Pipe Size & Sched"}>
        <Input
          disabled={true}
          defaultValue={`${pipe_size_inches}${
            units_system === "imperial" ? "in" : "cm"
          } ${getPipeSchedule(pipe_schedule)}`}
        />
      </Form.Item>

      <Form.Item
        label="Weld Test Status"
        name="test_status"
        required
        rules={[
          {
            required: true,
            message: "Please select test status",
          },
        ]}
      >
        <Radio.Group buttonStyle="solid" size="large">
          {/* green */}
          <Radio.Button className="WeldTestReport_Button_Pass" value="pass">
            Pass
          </Radio.Button>
          {/* red */}
          <Radio.Button className="WeldTestReport_Button_Fail" value="fail">
            Fail
          </Radio.Button>
          <Radio.Button value="not tested">Not Tested</Radio.Button>
        </Radio.Group>
      </Form.Item>

      {/**
       * Fail Location ( Root, Fill, Cap )
       * Detail:
       * Excessive Stick out
       * Grinding while welding
       * Cold lap roll over?
       * Location on
       */}
      <Form.Item label="Defect Location" name="defect_location">
        <Select
          mode="multiple"
          placeholder="Failure Location (Root, Fill, Cap)"
          onChange={handleChange}
          tagRender={({ label, closable, onClose }) => (
            <Tag color={"error"} closable={closable} onClose={onClose}>
              {label}
            </Tag>
          )}
          style={{ width: "100%" }}
        >
          {filteredOptions.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Detail" name="note">
        <TextArea
          placeholder="Please provide more details about the defect location"
          rows={4}
        ></TextArea>
      </Form.Item>

      {/**
       * Porosity
       * 1. Excessive Stick out
       * 2. Wind gust from grinding
       * 3. Contaminated Tacks
       * 4. Contaminated inside the pipe
       * 5. Wind gust from outside
       * 6. Bottle Valve not fully opened
       * 7. Flow regulator prior to EWR
       * 8. EWR settings
       * 9. Inadequate torch drag angle
       * 10. End of bottles
       * 11. Gas line pinches
       *
       * LOF Lack of Fusion
       * 1. Inadequate torch weave motion
       * 2. Tight Root joint Geometry
       */}
      <Form.List name="defects">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                label={index === 0 ? "Defect Type" : ""}
                required={fields.length > 0 ? true : false}
                key={field.key}
              >
                <Form.Item
                  name={[field.name, "defect_type"]}
                  rules={[
                    {
                      required: true,
                      message: "Please select defect type or remove this field",
                    },
                  ]}
                >
                  <Select
                    style={{ width: 300 }}
                    placeholder="Please select defect type (LOF, Porosity)"
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        {/*
                        <Divider style={{ margin: "8px 0" }} />
                        <Space align="center" style={{ padding: "0 8px 4px" }}>
                          <Input
                            placeholder="Please enter defect type"
                            value={name}
                            onChange={onNameChange}
                          />
                          <Typography.Link
                            onClick={addItem}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <PlusOutlined /> Add item
                          </Typography.Link>
                        </Space>
                         */}
                      </>
                    )}
                  >
                    {filteredDefectOptions.map((item) => (
                      <Option key={item}>{item}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name={[field.name, "comment"]}>
                  <TextArea
                    placeholder="Please provide additional description about the defect"
                    rows={4}
                  ></TextArea>
                </Form.Item>
                {fields.length > 0 ? (
                  <>
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                    <span style={{ paddingLeft: 10 }}>Remove defect type</span>
                  </>
                ) : null}
              </Form.Item>
            ))}
            {fields.length < 4 && (
              <Form.Item
                label={fields.length < 1 ? "Defect Type" : " "}
                className={
                  fields.length < 1
                    ? ""
                    : "WeldTestReport-DefectType_button_label"
                }
              >
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: "60%" }}
                  icon={<PlusOutlined />}
                >
                  Add defect type
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            )}
          </>
        )}
      </Form.List>

      {/**
       * General Attachment Type
       * document - pdf
       * image - jpeg, png
       */}
      <Form.Item
        label="Attachments"
        name="attachments"
        getValueFromEvent={normFile}
        valuePropName="fileList"
      >
        <Upload listType="text" maxCount={5} multiple {...uploadProps}>
          <Button icon={<AttachmentIcon />}>
            <span style={{ fontWeight: 500 }}>Add Attachments</span> (Max: 5,
            10MB)
          </Button>
        </Upload>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

function RobotWeldTestReportList(props) {
  const { reports } = props;

  // const setEditReport = (record) => {
  //   props.onEditReport && props.onEditReport(record);
  // };

  const removeReport = (record) => {
    props.onDeleteReport && props.onDeleteReport(record);
  };

  const filteredReports = reports.filter((item) => !item.deleted_at);

  const columns = [
    // {
    //   title: "Id",
    //   dataIndex: "id",
    // },
    // {
    //   title: "Work Order",
    //   dataIndex: "work_order_ref",
    // },
    // {
    //   title: "Spool",
    //   dataIndex: "spool_ref",
    // },
    // {
    //   title: "Weld ID",
    //   dataIndex: "welder_id_ref",
    // },
    {
      title: "Test Status",
      dataIndex: "test_status",
      render: (text) => {
        let color = "grey";
        switch (text) {
          case "pass":
            color = "green";
            break;
          case "fail":
            color = "red";
            break;

          default:
            break;
        }
        // switch (text)
        // case:
        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: "Defect Location",
      dataIndex: "defect_location",
      render: (text, record, index) => {
        return (
          <>
            {record.defect_location &&
              record.defect_location.map((item) => <Tag>{item}</Tag>)}
          </>
        );
      },
    },
    {
      title: "Note",
      dataIndex: "note",
    },
    {
      title: "Defects",
      dataIndex: "defects",
      render: (text, record, index) => {
        if (record.defects)
          return (
            <List
              size="small"
              dataSource={record.defects}
              renderItem={(item) => (
                <List.Item>
                  <Typography.Text mark>{item.defect_type}</Typography.Text>{" "}
                  {item.comment}
                </List.Item>
              )}
            ></List>
          );
        else {
          return <span></span>;
        }
      },
    },
    {
      title: "Attachments",
      dataIndex: "attachment_urls",

      render: (text, record, index) => {
        return (
          <>
            {record.attachment_urls &&
              record.attachment_urls.map((item) => (
                <Tooltip
                  title={decodeURI(item.split("/").slice(-1)[0].split("?")[0])}
                >
                  <a
                    href={item}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    {" "}
                    <FileOutlined />
                  </a>
                </Tooltip>
              ))}
          </>
        );
      },
    },
    {
      title: "Submitted At",
      dataIndex: "created_at",
      render: (text, record, index) => {
        return <span>{moment(text).format("YYYY-MM-DD HH:MM:ss")}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {/* <EditOutlined onClick={() => setEditReport(record)} /> */}
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => removeReport(record)}
          >
            <DeleteOutlined />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return <Table columns={columns} dataSource={filteredReports}></Table>;
}

function RobotWeldTestReportModal(props) {
  const { robotName } = useParams();
  const robotId =
    robotName && robotName.match(/(\d+)/) && robotName.match(/(\d+)/)[0];

  const dispatch = useDispatch();
  const {
    job: { work_order_ref, spool_ref, welder_id_ref },
    job,
  } = props;

  const reports = useSelector((state) => state.robot.weldTestReports);

  const [selectedReport, setSelectedReport] = useState(null);

  const [activeKey, setActiveKey] = useState("1");

  useEffect(() => {
    dispatch(
      getRobotWeldTestReportsList({
        id: robotId,
        query: {
          work_order_ref,
          spool_ref,
          welder_id_ref,
        },
      })
    );
  }, [dispatch, robotId, work_order_ref, spool_ref, welder_id_ref]);

  if (!reports) {
    return <LoadingPage />;
  }

  const onSubmitNew = (values) => {
    dispatch(
      createRobotWeldTestReport({
        id: robotId,
        payload: {
          work_order_ref,
          spool_ref,
          welder_id_ref,
          ...values,
        },
      })
    );
    setActiveKey("1");
  };

  return (
    <>
      <Tabs
        defaultActiveKey={activeKey}
        activeKey={activeKey}
        onTabClick={(key) => {
          if (key === "1") {
            setSelectedReport(null);
          }
          setActiveKey(key);
        }}
        type="card"
        size={"large"}
      >
        <TabPane
          tab="Submitted Reports"
          key="1"
          style={{ overflowX: "scroll" }}
        >
          {reports.length > 0 ? (
            <RobotWeldTestReportList
              reports={reports}
              onEditReport={(report) => {
                setSelectedReport(report);
                setActiveKey("2");
              }}
              onDeleteReport={(report) =>
                dispatch(
                  removeRobotWeldTestReport({
                    robot_id: robotId,
                    report_id: report.id,
                  })
                )
              }
            ></RobotWeldTestReportList>
          ) : (
            <Empty
              description={
                <span style={{ padding: 20 }}>
                  There is no reports submitted on this Job:{" "}
                  <b>
                    {work_order_ref} {spool_ref} {welder_id_ref}
                  </b>
                </span>
              }
            >
              <Button type="primary" onClick={() => setActiveKey("3")}>
                Create Weld Test Report
              </Button>
            </Empty>
          )}
        </TabPane>
        {selectedReport && (
          <TabPane tab="Edit Report" key="2">
            <Title level={3}>
              Please fill in the form to report weld test results
            </Title>
            <RobotWeldTestReportForm
              robotId={robotId}
              job={job}
              onSubmit={() => {
                // onSubmitEdit
              }}
            />
          </TabPane>
        )}
        {!selectedReport && (
          <TabPane tab="New Report" key="3">
            <Title level={3}>
              Please fill in the form to report weld test results
            </Title>
            <RobotWeldTestReportForm
              robotId={robotId}
              job={job}
              report={selectedReport}
              onSubmit={onSubmitNew}
            />
          </TabPane>
        )}
      </Tabs>
    </>
  );
}

export default RobotWeldTestReportModal;
