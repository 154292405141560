import { useDispatch } from "react-redux";
import { useLoadingWithProgress } from "./useLoading";
import { getRobotPLCAlarms } from "../features/alarms/alarmSlice";
import { useEffect } from "react";
import { getRobotInfo } from "../features/robot/robotSlice";
import moment from "moment";

export function useRobotAlarms(
  robotIds: number | number[],
  dateRange?: { start: string; end: string }
) {
  const robotIdList = typeof robotIds === "number" ? [robotIds] : robotIds;
  const [[robots, info], robotProgress] = useLoadingWithProgress(
    "robot",
    // @ts-ignore
    ({ robots, info }) => [robots, info],
    getRobotInfo
  );
  const [alarms, alarmsProgress] = useLoadingWithProgress(
    "alarms",
    // @ts-ignore
    ({ alarms }) => alarms,
    getRobotPLCAlarms
  );
  const dispatch = useDispatch();
  const start =
    dateRange?.start ?? moment().subtract(30, "d").format("YYYY-MM-DD");
  const end = dateRange?.end ?? moment().format("YYYY-MM-DD");

  useEffect(() => {
    for (const id of robotIdList) {
      dispatch(getRobotInfo({ id }));
      dispatch(getRobotPLCAlarms({ id, start, end }));
    }
  }, [dispatch, robotIdList.length, robotIdList?.[0], start, end]);

  return combineAlarms(robotIdList, robots, info, alarms);
}

function combineAlarms(robotIds: number[], robots, info, alarms) {
  const crashAlarms = robotIds
    .map((robotId) => {
      const robotInfo = info[robotId];
      if (!robotInfo?.summary) {
        return null;
      }
      const coreFiles = parseInt(robotInfo.summary?.data?.[0]?.core_files, 10);
      if (coreFiles > 0)
        return {
          key: "sys" + robotId,
          unit: robots[robotId]?.name,
          message: `System crashed ${coreFiles} times`,
          type: "crash",
          received_at: robotInfo.summary?.date,
          detail: "Please contact customer to investigate.",
        };
    })
    .filter((e) => e);

  const diskSpaceAlarms = robotIds
    .map((robotId) => {
      const robotInfo = info[robotId];
      const usedSpace = parseInt(robotInfo?.space?.used, 10);
      const totalSpace = parseInt(robotInfo?.space?.total, 10);
      if (!robotInfo?.summary || isNaN(usedSpace) || isNaN(totalSpace)) {
        return null;
      }
      const usedPercent = (usedSpace / totalSpace) * 100;
      return (
        usedPercent > 75 && {
          key: "ipc" + robotId,
          unit: robots[robotId]?.name,
          message: `${robotInfo.space.free} disk space left`,
          type: usedPercent > 90 ? "error" : "warning",
          received_at: robotInfo.summary?.date,
          detail: "Please contact customer to free up the space.",
        }
      );
    })
    .filter((e) => e);

  const plcAlarms = robotIds
    .map((robotId) => {
      return alarms[robotId];
    })
    .filter((e) => e)
    .reduce((prev, curr) => [...prev, ...curr], [])
    .sort((a, b) => {
      const dateA = new Date(a?.received_at);
      const dateB = new Date(b?.received_at);
      // most recent first
      if (dateA < dateB) return 1;
      if (dateA > dateB) return -1;
      return 0;
    });

  return [
    ...crashAlarms,
    ...diskSpaceAlarms,
    ...(plcAlarms ).map((item, index) => {
      return { key: "plc" + index, ...item };
    }),
  ];
}
