import React, { useEffect, useState } from "react";

import { Card, Descriptions, Progress } from "antd";

import moment from "moment";
import "moment-timezone";

import { fileSize } from "../../libs/utils";
import { CARD_CONFIG_STYLE } from "../../libs/constants";
import { getLegacySwrInformation } from "./robotSlice";
import { useDispatch, useSelector } from "react-redux";
export default function RobotInfo(props) {
  const NOVARC_SW = ["NovData", "WeldVision", "NovEye", "NovSync"];

  const { robotId, isCustomer, tier, weldRun } = props;
  const robot = useSelector((state) => state.robot.robots[robotId] ?? {});
  const robotInfo = useSelector((state) => state.robot.info[robotId] ?? {});
  const swr_information = useSelector(
    (state) => state.robot.legacySwrInfo[robotId]?.swr_information ?? []
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLegacySwrInformation([robotId]));
  }, [dispatch, robotId]);

  const [localTime, setLocalTime] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => setLocalTime(moment()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const ring = robotInfo?.space
    ? (parseInt(robotInfo.space.used, 10) /
        parseInt(robotInfo.space.total, 10)) *
      100
    : null;

  const bandwidthRing =
    tier?.capacity ? (tier.used / tier.capacity) * 100 : null;

  const novarcSoftware = (robotInfo?.versions ?? [])
    .filter((item, index) => {
      return NOVARC_SW.includes(item.name);
    });

  const latestSoftware = Array.from(
    novarcSoftware
      .reduce((m, obj) => {
        const curr = m.get(obj.name);
        return m.set(
          obj.name,
          curr ? (curr.install_date < obj.install_date ? obj : curr) : obj
        );
      }, new Map())
      .values()
  );

  const swr_summary = {
    customer:
      swr_information.find((item) => item.key === "client_name")?.value ||
      robot.customer ||
      "N/A",
    location: robot.location || "N/A",
    last_online: robotInfo?.summary?.date || "N/A",
    power_source:
      swr_information.find((item) => item.key === "power_source")?.value ||
      "N/A",
    operator:
      swr_information.find((item) => item.key === "name_mc")?.value || "N/A",
    local_time: moment
      .tz(
        localTime,
        // todo - confirm if time zone is correct
        Intl.DateTimeFormat().resolvedOptions().timeZone
      )
      .format("llll"),
  };

  const dateCycleText = (
    <i style={{ fontSize: "0.8em" }}>
      {moment(localTime).set("date", tier.recurring_date).format("MMM Do")}{" "}
      {" ~ "}
      {moment(localTime)
        .add(1, "months")
        .set("date", tier.recurring_date)
        .subtract(1, "days")
        .format("MMM Do")}
    </i>
  );

  const downloadUsageText = `${fileSize(
    Number(tier.capacity) < Number(tier.used)
      ? 0
      : Number(tier.capacity) - Number(tier.used)
  )} Left`;

  const plcInformation = weldRun?.[0];

  return (
    <div className="summarypage">
      <h1>SWR Summary</h1>
      {robotInfo?.space && (
        <Card {...CARD_CONFIG_STYLE} title="Disk Usage">
          <div className="usage">
            <Progress
              className="usage-chart"
              type="circle"
              percent={ring}
              format={() => `${Math.round(ring)} %`}
              strokeColor={
                ring > 85 ? "#f5222d" : ring > 70 ? "#fa8c16" : "#1890ff"
              }
            />
            <div className="usage-values">
              <div>{`${robotInfo.space.free} Left`}</div>
              <div>{`Total ${robotInfo.space.total}`}</div>
            </div>
          </div>
        </Card>
      )}
      {tier && (
        <Card {...CARD_CONFIG_STYLE} title="Download Usage">
          <div className="usage">
            <Progress
              className="usage-chart"
              type="circle"
              percent={bandwidthRing}
              format={() =>
                `${bandwidthRing > 100 ? 100 : Math.round(bandwidthRing)} %`
              }
              strokeColor={
                bandwidthRing > 85
                  ? "#f5222d"
                  : bandwidthRing > 70
                  ? "#fa8c16"
                  : "#1890ff"
              }
            />
            <div className="usage-values">
              <div>{downloadUsageText}</div>
              <div>{`Total ${fileSize(tier.capacity)}`}</div>
            </div>
          </div>
          {dateCycleText}
        </Card>
      )}

      <Descriptions
        column={{ xxl: 3, xl: 3, lg: 1, md: 1, sm: 1, xs: 1 }}
        // title={"SWR Software"}
        bordered
        layout="vertical"
        size={"small"}
      >
        {latestSoftware &&
          latestSoftware
            .sort((a, b) => {
              const order = ["NovEye", "NovData", "NovSync"];
              return order.indexOf(a.name) - order.indexOf(b.name);
            })
            .map((item, index) => {
              return (
                <Descriptions.Item key={index} label={item.name}>
                  {item.version}
                </Descriptions.Item>
              );
            })}
        <Descriptions.Item label={"MSG"}>
          {plcInformation?.msg_major_version}.
          {plcInformation?.msg_minor_version}
        </Descriptions.Item>
        <Descriptions.Item label={"PLC"}>
          {plcInformation?.plc_major_version}.
          {plcInformation?.plc_minor_version}
        </Descriptions.Item>
        <Descriptions.Item label={"HMI"}>
          {plcInformation?.hmi_major_version}.
          {plcInformation?.hmi_minor_version}
        </Descriptions.Item>
        <Descriptions.Item label={"Customer"}>
          {swr_summary.customer}
        </Descriptions.Item>
        {!isCustomer && (
          <Descriptions.Item label={"Location"}>
            {swr_summary.location}
          </Descriptions.Item>
        )}
        {robotInfo.summary && (
          <Descriptions.Item label={"Last Online"}>
            {swr_summary.last_online}
          </Descriptions.Item>
        )}

        {!isCustomer && (
          <Descriptions.Item label={"Power Source"}>
            {swr_summary.power_source}
          </Descriptions.Item>
        )}
        {!isCustomer && (
          <Descriptions.Item label={"Operator"}>
            {swr_summary.operator}
          </Descriptions.Item>
        )}
        <Descriptions.Item label={"Local Time"}>
          {swr_summary.local_time}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
