import React, { useState } from "react";

import { Button, Layout, Divider, Modal } from "antd";

import packageInfo from '../../package.json';

import EULAPage from "./EULAPage";

const { Footer } = Layout;

function LayoutFooter() {
  const [modalOpen, setModalOpen] = useState(false);
  const today = new Date();

  return (
    <Footer style={{ textAlign: "center" }}>
      ©{today.getFullYear()} Created by Novarc Technologies Inc. v{packageInfo.version}
      <Divider type="vertical" />
      <span style={{ cursor: "pointer" }} onClick={() => setModalOpen(true)}>
        End User License Agreement
      </span>
      <Modal
        title={null}
        width={"70%"}
        centered
        visible={modalOpen}
        onCancel={() => setModalOpen(false)}
        footer={
          <Button type="primary" onClick={() => setModalOpen(false)}>
            OK
          </Button>
        }
      >
        <EULAPage />
      </Modal>
    </Footer>
  );
}

export default LayoutFooter;
