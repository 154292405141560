import { createSlice } from "@reduxjs/toolkit";

import { chain, filter, find, includes, map, orderBy, uniqBy } from "lodash";

import { getProgramData, getWeldRuns } from "../../services/api/robot";
import { getFromS3URL } from "../../services/api";
import {
  DATE_REGEX,
  DATETIME_REGEX,
  DEFAULT_INITIAL_DATE,
} from "../../libs/constants";

import {
  novEyeVideos,
  novDataLogs,
  novEyeLogs,
  novDataDailyReport,
  getTimeZone,
} from "../../libs/utils";

import { loadData } from "../robot/RobotSearchFiles";

// import { parsePLCAlarms, getPLCAlarms, getNDaysPLC } from "../../libs/utils";

import { sortBy, flatten } from "lodash";
import moment from "moment";
import "moment-timezone";

const syncSlice = createSlice({
  name: "syncInfo",
  initialState: {
    fetching: false,
    fetchingError: null,
    data: [],
  },

  reducers: {
    fetchListRequest: (state) => ({
      ...state,
      fetching: true,
      fetchingError: null,
    }),

    fetchListFailure: (state, action) => ({
      ...state,
      fetching: false,
      fetchingError: action.error,
    }),

    fetchListSuccess: (state, action) => ({
      ...state,
      fetching: false,
      fetchingError: null,
    }),

    fetchRequest: (state) => ({
      ...state,
      fetching: true,
      fetchingError: null,
    }),

    fetchFailure: (state, action) => ({
      ...state,
      fetching: false,
      fetchingError: action.error,
    }),

    fetchSuccess: (state, action) => ({
      ...state,
      fetching: false,
      fetchingError: null,
      data: [...state.data, { ...action.payload }],
    }),
  },
});

export const {
  fetchListRequest,
  fetchListFailure,
  fetchListSuccess,
  fetchRequest,
  fetchFailure,
  fetchSuccess,
} = syncSlice.actions;

export default syncSlice.reducer;

/**
 * Get List of Robots
 * Get Weld Run per robot
 * Get Weld Video per robot
 * Get Daily report per robot
 * Get NovData log per robot
 */

export const getSyncList = (robotList, dateRange) => async (dispatch) => {
  try {
    dispatch(fetchListRequest());

    const lists = await Promise.all(
      robotList.map(async (id) => {
        const sync = await getRobotData(id, dateRange)(dispatch);
        return sync;
      })
    );
    dispatch(fetchListSuccess());
  } catch (err) {
    dispatch(fetchListFailure(err.toString()));
  }
};

const getRobotData = (id, query) => async (dispatch) => {
  try {
    dispatch(fetchRequest());
    console.log("syncSlice/getRobotData:::", id);

    const { data } = await getWeldRuns(id, {
      ...query,
      // Overriding get weld run with default start date to get last weld
      start: DEFAULT_INITIAL_DATE,
      per_page: 1,
    });
    const { data: videosData } = await getProgramData(id, {
      key: "videos",
      ...query,
    });
    const { data: novEyeLogsData } = await getProgramData(id, {
      key: "noveye_logs",
      ...query,
    });

    const { data: dailyReportsData } = await getProgramData(id, {
      key: "daily_reports",
      ...query,
    });

    const { data: novDataLogsData } = await getProgramData(id, {
      key: "novdata_logs",
      ...query,
    });

    const synced = check(
      data.slice(0, 1),
      novEyeVideos(videosData).slice(0, 1),
      novDataLogs(novDataLogsData).slice(0, 1),
      novDataDailyReport(dailyReportsData).slice(0, 1),
      novEyeLogs(novEyeLogsData).slice(0, 1)
    );
    dispatch(fetchSuccess({ id, ...synced }));
  } catch (err) {
    dispatch(fetchFailure(err.toString()));
  }
};

function check(weldRun, videos, tsv, reports, noveye_logs) {
  const lastWeldRun =
    weldRun && weldRun.length > 0
      ? moment
          .tz(weldRun[0].arc_on_utc, getTimeZone(weldRun[0].time_zone))
          .format("YYYY-MM-DD")
      : null;

  const lastDailyReport =
    reports && reports.length > 0
      ? [...reports[0].fileName.matchAll(DATE_REGEX)][0][0] // extract date from the fileName
      : null;

  // as video does not have timestamp. refer to uploaded At
  const lastVideo =
    videos && videos.length > 0
      ? videos[0].uploadedAt // this should be used if can't find with weld info.
      : //   but still not reliable as it is uploaded time
        null;

  const lastNovDataLog = tsv && tsv.length > 0 ? tsv[0].fileName : null;

  const lastNovEyeLog =
    noveye_logs && noveye_logs.length > 0 ? noveye_logs[0].fileName : null;

  const matchDateTime = [...lastNovEyeLog.matchAll(DATETIME_REGEX)][0];

  return {
    lastWeldRun,
    lastDailyReport,
    lastNovEyeLog: moment([
      matchDateTime[1],
      parseInt(matchDateTime[2], 10) - 1,
      matchDateTime[3],
    ]).format("YYYY-MM-DD"),
    // expected: loadData({
    //   videos,
    //   tsv,
    //   reports,
    //   noveye_logs,
    // }).dates[0],
  };
}
