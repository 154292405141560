import React from "react";
import { useParams } from "react-router-dom";

import { Card, Col, Row, Statistic } from "antd";

import LoadingPage from "../../components/LoadingPage";
import Error404Page from "../../components/Error404Page";

import AlarmList from "../alarms/AlarmList";
import { useRobotAlarms } from "../../libs/useAlarms";
import { LOADING_ANIMATION_SIZE_FULL } from "../../libs/constants";
import { normalizeRobotId } from "../../libs/utils";
import moment from "moment";
const RobotAlarmView = () => {
  const { robotName } = useParams();
  const robotId = normalizeRobotId(robotName);
  const dateRange = {
    start: moment().subtract(30, "days").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  };
  const data = useRobotAlarms(robotId, dateRange);

  if (!robotId) {
    return <Error404Page />;
  }

  if (!data) {
    return <LoadingPage size={LOADING_ANIMATION_SIZE_FULL} />;
  }

  const statsData = {
    warning: data.filter((item) => item.type === "warning").length || 0,
    error: data.filter((item) => item.type === "error").length || 0,
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Card title="" bordered={false}>
            <Statistic
              title={"Error"}
              value={statsData.error}
              valueStyle={{ color: "red" }}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="" bordered={false}>
            <Statistic
              title={"Warning"}
              value={statsData.warning}
              valueStyle={{ color: "orange" }}
            />
          </Card>
        </Col>
      </Row>
      <AlarmList alarms={data} />
    </>
  );
};

export default RobotAlarmView;
