import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";

import {
  Badge,
  Calendar as AntCalendar,
  Card,
  Col,
  Empty,
  Row,
  Select,
  Statistic,
  Tooltip,
  Typography,
} from "antd";

import { Bar } from "@ant-design/charts";

import moment from "moment";

import LoadingPage from "../../components/LoadingPage";
import { LOADING_ANIMATION_SIZE_FULL } from "../../libs/constants";
import { getRobotInfo } from "./robotSlice";
import { normalizeRobotId } from "../../libs/utils";

const today = moment();
const last30Days = today.clone().subtract(30, "days");

const { Title } = Typography;

const getPipeSize = (data) => {
  if (data && data.length > 0) {
    return data.map((item) => {
      return {
        ...item,
        pipe_type: `${item.pipe_size_inches}${
          item.units_system
            ? item.units_system === "imperial"
              ? "inches"
              : "m"
            : ""
        }_sche_${item.pipe_schedule}`,
      };
    });
  } else {
    return [];
  }
};

const RobotStats = () => {
  const { robotName } = useParams();
  const robotId = normalizeRobotId(robotName);
  const dispatch = useDispatch();

  const robotInfo = useSelector((state) => state.robot.info[robotId]);

  useEffect(() => {
    if (!robotInfo?.summary) {
      dispatch(getRobotInfo({ id: robotId }));
    }
  }, [dispatch, robotId]);

  if (!robotInfo) {
    return <LoadingPage size={LOADING_ANIMATION_SIZE_FULL} />;
  }

  if (!robotInfo?.summary?.data?.length) {
    return (
      <>
        <Title level={2}>Statistics</Title>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "50vh",
          }}
        >
          <Empty
            image={Empty.PRESENTED_IMAGE_DEFAULT}
            description={`No Weld Data since ${last30Days.format(
              "YYYY-MM-DD"
            )} or older`}
          />
        </div>
      </>
    );
  }

  const {
    date,
    data: [, weldHours = {}, weldInches = {}, weldPipe = {}],
  } = robotInfo.summary;

  const getListData = (value) => {
    let listData;

    weldHours.weld_time_per_day_in_month &&
      weldHours.weld_time_per_day_in_month.map((item) => {
        if (moment(item.date).toString() === moment(value).toString()) {
          listData = [{ type: "success", content: item.sum }];
        }
        return null;
      });

    weldInches.weld_inches_per_day_in_month &&
      weldInches.weld_inches_per_day_in_month.map((item) => {
        if (moment(item.date).toString() === moment(value).toString()) {
          listData = [...listData, { type: "warning", content: item.sum }];
        }
        return null;
      });

    return listData || [];
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <span className="events">
        {listData.map((item) => (
          <li key={item.content}>
            {item.content && item.type === "success" && (
              <Tooltip title={"Weld Time"}>
                <Badge
                  status={item.type}
                  text={`${item.content.split(".")[0]} (HH:mm:ss)`}
                />
              </Tooltip>
            )}
            {item.content && item.type === "warning" && (
              <Tooltip title={"Weld Inches"}>
                <Badge status={item.type} text={`${item.content} (inches)`} />
              </Tooltip>
            )}
          </li>
        ))}
      </span>
    );
  };
  const monthCellRender = (value) => {
    // const listData = getListData(value);
    return <span></span>;
  };

  // const fromDate =
  //   weldHours.weld_time_per_day_in_month &&
  //   weldHours.weld_time_per_day_in_month.length > 0 &&
  //   weldHours.weld_time_per_day_in_month[
  //     weldHours.weld_time_per_day_in_month.length - 1
  //   ].date;

  const config = {
    title: {
      visible: false,
      // text: "Overall Pipe size used",
      fontSize: 20,
    },
    height:
      400 +
      (weldPipe.pipes && weldPipe.pipes.length > 10
        ? weldPipe.pipes.length * 30
        : 0),
    // forceFit: true,
    data: getPipeSize(weldPipe.pipes),

    xField: "count",
    yField: "pipe_type",
    barSize: 20,
    label: {
      visible: true,
    },
  };

  return (
    <>
      <Title level={2}>Statistics</Title>
      <Title level={3}>{date}</Title>

      <Row gutter={16}>
        <Col span={12}>
          <Card>
            <Statistic title={"# of weld done"} value={weldHours.total_count} />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Statistic
              title={"Total Weld Time (HH:mm:ss)"}
              value={
                weldHours.total_hours && weldHours.total_hours.split(".")[0]
              }
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Statistic
              title={"# of weld done in last 7 days"}
              value={weldHours.count}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Statistic
              title={"Weld Time in last 7 days"}
              value={weldHours.hours}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Statistic
              title={"Total Weld Inches"}
              value={weldInches.total_inches}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Statistic
              title={"Weld Inches in last 7 days"}
              value={weldInches.inches}
            />
          </Card>
        </Col>
        <br></br>

        {weldHours.weld_time_per_day_in_month && (
          <Col span={24}>
            <Card>
              <AntCalendar
                // validRange={[moment(fromDate), moment(date)]}

                headerRender={({ value, type, onChange, onTypeChange }) => {
                  const start = 0;
                  const end = 12;
                  const monthOptions = [];

                  const current = value.clone();
                  const localeData = value.localeData();
                  const months = [];
                  for (let i = 0; i < 12; i++) {
                    current.month(i);
                    months.push(localeData.monthsShort(current));
                  }

                  for (let index = start; index < end; index++) {
                    monthOptions.push(
                      <Select.Option className="month-item" key={`${index}`}>
                        {months[index]}
                      </Select.Option>
                    );
                  }
                  const month = value.month();

                  const year = value.year();
                  const options = [];
                  for (let i = year - 10; i < year + 10; i += 1) {
                    options.push(
                      <Select.Option key={i} value={i} className="year-item">
                        {i}
                      </Select.Option>
                    );
                  }
                  return (
                    <div>
                      <Typography.Title level={4}>
                        Last 30 days of Weld Statistics
                      </Typography.Title>
                      <Row
                        gutter={8}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          padding: "12px 0",
                        }}
                      >
                        {/* <Col>
                          <Radio.Group
                            size="small"
                            onChange={(e) => onTypeChange(e.target.value)}
                            value={type}
                          >
                            <Radio.Button value="month">Month</Radio.Button>
                            <Radio.Button value="year">Year</Radio.Button>
                          </Radio.Group>
                        </Col> */}
                        <Col>
                          <Select
                            size="small"
                            dropdownMatchSelectWidth={false}
                            className="my-year-select"
                            onChange={(newYear) => {
                              const now = value.clone().year(newYear);
                              onChange(now);
                            }}
                            value={String(year)}
                          >
                            {options}
                          </Select>
                        </Col>
                        <Col>
                          <Select
                            size="small"
                            dropdownMatchSelectWidth={false}
                            value={String(month)}
                            onChange={(selectedMonth) => {
                              const newValue = value.clone();
                              newValue.month(parseInt(selectedMonth, 10));
                              onChange(newValue);
                            }}
                          >
                            {monthOptions}
                          </Select>
                        </Col>
                      </Row>
                    </div>
                  );
                }}
                defaultValue={moment(date)}
                dateCellRender={dateCellRender}
                monthCellRender={monthCellRender}
              />
            </Card>
          </Col>
        )}
        <Col span={24}>
          <Card>
            <Typography.Title level={4}>
              Recently Welded Pipe Size
            </Typography.Title>
            <Bar className="RobotStats_BarCharts" {...config} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default RobotStats;
