import React from "react";
import { Chart } from "react-google-charts";
import { GeolocationData } from "../robot/robotSlice";
import { uniqBy } from "lodash";
interface Props {
  geoData: GeolocationData[];
}
export function DistributionChart(props: Props) {
  const { geoData } = props;
  const countries = geoData.map((e) => e.country ?? "not deployed");
  const countryCountPairs = countries.map((country) => [
    country,
    countries.filter((e) => e === country).length,
  ]);
  const countryCountUnique = uniqBy(countryCountPairs, (e) => e[0]);
  const data = [["Country", "Deployed SWR Count"], ...countryCountUnique];
  return (
    <Chart
      className={"swr-distribution-chart"}
      chartType="PieChart"
      data={data}
      options={{
        title: "SWR count by Country",
        pieSliceText: "value",
          legend:{position:"right"}
      }}
    />
  );
}
