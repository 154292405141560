import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  getRobots,
  getOperationHours,
  getProgramData,
  getWeldRuns,
  getTier,
  getUsage,
  updateUsage,
  getRobotPerformanceWeldHours,
  getRobotPerformanceWeldInches,
  getRobotPerformanceOperatorsWeldHours,
  getRobotPerformanceOperatorsWeldInches,
  getRobotWeldTestConsent,
  putRobotWeldTestConsent,
  getWeldTestStatus,
  getWeldTestReports,
  createWeldTestReport,
  getWeldTestReport,
  updateWeldTestReport,
  deleteWeldTestReport,
  getNotificationsOnRobot,
  createNotificationOnRobot,
  updateNotificationOnRobot,
  getWeldRunFiles,
  putWeldFiles, getLastWeldRun,
} from "../../services/api/robot";

import { getFromS3URL } from "../../services/api";

import {
  latestSummary,
  softwareVersion,
  diskSpace,
  normalizeRobotName,
} from "../../libs/utils";

import { robots_with_coordinates } from "../../libs/mockData";
import * as FetchingHelper from "../../redux/fetchingHelper";
import { removeUndefinedKeys } from "../../libs/utilsTyped";
import { getGeolocation } from "../../services/api/robot";
import {windowsToIana} from "../../libs/windowsToIanaMap";

const sliceName = "robot";

//todo move these to centralized location for easier sync with backend
interface RobotData {
  id: number;
  name: string;
  customer: string;
  location: string;
  groups: any[];
}

interface LegacySwrInfoGeoData {
  coordinates;
  country;
  swr_information;
}
export interface GeolocationData{
  swr_id: number;
  name: string;
  coordinates: [number, number];
  address?: string;
  country?: string;
}
interface RobotInfo {
  id;
  summary;
  versions;
  space;
  current_files;
}
export interface WeldRunWithFiles {
  weld_run_id: number;
  robot_id: number;
  work_order_ref: string;
  spool_ref: string;
  welder_id_ref: string;
  run_number: number;
  arc_on_utc: string;
  time_zone: string;
  arc_off_utc: string;
  weld_files_id: number;
  weld_id: string;
  vid_filename: string | null;
  vid_hash: string | null;
  log_filename: string | null;
  log_hash: string | null;
}

const robotSlice = createSlice({
  name: sliceName,
  initialState: {
    ...FetchingHelper.getInitialState(),
    robots: {} as { [key: string]: RobotData },
    index: [] as number[],
    robotFiles: {} as { [key: string]: WeldRunWithFiles[] },
    legacySwrInfo: {} as { [key: string]: LegacySwrInfoGeoData },
    info: {} as { [key: string]: RobotInfo },
    geo: [] as GeolocationData[],
    timezone: {} as { [key: string]: string },

    operation_hours: undefined,
    weld_hours: undefined,
    weld_inches: undefined,
    weldHours: undefined,
    weldInches: undefined,
    weldHoursOperators: undefined,
    weldInchesOperators: undefined,
    weldRun: undefined,
    lastWeldRun: undefined,
    tier: undefined,
    usage: undefined,
    stats: undefined,
    weldTestConsent: undefined,
    weldTestReportsStatus: undefined,
    weldTestReports: undefined,
    notifications: undefined,
  },

  reducers: {
    fetchLegacySwrInfo: (state, action) => {
      const dataById = action.payload;
      for (const id of Object.keys(dataById)) {
        state.legacySwrInfo[id] = dataById[id];
      }
    },

    fetchPerformanceStatsRequest: (state) => state,
    fetchPerformanceStatsSuccess: (state, action) => ({
      ...state,
      stats: {
        ...state.stats,
        ...action.payload.stats,
      },
    }),
    fetchPerformanceStatsFailure: (state, action) => state,
  },
  extraReducers: (builder) => {
    builder
      .addCase(...FetchingHelper.createBasicPendingCase(getRobotsList))
      .addCase(getRobotsList.fulfilled, (state, action) => {
        FetchingHelper.completeFetch(state, getRobotsList.typePrefix);

        state.robots = action.payload.reduce(
          (acc, curr) => ({ ...acc, [curr.id]: curr }),
          {}
        );
        state.index = action.payload
          .map((e) => e.id)
          .sort((a, b) => {
            // sort in descending order based on displayed number (not internal num)
            const aNum = normalizeRobotName(a, false);
            const bNum = normalizeRobotName(b, false);
            return +bNum - +aNum;
          });
      })
      .addCase(...FetchingHelper.createBasicRejectedCase(getRobotsList))
      .addCase(...FetchingHelper.createBasicPendingCase(getRobotGeolocation))
      .addCase(
        ...FetchingHelper.createBasicFulfilledCase(getRobotGeolocation, "geo")
      )
      .addCase(...FetchingHelper.createBasicRejectedCase(getRobotGeolocation))
      .addCase(...FetchingHelper.createBasicPendingCase(getRobotInfo))
      .addCase(getRobotInfo.fulfilled, (state, action) => {
        FetchingHelper.completeFetch(state, getRobotInfo.typePrefix);

        const robotInfo = action.payload;
        state.info[robotInfo.id] = robotInfo;
      })
      .addCase(...FetchingHelper.createBasicRejectedCase(getRobotInfo))

      .addCase(...FetchingHelper.createBasicPendingCase(getRobotWeld))
      .addCase(
        ...FetchingHelper.createFulfilledCaseWithObjectMerge(
          getRobotWeld,
          "weldRun"
        )
      )
      .addCase(...FetchingHelper.createBasicRejectedCase(getRobotWeld))
      .addCase(...FetchingHelper.createBasicPendingCase(getRobotTimezone))
      .addCase(
        ...FetchingHelper.createFulfilledCaseWithObjectMerge(
          getRobotTimezone,
          "timezone"
        )
      )
      .addCase(...FetchingHelper.createBasicRejectedCase(getRobotTimezone))
      .addCase(...FetchingHelper.createBasicPendingCase(getRobotLastWeldDone))
      .addCase(
        ...FetchingHelper.createFulfilledCaseWithObjectMerge(
          getRobotLastWeldDone,
          "lastWeldRun"
        )
      )
      .addCase(...FetchingHelper.createBasicRejectedCase(getRobotLastWeldDone))

      .addCase(...FetchingHelper.createBasicPendingCase(getRobotWeldFiles))
      .addCase(
        ...FetchingHelper.createFulfilledCaseWithObjectMerge(
          getRobotWeldFiles,
          "robotFiles"
        )
      )
      .addCase(...FetchingHelper.createBasicRejectedCase(getRobotWeldFiles))

      .addCase(...FetchingHelper.createBasicPendingCase(updateRobotWeldFiles))
      .addCase(updateRobotWeldFiles.fulfilled, (state, action) => {
        FetchingHelper.completeFetch(state, updateRobotWeldFiles.typePrefix);

        const { robot_id, data } = action.payload;
        state.robotFiles[robot_id] = state.robotFiles[robot_id].map((e) => {
          if (e.weld_files_id === data.id) {
            return {
              ...e,
              vid_filename: data?.vid_filename,
              log_filename: data?.log_filename,
            };
          }
          return e;
        });
      })
      .addCase(...FetchingHelper.createBasicRejectedCase(updateRobotWeldFiles))

      .addCase(
        ...FetchingHelper.createBasicPendingCase(getRobotsOperationHours)
      )
      .addCase(
        ...FetchingHelper.createBasicFulfilledCase(
          getRobotsOperationHours,
          "operation_hours"
        )
      )
      .addCase(
        ...FetchingHelper.createBasicRejectedCase(getRobotsOperationHours)
      )

      .addCase(...FetchingHelper.createBasicPendingCase(getRobotWeldHoursList))
      .addCase(
        ...FetchingHelper.createFulfilledCaseWithObjectMerge(
          getRobotWeldHoursList,
          "weldHours"
        )
      )
      .addCase(...FetchingHelper.createBasicRejectedCase(getRobotWeldHoursList))
      .addCase(...FetchingHelper.createBasicPendingCase(getRobotWeldInchesList))
      .addCase(
        ...FetchingHelper.createFulfilledCaseWithObjectMerge(
          getRobotWeldInchesList,
          "weldInches"
        )
      )
      .addCase(
        ...FetchingHelper.createBasicRejectedCase(getRobotWeldInchesList)
      )
      .addCase(
        ...FetchingHelper.createBasicPendingCase(getRobotOperatorsWeldHoursList)
      )
      .addCase(
        ...FetchingHelper.createFulfilledCaseWithObjectMerge(
          getRobotOperatorsWeldHoursList,
          "weldHoursOperators"
        )
      )
      .addCase(
        ...FetchingHelper.createBasicRejectedCase(
          getRobotOperatorsWeldHoursList
        )
      )
      .addCase(
        ...FetchingHelper.createBasicPendingCase(
          getRobotOperatorsWeldInchesList
        )
      )
      .addCase(
        ...FetchingHelper.createFulfilledCaseWithObjectMerge(
          getRobotOperatorsWeldInchesList,
          "weldInchesOperators"
        )
      )
      .addCase(
        ...FetchingHelper.createBasicRejectedCase(
          getRobotOperatorsWeldInchesList
        )
      )

      .addCase(...FetchingHelper.createBasicPendingCase(getRobotTier))
      .addCase(
        ...FetchingHelper.createFulfilledCaseWithObjectMerge(
          getRobotTier,
          "tier"
        )
      )
      .addCase(...FetchingHelper.createBasicRejectedCase(getRobotTier))
      .addCase(...FetchingHelper.createBasicPendingCase(updateRobotUsage))
      .addCase(
        ...FetchingHelper.createFulfilledCaseWithObjectMerge(
          updateRobotUsage,
          "tier"
        )
      )
      .addCase(...FetchingHelper.createBasicRejectedCase(updateRobotUsage))
      .addCase(...FetchingHelper.createBasicPendingCase(fetchRobotUsageList))
      .addCase(
        ...FetchingHelper.createFulfilledCaseWithObjectMerge(
          fetchRobotUsageList,
          "tier"
        )
      )
      .addCase(...FetchingHelper.createBasicRejectedCase(fetchRobotUsageList))

      .addCase(
        ...FetchingHelper.createBasicPendingCase(getRobotWeldTestReportsStatus)
      )
      .addCase(
        ...FetchingHelper.createBasicFulfilledCase(
          getRobotWeldTestReportsStatus,
          "weldTestReportsStatus"
        )
      )
      .addCase(
        ...FetchingHelper.createBasicRejectedCase(getRobotWeldTestReportsStatus)
      )
      .addCase(
        ...FetchingHelper.createBasicPendingCase(getRobotWeldTestReportsList)
      )
      .addCase(
        ...FetchingHelper.createBasicFulfilledCase(
          getRobotWeldTestReportsList,
          "weldTestReports"
        )
      )
      .addCase(
        ...FetchingHelper.createBasicRejectedCase(getRobotWeldTestReportsList)
      )

      .addCase(
        ...FetchingHelper.createBasicPendingCase(createRobotWeldTestReport)
      )
      .addCase(
        ...FetchingHelper.createFulfilledCaseWithObjectMerge(
          createRobotWeldTestReport,
          "weldTestReports"
        )
      )
      .addCase(
        ...FetchingHelper.createBasicRejectedCase(createRobotWeldTestReport)
      )
      .addCase(
        ...FetchingHelper.createBasicPendingCase(removeRobotWeldTestReport)
      )
      .addCase(removeRobotWeldTestReport.fulfilled, (state, action) => {
        FetchingHelper.completeFetch(
          state,
          removeRobotWeldTestReport.typePrefix
        );

        //precondition requires weldTestReports to not be null prior to remove call
        state.weldTestReports = state.weldTestReports.map((obj) =>
          obj.id === action.payload.id
            ? {
                ...obj,
                ...action.payload,
              }
            : obj
        );
      })
      .addCase(
        ...FetchingHelper.createBasicRejectedCase(removeRobotWeldTestReport)
      )

      .addCase(
        ...FetchingHelper.createBasicPendingCase(getRobotNotificationsList)
      )
      .addCase(
        ...FetchingHelper.createFulfilledCaseWithListAppend(
          getRobotNotificationsList,
          "notifications",
          // note - carried over from previous behavior
          //   only checks if value is not null, rather than checking the list
          (list, value) => !!value
        )
      )
      .addCase(
        ...FetchingHelper.createBasicRejectedCase(getRobotNotificationsList)
      )
      .addCase(
        ...FetchingHelper.createBasicPendingCase(createRobotNotification)
      )
      .addCase(
        ...FetchingHelper.createFulfilledCaseWithListAppend(
          createRobotNotification,
          "notifications",
          // note - carried over from previous behavior
          //   only checks if value is not null, rather than checking the list
          (list, value) => !!value
        )
      )
      .addCase(
        ...FetchingHelper.createBasicRejectedCase(createRobotNotification)
      )
      .addCase(
        ...FetchingHelper.createBasicPendingCase(updateRobotNotification)
      )
      .addCase(updateRobotNotification.fulfilled, (state, action) => {
        FetchingHelper.completeFetch(state, updateRobotNotification.typePrefix);

        state.notifications = state.notifications.filter((item) => {
          return item.id !== action.payload?.id;
        });
      })
      .addCase(
        ...FetchingHelper.createBasicRejectedCase(updateRobotNotification)
      )

      .addCase(
        ...FetchingHelper.createBasicPendingCase(getRobotDataShareConsent)
      )
      .addCase(
        ...FetchingHelper.createBasicFulfilledCase(
          getRobotDataShareConsent,
          "weldTestConsent"
        )
      )
      .addCase(
        ...FetchingHelper.createBasicRejectedCase(getRobotDataShareConsent)
      )
      .addCase(
        ...FetchingHelper.createBasicPendingCase(putRobotDataShareConsent)
      )
      .addCase(
        ...FetchingHelper.createBasicFulfilledCase(
          putRobotDataShareConsent,
          "weldTestConsent"
        )
      )
      .addCase(
        ...FetchingHelper.createBasicRejectedCase(putRobotDataShareConsent)
      );
  },
});

export const {
  fetchLegacySwrInfo,

  // ##############################
  // Performance
  // ##############################

  fetchPerformanceStatsRequest,
  fetchPerformanceStatsSuccess,
  fetchPerformanceStatsFailure,
} = robotSlice.actions;

export default robotSlice.reducer;

// export const {
//   selectById: selectRobotById,
//   selectIds: selectRobotIds,
//   selectEntities: selectRobotEntities,
//   selectAll: selectAllRobots,
//   selectTotal: selectTotalRobots,
// } = robotsAdapter.getSelectors((state) => state.robots);

export const getRobotsList = createAsyncThunk<any, void>(
  `${sliceName}/getRobotsList`,
  async () => getRobots()
);

export const getRobotGeolocation = createAsyncThunk(
  `${sliceName}/getRobotGeolocation`,
  async () => getGeolocation()
);

//for maintaining backward compatibility, get the hardcoded swr_information data
export const getLegacySwrInformation =
  (robotList: number[]) => async (dispatch) => {
    try {
      const robotData = robotList
        // map produces the shape [{id1:{...data}}, {id2:{...data}}, ...]
        .map((id) => {
          /*
          FIXME: This uses manual dummy data
          Coordinate information will be retrieved from src/libs/mockdata
        */
          const { coordinates, country, swr_information } =
            robots_with_coordinates.find((i) => i.id === id) || {};

          return {
            [id]: {
              coordinates,
              country,
              // not sure what we need the swr_information for
              // it should probably be separated from Geo
              swr_information,
            },
          };
        })
        // final shape {id1:{...data}, id2:{...data},}
        .reduce((acc, curr) => ({ ...acc, ...curr }), {});

      dispatch(fetchLegacySwrInfo(robotData));
    } catch (err) {
      console.error("getRobotsGeo", err);
    }
  };

export const getRobotsOperationHours = createAsyncThunk<any, void>(
  `${sliceName}/getRobotsOperationHours`,
  async () => {
    return { data: await getOperationHours() };
  }
);

export const getRobotInfo = createAsyncThunk<
  { id: number; summary: any; versions: any; space: any; current_files: {} },
  { id: number; start?: string; end?: string }
>(`${sliceName}/getRobotInfo`, async ({ id, start, end }) => {
  const query = removeUndefinedKeys(
    {
      key: "health_logs",
      start,
      end,
    },
    ["start", "end"]
  );
  const { data } = await getProgramData(id, query);

  // software_version.json
  // nh.log
  // disk_space.json
  // latest Data-YYYYMMDD.json
  const summaryItem = latestSummary(data);
  const softwareVersionItem = softwareVersion(data);
  const diskSpaceItem = diskSpace(data);
  // const currentFilesItem = currentFiles(data);

  const summary = summaryItem ? await getFromS3URL(summaryItem.url) : {};
  const versions = await getFromS3URL(softwareVersionItem.url);
  const space = await getFromS3URL(diskSpaceItem.url);

  // unused Legacy item:
  // This is new feature added along with NovSync > 2.0.3
  const current_files = {};
  return { id, summary, versions, space, current_files };
});

export const getRobotWeld = createAsyncThunk<
  any,
  { id: string; start?: string; end?: string }
>(`${sliceName}/getRobotWeld`, async ({ id, start, end }) => {
  const query = removeUndefinedKeys({
    start,
    end,
  });
  const { data } = await getWeldRuns(id, query);
  return { [id]: data };
});

export const getRobotWeldFiles = createAsyncThunk<
  any,
  { id: string; start?: string; end?: string }
>(`${sliceName}/getRobotWeldFiles`, async ({ id, start, end }) => {
  const query = removeUndefinedKeys({
    start,
    end,
  });
  const { data } = await getWeldRunFiles(id, query);
  return { [id]: data };
});

export const updateRobotWeldFiles = createAsyncThunk<
  any,
  { id: number; robot_id: number; vid_filename?: string; log_filename?: string }
>(
  `${sliceName}/updateRobotWeldFiles`,
  async ({ id, robot_id, vid_filename, log_filename }, thunkAPI) => {
    const updateVals = removeUndefinedKeys({
      id,
      vid_filename,
      log_filename,
    });
    const { data } = await putWeldFiles(robot_id, updateVals);
    // console.log("updated weld_files data for:", robot_id, data);

    // getState gets the root state so must get the sub-object
    const state = thunkAPI.getState()[sliceName] as ReturnType<
      typeof robotSlice.getInitialState
    >;
    console.warn(state);
    if (!state.robotFiles?.[robot_id]?.length) {
      console.error(
        "updateRobotWeldFiles: state.robotFiles not initialised - update function possibly called before GET",
        robot_id,
        id,
        vid_filename,
        log_filename
      );
      throw new Error("Unexpected result in updateRobotWeldFiles");
    }
    return { robot_id, data };
  }
);

export const getRobotTimezone = createAsyncThunk<
  any,
  { id: string; }
>(`${sliceName}/getRobotTimezone`, async ({ id }) => {
  const { data } = await getLastWeldRun(id);
  const timezoneWindows = data?.time_zone;
  const timezoneIana = windowsToIana(timezoneWindows) || null;
  return { [id]: timezoneIana };
});

// fixme this action does exactly the same thing as getRobotWeld
export const getRobotLastWeldDone = createAsyncThunk<
  any,
  { id: string; start?: string; end?: string }
>(`${sliceName}/getRobotLastWeldDone`, async ({ id, start, end }) => {
  const query = removeUndefinedKeys({
    start,
    end,
  });
  const { data } = await getWeldRuns(id, query);
  return { [id]: data };
});

//to be moved to an API interfaces file later
interface DownloadUsageResponse {
  data: {
    id: number;
    robot_id: number;
    tier: string;
    used: string; //numeric
    capacity: string; //numeric
    recurring_date: number;
    created_at: string;
  };
}

export const getRobotTier = createAsyncThunk<
  { [key: string]: DownloadUsageResponse["data"] },
  string
>(`${sliceName}/getRobotTier`, async (id) => {
  return { [id]: (await getTier(id))?.data };
});

export const updateRobotUsage = createAsyncThunk<
  { [key: string]: DownloadUsageResponse["data"] },
  { id: string; payload: { size; fileName; activity } }
>(`${sliceName}/updateRobotUsage`, async ({ id, payload }) => {
  const { data } = await updateUsage(id, {
    used: payload.size,
    note: payload.fileName,
    // allowable activity set is unclear, and usage seems to be updated regardless of preview or download
    activity: payload.activity,
  });

  // the returned data is same as the tier response
  return { [id]: data };
});

// NO USAGES
// noinspection JSUnusedLocalSymbols
const fetchRobotUsageList = createAsyncThunk<
  any,
  { id: string; payload: { month; year } }
>(`${sliceName}/fetchRobotUsageList`, async ({ id, payload }) => {
  const { data } = await getUsage(id, {
    month: payload.month,
    year: payload.year,
  });

  return { [id]: data };
});

//todo migrate to performance slice
export const getRobotStats =
  (id, { initial, recent }) =>
  async (dispatch) => {
    try {
      dispatch(fetchPerformanceStatsRequest());

      const totalWeldTimes = await getRobotPerformanceWeldHours(id, {
        start: initial,
      });
      const totalWeldInches = await getRobotPerformanceWeldInches(id, {
        start: initial,
      });
      const weldTimes = await getRobotPerformanceWeldHours(id, {
        start: recent,
      });
      const weldInches = await getRobotPerformanceWeldInches(id, {
        start: recent,
      });

      dispatch(
        fetchPerformanceStatsSuccess({
          stats: {
            [id]: {
              totalWeldTimes,
              totalWeldInches,
              weldTimes,
              weldInches,
            },
          },
        })
      );
    } catch (err) {
      dispatch(fetchPerformanceStatsFailure(err.toString()));
    }
  };

export const getRobotWeldHoursList = createAsyncThunk<
  any,
  { id: string; start?: string; end?: string }
>(`${sliceName}/getRobotWeldHoursList`, async ({ id, start, end }) => {
  const query = removeUndefinedKeys({
    start,
    end,
  });
  const data = await getRobotPerformanceWeldHours(id, query);
  return { [id]: data };
});
export const getRobotWeldInchesList = createAsyncThunk<
  any,
  { id: string; start?: string; end?: string }
>(`${sliceName}/getRobotWeldInchesList`, async ({ id, start, end }) => {
  const query = removeUndefinedKeys({
    start,
    end,
  });
  const data = await getRobotPerformanceWeldInches(id, query);
  return { [id]: data };
});
export const getRobotOperatorsWeldHoursList = createAsyncThunk<
  any,
  { id: string; start?: string; end?: string }
>(`${sliceName}/getRobotOperatorsWeldHoursList`, async ({ id, start, end }) => {
  const query = removeUndefinedKeys({
    start,
    end,
  });
  const data = await getRobotPerformanceOperatorsWeldHours(id, query);
  return { [id]: data };
});
export const getRobotOperatorsWeldInchesList = createAsyncThunk<
  any,
  { id: string; start?: string; end?: string }
>(
  `${sliceName}/getRobotOperatorsWeldInchesList`,
  async ({ id, start, end }) => {
    const query = removeUndefinedKeys({
      start,
      end,
    });
    const data = await getRobotPerformanceOperatorsWeldInches(id, query);
    return { [id]: data };
  }
);

export const getRobotDataShareConsent = createAsyncThunk<any, string>(
  `${sliceName}/getRobotDataShareConsent`,
  async (robot_id) => {
    return getRobotWeldTestConsent(robot_id);
  }
);
export const putRobotDataShareConsent = createAsyncThunk<
  any,
  { robot_id: string; payload: any }
>(`${sliceName}/putRobotDataShareConsent`, async ({ robot_id, payload }) => {
  return putRobotWeldTestConsent(robot_id, payload);
});

export const getRobotWeldTestReportsStatus = createAsyncThunk<
  any,
  { id: string; start?: string; end?: string }
>(`${sliceName}/getRobotWeldTestReportsStatus`, async ({ id, start, end }) => {
  const query = removeUndefinedKeys({
    start,
    end,
  });
  return getWeldTestStatus(id, query);
});

export const getRobotWeldTestReportsList = createAsyncThunk<
  any,
  {
    id: string;
    query: {
      work_order_ref: string;
      spool_ref: string;
      welder_id_ref: string;
      start?;
      end?;
    };
  }
>(`${sliceName}/getRobotWeldTestReportsList`, async ({ id, query }) => {
  return getWeldTestReports(id, query);
});

export const createRobotWeldTestReport = createAsyncThunk<
  any,
  { id: string; payload: any }
>(`${sliceName}/createRobotWeldTestReport`, async ({ id, payload }) => {
  return (await createWeldTestReport(id, payload)).data;
});

// NO USAGES
// noinspection JSUnusedLocalSymbols
const getRobotWeldTestReport = createAsyncThunk<
  any,
  { params: { robot_id; report_id }; query: any }
>(`${sliceName}/getRobotWeldTestReport`, async ({ params, query }) => {
  return getWeldTestReport(params, query);
});

// NO USAGES
// noinspection JSUnusedLocalSymbols
const updateRobotWeldTestReport = createAsyncThunk<
  any,
  { params: { robot_id; report_id }; payload: any }
>(`${sliceName}/updateRobotWeldTestReport`, async ({ params, payload }) => {
  return updateWeldTestReport(params, payload);
});

export const removeRobotWeldTestReport = createAsyncThunk<
  any,
  { robot_id: string; report_id: any }
>(`${sliceName}/removeRobotWeldTestReport`, async (params) => {
  return (await deleteWeldTestReport(params)).data;
});

export const getRobotNotificationsList = createAsyncThunk<any, string>(
  `${sliceName}/getRobotNotificationsList`,
  async (robot_id) => {
    return (await getNotificationsOnRobot({ robot_id }))?.data;
  }
);

export const createRobotNotification = createAsyncThunk<
  any,
  { robot_id: string; payload: any }
>(`${sliceName}/createRobotNotification`, async ({ robot_id, payload }) => {
  return (await createNotificationOnRobot({ robot_id }, payload))?.data;
});

export const updateRobotNotification = createAsyncThunk<
  any,
  { robot_id: string; notification_id: any; payload: any }
>(
  `${sliceName}/updateRobotNotification`,
  async ({ robot_id, notification_id, payload }) => {
    return (
      await updateNotificationOnRobot({ robot_id, notification_id }, payload)
    )?.data;
  }
);
