import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dropdown, Button, Menu, Tabs, Skeleton } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";

import { Heatmap, Column } from "@ant-design/charts";

import { chain, sumBy } from "lodash";
// import moment from "moment";

import { getRobotsOperationHours } from "./robotSlice";

import LoadingPage from "../../components/LoadingPage";

// import { getDateList } from "../../libs/utils";
// import { DEFAULT_LOCALE } from "../../libs/constants";

const { TabPane } = Tabs;

const filterData = [
  { key: 1, title: "Daily" },
  { key: 2, title: "Weekly" },
];

function RobotOperationHours() {
  const dispatch = useDispatch();
  const [dropdownKey, setDropdownKey] = useState(1);

  const data = useSelector((state) => state.robot.operation_hours);
  // const today = new Date().toLocaleDateString(DEFAULT_LOCALE);
  // const lastWeek = moment(today).subtract(7, "days").format("YYYY-MM-DD");

  useEffect(() => {
    dispatch(getRobotsOperationHours());
  }, [dispatch]);

  if (!data) {
    return (
      <Skeleton.Node
        active={true}
        style={{ height: 200, width: "100%", background: "white" }}
      >
        <LoadingPage size={100} />
      </Skeleton.Node>
    );
  }

  // Mock Data
  // const data = getDateList(lastWeek, today).map((item, index) => {
  //   const weekData = [...Array(24).keys()].map((i) => {
  //     return {
  //       ...item,
  //       hour: i.toString(),
  //       day:
  //         moment(item.date).isoWeekday() === 7
  //           ? 0
  //           : moment(item.date).isoWeekday(),
  //       value: random(20),
  //     };
  //   });

  //   return weekData;
  // });

  // const flattenData = flatten(data);

  const lastDayData = chain(data)
    .groupBy("hour")
    .map((item, key) => {
      return { hour: key, value: sumBy(item, "value") };
    })
    .value();

  return (
    <>
      <Tabs
        className="Blank_active_tab"
        defaultActiveKey="1"
        size={"small"}
        type="card"
        tabBarExtraContent={
          <Dropdown
            overlay={
              <Menu>
                {filterData.map((item) => (
                  <Menu.Item
                    key={item.key}
                    onClick={() => {
                      setDropdownKey(item.key);
                    }}
                  >
                    {item.title}
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <Button size={"small"}>
              {filterData.find(({ key }) => key === dropdownKey).title}
              <CaretDownOutlined />
            </Button>
          </Dropdown>
        }
      >
        <TabPane tab={null} key="1" style={{ height: 210 }}>
          {dropdownKey === 1 && (
            <Column
              {...{
                data: lastDayData,

                xField: "hour",
                yField: "value",
                columnWidthRatio: 0.95,
                tooltip: {
                  showMarkers: false,
                  position: "top",
                },
                interactions: [{ type: "element-highlight" }],
                height: 200,
                meta: {
                  range: {
                    min: 0,
                    max: 24,
                    tickInterval: 2,
                  },
                  values: {
                    // max: 20,
                    nice: true,
                  },
                },
                // tooltip: { title: "date" },
              }}
            />
          )}
          {dropdownKey === 2 && (
            <Heatmap
              {...{
                data: data,
                xField: "hour",
                yField: "day",
                colorField: "value",
                height: 200,
                color: [
                  "#d9d9d9",
                  "#e6f7ff",
                  "#bae7ff",
                  "#91d5ff",
                  "#69c0ff",
                  "#40a9ff",
                  "#1890ff",
                  "#096dd9",
                  "#0050b3",
                  "#003a8c",
                  "#002766",
                ],
                reflect: "y",
                shape: "boundary-polygon",
                meta: {
                  day: {
                    type: "cat",
                    values: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                  },
                  hour: {
                    // range: [0, 23],
                  },

                  week: { type: "cat" },
                  value: { sync: true },
                  date: { type: "cat" },
                },
                tooltip: {
                  title: "date",
                },
              }}
            />
          )}
        </TabPane>
      </Tabs>
    </>
  );
}
export default RobotOperationHours;
