import React from "react";

import RobotNavigation from "../features/robot/RobotNavigation";
import RobotAnalytics from "../features/robot/RobotAnalytics";

function AnalyticsPage(props) {
  return (
    <>
      <RobotNavigation {...props} />
      <RobotAnalytics {...props} />
    </>
  );
}

export default AnalyticsPage;
