import React from "react";

import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
} from "@react-pdf/renderer";

import { Button } from "antd";

import { CloudDownloadOutlined, LoadingOutlined } from "@ant-design/icons";

import { blue, grey } from "@ant-design/colors";

import NovLogo from "../../assets/novarc-logo.png";
import moment from "moment";
import "moment-timezone";

import {
  convertInchesTomm,
  getPipeSchedule,
  getTimeZone,
} from "../../libs/utils";

const NUM_ROWS_IN_FIRST_PAGE = 13;
const NUM_ROWS_PER_PAGE = 17;

const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth / 1.5,
    height: window.innerHeight / 2,
  },

  page: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    lineHeight: 1.5,
    flexDirection: "column",
  },

  container: {
    // marginBottom: 100,
    // paddingBottom: 60,
  },

  logo: {
    width: 200,
    layout: "right",
    marginRight: -12,
  },

  title: {
    marginRight: 4,
    fontSize: 14,
    fontWeight: "bold",
    color: blue[7], //#096dd9
  },

  summary: {
    marginRight: 4,
    fontSize: 10,
    fontWeight: "bold",
  },

  findPrint: {
    fontSize: 8,
    color: grey[3],
  },

  row: {
    flexGrow: 1,
    flexDirection: "row",
  },

  headerContainer: {
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 28.5,
  },

  titleContainer: {
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
  },

  summaryContainer: {
    fontSize: 10,
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    marginBottom: 8,
  },

  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    borderWidth: 1,
    borderTopWidth: 0,
    borderColor: "black", //#000000
  },

  tableHeader: {
    fontSize: 9,
    flexDirection: "row",
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
    borderTopWidth: 1,
    borderColor: "black", //#000000
    backgroundColor: grey[3],
  },

  tableHeaderCell: {
    width: "9%",
  },

  tableHeaderCellSmall: {
    width: "5%",
  },

  tableHeaderCellWide: {
    width: "18%",
  },

  tableRowOdd: {
    fontSize: 8,
    flexDirection: "row",
    backgroundColor: "white", //#ffffff
    borderTopWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },

  tableRowEven: {
    fontSize: 8,
    flexDirection: "row",
    backgroundColor: grey[0],
    borderTopWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },

  footer: {
    position: "absolute",
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: grey[3],
  },
});

function generateReportTitle(type) {
  let title = "";
  switch (type) {
    case "date":
      title = "Daily ";
      break;
    case "week":
      title = "Weekly ";
      break;
    case "month":
      title = "Monthly ";
      break;
    case "quarter":
      title = "Quarterly ";
      break;
    case "year ":
      title = "Yearly ";
      break;
    default:
      break;
  }
  return title + "Operations Report";
}

function getCumulativeFDI(items) {
  return items
    .filter((item) => {
      return (
        Number(moment.duration({ ...item.weld_time }).asMinutes()) >=
        Number(moment.duration({ minutes: 1 }).asMinutes())
      );
    })
    .reduce((prev, curr) => {
      return prev + curr.weld_fdi;
    }, 0)
    .toFixed(1);
}

function getCumulativeDI(items) {
  return items
    .filter((item) => {
      return (
        Number(moment.duration({ ...item.weld_time }).asMinutes()) >=
        Number(moment.duration({ minutes: 1 }).asMinutes())
      );
    })
    .reduce((prev, curr) => {
      return prev + curr.weld_di;
    }, 0)
    .toFixed(1);
}

function getWeldTime(items) {
  return items.slice(1).reduce((prev, curr) => {
    return prev.add(curr.weld_time);
  }, moment.duration(items[0].weld_time))._data;
}

function getSetupTime(items) {
  return items.slice(1).reduce((prev, curr) => {
    if (curr.duration) {
      return prev.add(curr.duration._data);
    } else {
      return prev.add(moment.duration(0));
    }
  }, moment.duration(0))._data;
}

function parseWeldTime(duration) {
  const hours = duration.hours() || false;
  const minutes = duration.minutes() || false;
  const seconds = duration.seconds() || false;

  return `${hours ? hours + "h " : ""}${minutes ? minutes + "m " : ""}${
    seconds ? seconds + "s" : ""
  }`;
}

function PDFDocument(props) {
  const { robotName, weldRun, date, type } = props;
  return (
    <Document
      title={
        robotName +
        " " +
        generateReportTitle(type) +
        " " +
        moment(date[0]).format("YYYY-MM-DD") +
        " " +
        moment(date[1]).format("YYYY-MM-DD") +
        ".pdf"
      }
      author="Novarc Technologies Inc"
      subject={robotName + "_Operations Report"}
    >
      {/*render a single page*/}
      <Page
        size="LETTER"
        orientation="landscape"
        wrap
        // debug
        style={styles.page}
      >
        <View style={styles.container}>
          <View style={styles.headerContainer} fixed>
            <View style={styles.titleContainer}>
              <Text style={styles.title}>{robotName}</Text>
              <Text style={styles.title}>{generateReportTitle(type)}</Text>
              <Text style={styles.title}>
                {moment(date[0]).format("ddd, MMMM DD, YYYY")}
                {" ~ "}
                {moment(date[1]).format("ddd, MMMM DD, YYYY")}
              </Text>
            </View>
            <Image src={NovLogo} style={styles.logo}></Image>
          </View>
          <View style={styles.summaryContainer}>
            <View style={styles.row}>
              <Text style={styles.summary}>Weld Time:</Text>
              <Text>
                {parseWeldTime(moment.duration(getWeldTime(weldRun)))}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.summary}>Arc Off Time:</Text>
              <Text>
                {parseWeldTime(moment.duration(getSetupTime(weldRun)))}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.summary}>Production Time:</Text>
              <Text>
                {parseWeldTime(
                  moment
                    .duration(getWeldTime(weldRun))
                    .add(moment.duration(getSetupTime(weldRun)))
                )}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.summary}>Cumulative FDI:</Text>
              <Text>{getCumulativeFDI(weldRun)}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.summary}>Cumulative DI:</Text>
              <Text>{getCumulativeDI(weldRun)}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.findPrint}>
                *For FDI calculations purposes, every weld more than 1 minute is
                considered a new weld.
              </Text>
            </View>
          </View>

          <View style={styles.tableContainer}>
            <View style={[styles.row, styles.tableHeader]} fixed>
              <Text style={styles.tableHeaderCellSmall}>Date</Text>
              <Text
                style={styles.tableHeaderCellSmall}
                // tableHeaderCell
                // tableHeaderCellSmall
                // tableHeaderCellWide
              >
                {" "}
              </Text>
              <Text style={styles.tableHeaderCell}>Job Id</Text>
              <Text style={styles.tableHeaderCell}>Spool</Text>
              <Text style={styles.tableHeaderCell}>Weld Id</Text>
              <Text style={styles.tableHeaderCellSmall}>Run</Text>
              <Text style={styles.tableHeaderCell}>Start Time</Text>
              <Text style={styles.tableHeaderCell}>Arc Off Time</Text>
              <Text style={styles.tableHeaderCell}>Arc On Time</Text>
              <Text style={styles.tableHeaderCell}>Operator</Text>
              <Text style={styles.tableHeaderCell}>Size-Sched</Text>
              <Text style={styles.tableHeaderCellWide}>Procedure</Text>
            </View>

            {weldRun.map((item, idx) => {
              const isMetric = item.units_system === "metric";
              const isNewPage =
                idx === NUM_ROWS_IN_FIRST_PAGE ||
                (NUM_ROWS_IN_FIRST_PAGE > 13 &&
                  (idx - NUM_ROWS_IN_FIRST_PAGE) % NUM_ROWS_PER_PAGE === 0);
              return (
                <View
                  key={idx}
                  style={[
                    styles.row,
                    idx % 2 === 0 ? styles.tableRowEven : styles.tableRowOdd,
                  ]}
                  break={isNewPage}
                >
                  <Text style={styles.tableHeaderCellSmall}>
                    {moment
                      .tz(item.arc_on_at, getTimeZone(item.time_zone))
                      .format("DD/MM/YY")}
                  </Text>
                  <Text style={styles.tableHeaderCellSmall}>{idx + 1}</Text>
                  <Text style={styles.tableHeaderCell}>
                    {item.work_order_ref}
                  </Text>
                  <Text style={styles.tableHeaderCell}>{item.spool_ref}</Text>
                  <Text style={styles.tableHeaderCell}>
                    {item.welder_id_ref}
                  </Text>
                  <Text style={styles.tableHeaderCellSmall}>
                    {item.run_number}
                  </Text>
                  <Text style={styles.tableHeaderCell}>
                    {moment
                      .tz(item.arc_on_at, getTimeZone(item.time_zone))
                      .format("HH:mm:ss")}
                  </Text>
                  <Text style={styles.tableHeaderCell}>
                    {item.duration
                      ? parseWeldTime(
                          moment.duration({ ...item.duration._data })
                        )
                      : ""}
                  </Text>
                  <Text style={styles.tableHeaderCell}>
                    {parseWeldTime(moment.duration({ ...item.weld_time }))}
                  </Text>
                  <Text style={styles.tableHeaderCell}>
                    {item.operator_name}
                  </Text>
                  <Text style={styles.tableHeaderCell}>
                    {isMetric
                      ? convertInchesTomm(item.pipe_size_inches).toFixed(0)
                      : item.pipe_size_inches}
                    {isMetric ? "mm" : "in"}-
                    {getPipeSchedule(item.pipe_schedule)}
                  </Text>
                  <Text style={styles.tableHeaderCellWide}>
                    {item.procedure_name}
                  </Text>
                </View>
              );
            })}
          </View>
        </View>
        <Text
          style={styles.footer}
          render={({ pageNumber, totalPages }) =>
            `Generated via NovSync ${moment().format(
              "YYYY-MM-DD"
            )} \n ${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}

export function PDFPreview(props) {
  return (
    <>
      <PDFViewer style={styles.viewer} showToolbar={!true}>
        <PDFDocument {...props}></PDFDocument>
      </PDFViewer>
    </>
  );
}

export function PDFDownload(props) {
  const { robotName, date, type } = props;
  return (
    <PDFDownloadLink
      document={<PDFDocument {...props}></PDFDocument>}
      fileName={
        robotName +
        " " +
        generateReportTitle(type) +
        " " +
        moment(date[0]).format("YYYY-MM-DD") +
        " " +
        moment(date[1]).format("YYYY-MM-DD") +
        ".pdf"
      }
    >
      {({ blob, url, loading, error }) =>
        loading ? (
          <Button icon={<LoadingOutlined />}></Button>
        ) : (
          <Button icon={<CloudDownloadOutlined />}></Button>
        )
      }
    </PDFDownloadLink>
  );
}
