import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import moment from "moment";

import LoadingPage from "../../components/LoadingPage";
import Error404Page from "../../components/Error404Page";

import { LOADING_ANIMATION_SIZE_FULL } from "../../libs/constants";

import RobotInfo from "./RobotInfo";
import RobotAlarmStatistics from "../alarms/RobotAlarmStatistics";

import { getRobotWeld, getRobotTier } from "./robotSlice";
import StatusBanner from "../alarms/StatusBanner";
import { useRobotAlarms } from "../../libs/useAlarms";
import { normalizeRobotId } from "../../libs/utils";
const RobotSummary = (props) => {
  const { robotName } = useParams();
  const robotId = normalizeRobotId(robotName);
  const robotWeldRun = useSelector((state) => state.robot.weldRun?.[robotId]);
  const tier = useSelector((state) => state.robot.tier?.[robotId]);

  const last24Hours = {
    start: moment().subtract(1, "days").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  };
  const dateRange = {
    start: moment().subtract(30, "days").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  };

  const { isCustomer } = props;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRobotWeld({ id: robotId }));
    dispatch(getRobotTier(robotId));

    // cannot directly include dateRange object
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch, robotId, dateRange.start, dateRange.end]);

  const data = useRobotAlarms(robotId, dateRange);

  if (!robotId) {
    return <Error404Page />;
  }

  if (!robotWeldRun || !tier) {
    return <LoadingPage size={LOADING_ANIMATION_SIZE_FULL} />;
  }

  return (
    <>
      <StatusBanner
        robotName={robotName}
        weldData={robotWeldRun}
        dateRange={last24Hours}
      />
      <div className="healthpage">
        <RobotInfo
          robotId={robotId}
          isCustomer={isCustomer}
          tier={tier}
          weldRun={robotWeldRun}
        />
        <RobotAlarmStatistics
          data={data}
        />
      </div>
    </>
  );
};

export default RobotSummary;
