import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Input, Table } from "antd";

import LoadingPage from "../../components/LoadingPage";

import { getRobotInfo, getRobotsList } from "../robot/robotSlice";
import { useLoadingWithProgress } from "../../libs/useLoading";

export default function OfflineRobots() {
  const dispatch = useDispatch();
  const [tableFilter, setTableFilter] = useState("");
  const [[robots, robotList, info], progress] = useLoadingWithProgress(
    "robot",
    ({ robots, index, info }) => [robots, index, info],
    [getRobotsList, getRobotInfo]
  );
  useEffect(() => {
    for (const id of robotList) {
      dispatch(getRobotInfo({ id }));
    }
  }, [dispatch, robotList]);

  if (!progress.isFinished()) {
    return <LoadingPage />;
  }

  const robotDate = robotList.map((id, index) => {
    return {
      key: index,
      unit: robots[id]?.name ?? "",
      date: info[id]?.summary?.date ?? "",
    };
  });
  const dataSource = robotDate.filter(
    (e) => e.unit.includes(tableFilter) || e.date.includes(tableFilter)
  );
  const columns = [
    {
      title: "Name",
      dataIndex: "unit",
      key: "name",
      sorter: (a, b) => {
        const aParts = a.unit.split("-");
        const bParts = b.unit.split("-");
        const aNum = aParts.length ? +aParts[aParts.length - 1] : 0;
        const bNum = bParts.length ? +bParts[bParts.length - 1] : 0;
        return aNum - bNum;
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => a.date.localeCompare(b.date),
    },
  ];

  return (
    <div>
      <Input.Search
        placeholder="Search by name or date"
        onSearch={(val) => setTableFilter(val)}
        enterButton={true}
      ></Input.Search>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{ size: "small", pageSize: 6 }}
      />
    </div>
  );
}
