import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col, Card, Statistic } from "antd";
import * as moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

import Error404Page from "../../components/Error404Page";

import { getRobotStats } from "./robotSlice";

momentDurationFormatSetup(moment);

const INITIAL_DATE = "2010-01-01"; // Default date to query day 1 of weld

const lastNDays = {
  key: 1,
  title: "Last 1 month",
  value: 1,
  date: moment().subtract(1, "month"),
};

const RobotStatsCard = (props) => {
  const { robotId } = props;
  const stats = useSelector((state) => state.robot.stats);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getRobotStats(robotId, {
        initial: INITIAL_DATE,
        recent: lastNDays.date.format("YYYY-MM-DD"),
      })
    );
  }, [dispatch, robotId]);

  if (!robotId) {
    return <Error404Page />;
  }

  if (!stats || (stats.length > 0 && stats[robotId])) {
    return <></>;
  }

  const robotStats = stats[robotId];
  if (!robotStats) {
    return <></>;
  }

  const data = {
    totalWeldTimes:
      robotStats.totalWeldTimes.length > 1
        ? robotStats.totalWeldTimes.slice(1).reduce((prev, curr) => {
            return prev.add(curr.value);
          }, moment.duration(robotStats.totalWeldTimes[0].value) || 0)._data
        : 0,

    weldTimes:
      robotStats.weldTimes.length > 1
        ? robotStats.weldTimes.slice(1).reduce((prev, curr) => {
            return prev.add(curr.value);
          }, moment.duration(robotStats.weldTimes[0].value) || 0)._data
        : 0,

    totalWeldInches: robotStats.totalWeldInches.reduce((prev, curr) => {
      return prev + Number(curr.value);
    }, 0),

    weldInches: robotStats.weldInches.reduce((prev, curr) => {
      return prev + Number(curr.value);
    }, 0),
  };

  return (
    <Row
      gutter={[4, 4]}
      style={{
        marginTop: 14,
        paddingTop: 16,
        paddingBottom: 10,
        background: "rgba(211, 226, 235, 0.1)",
      }}
    >
      <Col span={6}>
        <Card className="RobotStatsCard">
          <Statistic
            className="RobotStats_Content"
            title={""}
            value={data.weldInches}
            formatter={(value) => (
              <span style={{ fontSize: 28, fontWeight: 700 }}>
                {value.toFixed(0)}
                <span
                  style={{
                    paddingLeft: 3,
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#79ADD1",
                  }}
                >
                  inches
                </span>
              </span>
            )}
          />
          <Statistic
            className="RobotStats_Title"
            title="Weld FDI (30 days)"
            value={""}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card className="RobotStatsCard">
          <Statistic
            className="RobotStats_Content"
            title={""}
            value={data.totalWeldInches}
            formatter={(value) => (
              <span style={{ fontSize: 28, fontWeight: 700 }}>
                {value.toFixed(0)}
                <span
                  style={{
                    paddingLeft: 3,
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#79ADD1",
                  }}
                >
                  inches
                </span>
              </span>
            )}
          />
          <Statistic
            className="RobotStats_Title"
            title="Overall Weld FDI"
            value={" "}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card className="RobotStatsCard">
          <Statistic
            className="RobotStats_Content"
            title={""}
            value={moment.duration(data.weldTimes).asHours()}
            formatter={(value) => (
              <span style={{ fontSize: 28, fontWeight: 700 }}>
                {value.toFixed(1)}
                <span
                  style={{
                    paddingLeft: 3,
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#79ADD1",
                  }}
                >
                  hrs
                </span>
              </span>
            )}
          />
          <Statistic
            className="RobotStats_Title"
            title="Arc On Time (30 days)"
            value={" "}
          />
        </Card>
      </Col>

      <Col span={6}>
        <Card className="RobotStatsCard">
          <Statistic
            className="RobotStats_Content"
            title={""}
            value={moment.duration(data.totalWeldTimes).asHours()}
            formatter={(value) => (
              <span style={{ fontSize: 28, fontWeight: 700 }}>
                {value.toFixed(1)}
                <span
                  style={{
                    paddingLeft: 3,
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#79ADD1",
                  }}
                >
                  hrs
                </span>
              </span>
            )}
          />
          <Statistic
            className="RobotStats_Title"
            title="Overall Arc On Time"
            value={" "}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default RobotStatsCard;
