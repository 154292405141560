import React from "react";

import RobotNavigation from "../features/robot/RobotNavigation";
import RobotAlarmView from "../features/robot/RobotAlarmView";

/**
 * Alarm List
 * This page is used for the full-screen alarms list
 * Visible from customer support side
 * @param {*} props 
 * @returns React.Component
 */
export default function AlarmListPage(props) {
  return (
    <>
      <RobotNavigation {...props} />
      <RobotAlarmView {...props} />
    </>
  );
}
