import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Button, Modal, Input, Tree, AutoComplete, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { uniqBy, chain, includes, map, filter } from "lodash";
import moment from "moment";

import LoadingPage from "../../components/LoadingPage";

import { VideoPlayer } from "../videos/VideosList";
import PreviewPDF from "../reports/PreviewPDF";

import { getRobotNovEyeLogs, getNovEyeLogContents } from "../videos/g3LogsSlice";
import { getRobotVideos } from "../videos/videoSlice";
import { getRobotNovDataLogs, getNovDataLogContents } from "../reports/ndLogsSlice";
import { getRobotReports } from "../reports/reportSlice";

import { DATETIME_REGEX, DATE_REGEX, LEGACY_WELD_REGEX } from "../../libs/constants";

const { DirectoryTree } = Tree;

/**
 * Load current_files for searching
 * This is to display grouped data
 * TODO: text search for old data
 * Priority if current_files.json exists
 */
function RobotSearchFiles() {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(null);
  const [value, setValue] = useState("");
  const [keyword, setKeyword] = useState("");

  const [selectedItem, setSelectedItem] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const { robotName } = useParams();
  const robotId = robotName.match(/(\d+)/)[0];

  const robots = useSelector((state) => state.robot.robots);

  const videos = useSelector((state) => state.videos.videos);
  const noveye_logs = useSelector((state) => state.noveye_logs.logs);
  const selectedNovEyeLog = useSelector(
    (state) => state.noveye_logs.selectedItem
  );

  const reports = useSelector((state) => state.reports.reports);
  const novdata_logs = useSelector((state) => state.novdata_logs.logs);
  const selectedNovDataLog = useSelector(
    (state) => state.novdata_logs.selectedItem
  );

  useEffect(() => {
    dispatch(getRobotVideos(robotId));
    dispatch(getRobotNovEyeLogs(robotId));
    dispatch(getRobotNovDataLogs(robotId));
    dispatch(getRobotReports(robotId));
  }, [dispatch, robotId]);

  if (!robots && !videos && !noveye_logs && !reports && !novdata_logs) {
    return <LoadingPage />;
  }
  // const robot = robots.find((item) => item.name === robotName);
  const current_files = {};

  const data = {
    videos,
    noveye_logs,
    reports,
    novdata_logs,
  };

  const {
    merged_videos,
    merged_daily_reports,
    merged_novdata_logs,
    merged_noveye_logs,
    dates,
    work_order_ref,
  } = loadData(data, current_files);

  const onSelect = (keys, event) => {
    console.log("Trigger Select", keys, event);
    if (event.node.isLeaf) {
      setSelectedItem(event.node);
      setModalOpen(true);

      if (event.node.fileName.includes(".log")) {
        dispatch(getNovEyeLogContents(event.node.url));
      } else if (event.node.fileName.includes(".tsv")) {
        dispatch(getNovDataLogContents(event.node.url));
      }
    }
  };

  const onExpand = () => {
    console.log("Trigger Expand");
  };

  return (
    <>
      <Input.Group>
        <DatePicker
          onChange={(e) => {
            setSelectedDate(moment(e).format("YYYY-MM-DD"));
          }}
          dateRender={(current) => {
            const style = {};

            if (includes(dates, current.format("YYYY-MM-DD"))) {
              style.border = "1px solid #1890ff";
              style.borderRadius = "50%";
            }

            return (
              <div className="ant-picker-cell-inner" style={style}>
                {current.date()}
              </div>
            );
          }}
        />

        <AutoComplete
          style={{ width: "200px" }}
          options={work_order_ref}
          onChange={(data) => {
            setValue(data);
          }}
          placeholder="Search by Work Order Or Spool Id"
        ></AutoComplete>

        <Button
          type="primary"
          size="middle"
          icon={<SearchOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setKeyword(value);
          }}
        ></Button>
      </Input.Group>

      <DirectoryTree
        onSelect={onSelect}
        onExpand={onExpand}
        treeData={geAllData(
          {
            merged_videos,
            merged_daily_reports,
            merged_novdata_logs,
            merged_noveye_logs,
          },
          selectedDate,
          keyword
        )}
      />

      {modalOpen && !!selectedItem && (
        <Modal
          title={selectedItem.name}
          visible={modalOpen}
          centered
          bodyStyle={{ height: "80%" }}
          width={"70%"}
          onOk={() => setModalOpen(false)}
          onCancel={() => setModalOpen(false)}
        >
          <DisplayFile
            item={selectedItem}
            selectedNovEyeLog={selectedNovEyeLog}
            selectedNovDataLog={selectedNovDataLog}
          />
        </Modal>
      )}
    </>
  );
}

const DisplayFile = ({ item, selectedNovDataLog, selectedNovEyeLog }) => {
  const extension = item && item.fileName && item.fileName.split(".");
  const type = extension[extension.length - 1];
  switch (type) {
    case "mp4":
      return (
        <VideoPlayer
          item={{ ...item, download: item.url }}
          searchText={item.fileName}
          truncatedVideoName={item.fileName}
        ></VideoPlayer>
      );

    case "pdf":
      return <PreviewPDF item={{ ...item, download: item.url }}></PreviewPDF>;

    case "log":
      return <span style={{ whiteSpace: "pre" }}>{selectedNovEyeLog}</span>;

    case "tsv":
      return <span style={{ whiteSpace: "pre" }}>{selectedNovDataLog}</span>;
    default:
      return null;
  }
};

/**
 * 1. Get Current Files,
 * 2. Get other files list
 * 3. Current file will have better data
 * 4.a if current file exists
 * 4. if exists in current file not in cloud - (no url to file)
 * 5. if exists in cloud not in current file - (file deleted or not synced yet)
 * 6. restruct cloud list with regex
 */
const loadData = (data, current_files = {}) => {
  const {
    videos: local_videos,
    tsv: local_novdata_logs,
    reports: local_daily_reports,
    noveye_logs: local_noveye_logs,
  } = current_files;

  const {
    videos: cloud_videos,
    reports: cloud_daily_reports,
    novdata_logs: cloud_novdata_logs,
    noveye_logs: cloud_noveye_logs,
  } = data;

  const merged_videos = mergeObject(cloud_videos, local_videos);

  const merged_daily_reports = mergeObject(
    cloud_daily_reports,
    local_daily_reports
  );
  const merged_novdata_logs = mergeObject(
    cloud_novdata_logs,
    local_novdata_logs
  );

  const merged_noveye_logs = mergeObject(cloud_noveye_logs, local_noveye_logs);

  const dates = filter([
    ...getDates(merged_videos),
    ...getDates(merged_daily_reports),
    ...getDates(merged_novdata_logs),
    ...getDates(merged_noveye_logs),
  ]).sort((a, b) => a - b);

  const work_order_ref = uniqBy(
    [
      ...getWorkOrderAndSpoolId(merged_videos),
      ...getWorkOrderAndSpoolId(merged_daily_reports),
      ...getWorkOrderAndSpoolId(merged_novdata_logs),
      ...getWorkOrderAndSpoolId(merged_noveye_logs),
    ],
    "value"
  );

  return {
    merged_videos,
    merged_daily_reports,
    merged_novdata_logs,
    merged_noveye_logs,
    dates,
    work_order_ref: work_order_ref,
    // spool_ref: spool_ref,
  };
};

const geAllData = (data, selectedDate, keyword) => {
  const {
    merged_videos,
    merged_daily_reports,
    merged_novdata_logs,
    merged_noveye_logs,
  } = data;

  const searched_videos =
    searchObject(merged_videos, selectedDate, keyword) || [];
  const searched_daily_reports =
    searchObject(merged_daily_reports, selectedDate, keyword) || [];
  const searched_novdata_logs =
    searchObject(merged_novdata_logs, selectedDate, keyword) || [];
  const searched_noveye_logs =
    searchObject(merged_noveye_logs, selectedDate, keyword) || [];

  return [
    {
      title: `Video (${searched_videos.length})`,
      key: "videos",
      children: searched_videos,
    },
    {
      title: `Daily Report (${searched_daily_reports.length})`,
      key: "reports",
      children: searched_daily_reports,
    },
    {
      title: `NovData Log (${searched_novdata_logs.length})`,
      key: "tsv",
      children: searched_novdata_logs,
    },
    {
      title: `NovEye Log (${searched_noveye_logs.length})`,
      key: "noveye_logs",
      children: searched_noveye_logs,
    },
  ];
};

const mergeObject = (cloudData, localData) => {
  const mergedData = map(cloudData, (item) => {
    item = { ...getInfoFromFileName(item) };

    map(localData, (obj) => {
      if (item.fileName === obj.file_name) {
        item = { ...item, ...obj };
      }
    });

    return { ...item, isLeaf: true };
  });

  return mergedData;
};

const searchObject = (list, date, keyword) => {
  return filter(list, (item) => {
    const isDateMatched =
      date ===
      moment(
        item.modified_time ||
          item.created_time ||
          item.extracted_time ||
          item.extracted_date ||
          item.extractedDate ||
          item.extractedTime ||
          item.uploadedAt
      ).format("YYYY-MM-DD");

    const isKeywordMatched =
      (item.fileName && item.fileName.includes(keyword)) ||
      (item.file_name && item.file_name.includes(keyword));

    if (!!date && keyword !== "") {
      return isDateMatched && isKeywordMatched;
    } else if (date) {
      return isDateMatched;
    } else if (keyword) {
      return isKeywordMatched;
    } else {
      return false;
    }
  });
};

const getInfoFromFileName = (item) => {
  const matchDateTime = [...item.fileName.matchAll(DATETIME_REGEX)][0];
  const matchDate = [...item.fileName.matchAll(DATE_REGEX)][0];
  const matchWeldInfo = [...item.fileName.matchAll(LEGACY_WELD_REGEX)][0];

  return {
    ...item,
    key: item.fileName,
    title: item.fileName,
    extractedTime: matchDateTime
      ? new Date(
          matchDateTime[1],
          parseInt(matchDateTime[2], 10) - 1,
          matchDateTime[3],
          matchDateTime[4],
          matchDateTime[5],
          matchDateTime[6]
        ).toISOString()
      : null,
    extractedDate: matchDate
      ? new Date(
          matchDate[1],
          parseInt(matchDate[2], 10) - 1,
          matchDate[3]
        ).toISOString()
      : null,
    weldInfo: matchWeldInfo
      ? {
          work_order_ref: matchWeldInfo[1],
          spool_ref: matchWeldInfo[2],
          welder_id_ref: matchWeldInfo[3],
          run_number: matchWeldInfo[4],
        }
      : null,
  };
};

const getDates = (list) => {
  return chain(list)
    .map((item) => {
      return moment(
        item.modified_time ||
          item.created_time ||
          item.extracted_time ||
          item.extracted_date ||
          item.extractedDate ||
          item.extractedTime ||
          item.uploadedAt
      ).format("YYYY-MM-DD");
    })
    .uniqBy()
    .value();
};

const getWorkOrderAndSpoolId = (list) => {
  return chain(list)
    .map((item) => {
      const value =
        (item &&
          item.weldInfo &&
          (item.weldInfo.work_order_ref.toLowerCase() ||
            item.weldInfo.spool_ref.toLowerCase())) ||
        (item.weld_info &&
          (item.weld_info.work_order_ref.toLowerCase() ||
            item.weld_info.spool_ref.toLowerCase()));

      return { value: value };
    })
    .uniqBy("value")
    .filter((item) => !!item.value)
    .value();
};

export default RobotSearchFiles;
