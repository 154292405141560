import React from "react";

import { Spin } from "antd";

import { ReactComponent as Spool } from "../assets/icons/Spool.svg";
import { ReactComponent as Torch } from "../assets/icons/Torch.svg";
import { LoadingOutlined } from "@ant-design/icons";
// from the SVG: draw a bounding circle to measure the outside margin
const svgImplicitMargin = 13 / 100;

export default function LoadingPage(props) {
  const containerSize = props.size;
  const loadingProgress = props.progress;
  const shouldShowLoadingText = loadingProgress?.total > 1;
  const progressPercent = shouldShowLoadingText
    ? `Loading... ${Math.trunc((100 * loadingProgress.ready) / loadingProgress.total)}%`
    : "";

  // only render custom loader if it is a full page otherwise use small circle
  if (!containerSize || containerSize < 100) {
    return (
      <div className="loading-container">
        <LoadingOutlined style={{ fontSize: 60, color: "#d9d9d9" }} spin />
      </div>
    );
  }

  const diagonalLength = containerSize / (1 - svgImplicitMargin);
  // pythag
  // a^2 = c^2 / 2
  // a = c / sqrt 2
  const size = diagonalLength / Math.SQRT2;

  const containerStyle = containerSize === 100 ? {} : { height: "80vh" };

  return (
    <div className="loading-container" style={containerStyle}>
      <div
        style={{
          width: containerSize,
          height: containerSize,
        }}
      >
        <div
          className="loading-static"
          style={{
            width: size,
            height: size,
          }}
        >
          <Torch width={size} height={size} />
        </div>
        <div
          className="loading-animated"
          style={{
            width: size,
            height: size,
          }}
        >
          <Spool width={size} height={size} />
        </div>
        {shouldShowLoadingText && (
          <div className="loading-static">
            <h1>{progressPercent}</h1>
          </div>
        )}
      </div>
    </div>
  );
}
