import React from "react";

import { DatePicker } from "antd";

import moment from "moment";

const { RangePicker } = DatePicker;

export default function PickerWithType({ type, onChange, defaultValue, value, lastSelectableDay }) {
  lastSelectableDay = lastSelectableDay ?? moment().format("YYYY-MM-DD");
  function disabledDate(current) {
    // Can not select days after today and today
    return current?.format("YYYY-MM-DD") > lastSelectableDay;
  }
  if (type === "range")
    return (
      <RangePicker
        showToday
        disabledDate={disabledDate}
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
      />
    );

  if (type === "date")
    return (
      <DatePicker showToday disabledDate={disabledDate} onChange={onChange} />
    );

  return (
    <DatePicker disabledDate={disabledDate} picker={type} onChange={onChange} />
  );
}
