import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import L from "leaflet";
import { MapToolTip } from "./MapToolTip";
import { GeolocationData } from "../robot/robotSlice";
import { normalizeRobotName } from "../../libs/utils";

interface Props {
  robotLocations: GeolocationData[];
}
export function WorldMap(props: Props) {
  const { robotLocations } = props;

  return (
    <>
      <MapContainer
        className="marker-cluster-map"
        // @ts-ignore
        maxZoom={8}
        scrollWheelZoom={false}
        zoom={2.4}
        center={[33.3861128, -3.1831302]}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

        <MarkerClusterGroup
          iconCreateFunction={(cluster) => {
            const childCount = cluster.getChildCount();
            let c = " marker-cluster-small";
            // if (childCount < 2) {
            //   c += "small";
            // } else if (childCount < 3) {
            //   c += "medium";
            // } else {
            //   c += "large";
            // }
            return L.divIcon({
              html: "<div><span>" + childCount + "</span></div>",
              className: "marker-cluster" + c,
              iconSize: L.point(40, 40, true),
            });
          }}
          maxClusterRadius={25}
          spiderfyOnMaxZoom={true}
        >
          {robotLocations.map(
            ({ swr_id, name, coordinates, address, country }) =>
              coordinates && (
                <MapToolTip
                  key={swr_id}
                  robotName={normalizeRobotName(swr_id, true)}
                  coordinates={coordinates}
                  customer={name}
                  location={address ?? country ?? "no address provided"}
                />
              )
          )}
        </MarkerClusterGroup>
      </MapContainer>
    </>
  );
}
