import React from "react";

import RobotNavigation from "../features/robot/RobotNavigation";
import RobotSummary from "../features/robot/RobotSummary";

export default function HealthPage(props) {
  return (
    <>
      <RobotNavigation {...props} />
      <RobotSummary {...props} />
    </>
  );
}
