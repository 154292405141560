import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Alert, Button, Form, Input, Typography } from "antd";
import { LockOutlined } from "@ant-design/icons";

import logo from "../../assets/novarc-logo.black.svg";

import { resetUserPassword, updateUserPassword } from "./authSlice";

import { useQuery } from "../../libs/utils";

const { Title } = Typography;

const PasswordReset = (props) => {
  const { isAuthenticated } = props;

  const resetting = useSelector((state) => state.auth.resetting);
  const resettingError = useSelector((state) => state.auth.resettingError);

  const dispatch = useDispatch();

  const query = useQuery();

  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = ({ password, new_password }) => {
    if (submitted) {
      setSubmitted(false);
    }
    dispatch(
      isAuthenticated
        ? updateUserPassword(password, new_password)
        : resetUserPassword(query.get("token"), new_password)
    );
    setSubmitted(true);
  };

  const onClose = (e) => {
    console.log(e, "Closing");
    setSubmitted(false);
  };

  return (
    <div
      style={{
        textAlign: "center",
        verticalAlign: "middle",
        maxWidth: 400,
        minWidth: 300,
        top: "50%",
        left: "50%",
        position: "absolute",
        MsTransform: "translate(-50%, -50%)",
        transform: "translate(-50%, -50%)",
      }}
    >
      {!isAuthenticated && (
        <React.Fragment>
          <img
            className="logo"
            alt="logo"
            src={logo}
            style={{ height: 80, margin: "12px" }}
          />

          <p>Cloud Web Dashboard for SWR</p>
        </React.Fragment>
      )}
      <Form
        name="password_reset"
        className="password_reset-form"
        initialValues={{ remember: true }}
        onFinish={handleSubmit}
        style={{ marginTop: 40 }}
      >
        <Title level={4}>
          {isAuthenticated ? "Change your password" : "Reset your password"}
        </Title>
        <p>Enter new password below.</p>

        {isAuthenticated && (
          <Form.Item
            name="password"
            rules={[
              { required: true, message: "Please enter your password!" },
              {
                type: "string",
                min: 6,
                max: 30,
                message: "Password should be between 6 to 30 characters",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
        )}

        <Form.Item
          name="new_password"
          rules={[
            { required: true, message: "Please input your new password!" },
            {
              type: "string",
              min: 6,
              max: 30,
              message: "Password should be between 6 to 30 characters",
            },
          ]}
        >
          <Input
            prefix={<LockOutlined />}
            type="password"
            placeholder="New Password"
          />
        </Form.Item>

        <Form.Item
          name="new_password_confirmation"
          rules={[
            { required: true, message: "Please confirm your new password!" },
            {
              type: "string",
              min: 6,
              max: 30,
              message: "Password should be between 6 to 30 characters",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "The two passwords that you entered do not match!"
                );
              },
            }),
          ]}
        >
          <Input
            prefix={<LockOutlined />}
            type="password"
            placeholder="New Password Confirmation"
          />
        </Form.Item>

        {!!resettingError && (
          <React.Fragment>
            <Alert
              message={
                resettingError === "Error: Request failed with status code 401"
                  ? "The current password you have provided is incorrect"
                  : `Sorry, Something went wrong!
                  Please try again later`
              }
              type="error"
              // closable
              // onClose={onClose}
            />
            <br />
          </React.Fragment>
        )}

        {!resettingError && !resetting && submitted && (
          <React.Fragment>
            <Alert
              message={"Successfully updated password"}
              type="success"
              closable
              onClose={onClose}
            />
            <br />
          </React.Fragment>
        )}

        <Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
            loading={resetting}
            disabled={!resettingError && submitted}
          >
            Update Password
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PasswordReset;
