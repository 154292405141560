import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {Button, Modal, Spin} from "antd";
import {
  EditOutlined,
  UploadOutlined,
  PlaySquareOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";

import Moment from "moment";
import "moment-timezone";
import { extendMoment } from "moment-range";

import { chain, orderBy } from "lodash";

import { getPipeSchedule, getTimeZone } from "../../libs/utils";

import {
  getRobotWeld,
  getRobotWeldTestReportsStatus,
} from "./robotSlice";

import RobotWeldTestReportForm from "./RobotWeldTestReportForm";
import LoadingPage from "../../components/LoadingPage";
import Calendar from "./Calendar";
import { LOADING_ANIMATION_SIZE_FULL } from "../../libs/constants";
import { useLoadingWithProgress } from "../../libs/useLoading";

const moment = extendMoment(Moment);

const columns = [
  { title: "" },
  {
    title: "Date",
    render: (_, item) => {
      if (item.children) {
        return <span>{moment(item.arc_off_utc).format("YYYY-MM-DD")}</span>;
      } else {
        return <span>{moment(item.arc_on_utc).format("YYYY-MM-DD")}</span>;
      }
    },
  },
  {
    title: "Order",
    dataIndex: "work_order_ref",
    // filters: weldRunFilters("work_order_ref"),
    // onFilter: (value, record) => record.work_order_ref.indexOf(value) === 0,
  },
  {
    title: "Spool",
    dataIndex: "spool_ref",
    // filters: weldRunFilters("spool_ref"),
    // onFilter: (value, record) => record.spool_ref.indexOf(value) === 0,
  },
  {
    title: "Weld ID",
    dataIndex: "welder_id_ref",
    // filters: weldRunFilters("welder_id_ref"),
    onFilter: (value, record) =>
      record.welder_id_ref && record.welder_id_ref.indexOf(value) === 0,
  },
  {
    title: "Run",
    dataIndex: "run_number", // should have count
  },
  {
    title: "Start Time",
    render: (_, item) => {
      return <span>{moment(item.arc_on_utc).format("HH:mm:ss")}</span>;
    },
  },
  {
    title: "Arc Off Time",
    render: (_, item) => {
      if (item.duration) {
        return (
          <span>
            {Number(item.duration.hours()) * 60 +
              Number(item.duration.minutes()) +
              "m "}
            {item.duration.seconds() + "s"}
          </span>
        );
      } else {
        return <span>{null}</span>;
      }
    },
  },
  {
    title: "Weld Time",
    render: (_, item) => {
      return (
        <span>
          {Number(moment.duration({ ...item.weld_time }).hours()) * 60 +
            Number(moment.duration({ ...item.weld_time }).minutes()) +
            "m "}
          {moment.duration({ ...item.weld_time }).seconds() + "s"}
        </span>
      );
    },
  },
  {
    title: "Pipe Size",
    // filters: weldRun
    //   .filter((item) =>
    //     moment(item.arc_on_utc).isSameOrAfter(
    //       filterData
    //         .find(({ key }) => key === dropdownKey)
    //         .date.format("YYYY-MM-DD")
    //     )
    //   )
    //   .map((item) => {
    //     const unit =
    //       !item.units_system || item.units_system === "imperial" ? "in" : "cm";
    //     return `${item.pipe_size_inches}${unit}`;
    //   })
    //   .filter((value, index, self) => self.indexOf(value) === index)
    //   .map((item) => {
    //     return { text: item, value: item };
    //   }),
    // onFilter: (value, record) => {
    //   const unit =
    //     !record.units_system || record.units_system === "imperial"
    //       ? "in"
    //       : "cm";
    //   return `${record.pipe_size_inches}${unit}` === value;
    // },
    render: (_, item) => {
      const unit =
        !item.units_system || item.units_system === "imperial" ? "in" : "cm";
      return <span>{`${item.pipe_size_inches}${unit}`}</span>;
    },
  },
  {
    title: "Pipe Sched",
    // filters: weldRun
    //   .filter((item) =>
    //     moment(item.arc_on_utc).isSameOrAfter(
    //       filterData
    //         .find(({ key }) => key === dropdownKey)
    //         .date.format("YYYY-MM-DD")
    //     )
    //   )
    //   .map((item) => {
    //     return getPipeSchedule(item.pipe_schedule);
    //   })
    //   .filter((value, index, self) => self.indexOf(value) === index)
    //   .map((item) => {
    //     return { text: item, value: item };
    //   }),
    // onFilter: (value, record) => {
    //   return record.pipe_schedule === value;
    // },
    render: (_, item) => {
      return <span>{getPipeSchedule(item.pipe_schedule)}</span>;
    },
  },
  {
    title: "FDI",
    render: (_, item) => {
      if (!item.run_number) {
        return <span>{item.weld_inches}</span>;
      } else {
        return <span></span>;
      }
    },
  },
  {
    title: "Operator",
    dataIndex: "operator_name",
    // filters: weldRunFilters("operator_name"),
    onFilter: (value, record) => {
      if (record.children && record.children.length > 0) {
        return record.children
          .map((child) => child.operator_name.indexOf(value) === 0)
          .filter((c) => c);
      } else {
        return (
          record.operator_name && record.operator_name.indexOf(value) === 0
        );
      }
    },
  },
  {
    title: "X-Ray",
    dataIndex: "x_ray",
  },
  {
    title: "Action",
    render: () => (
      <>
        <Button
          icon={<EditOutlined />}
        //   disabled={editingKey !== ""}
        //   onClick={() => edit(record)}
        />
        <Button
          icon={<UploadOutlined />}
        //   disabled={editingKey !== ""}
        //   onClick={() => edit(record)}
        />
        <Button
          icon={<PlaySquareOutlined />}
        //   disabled={editingKey !== ""}
        //   onClick={() => edit(record)}
        />
        <Button
          icon={<FileExcelOutlined />}
        //   disabled={editingKey !== ""}
        //   onClick={() => edit(record)}
        />
      </>
    ),
  },
];

function RobotWeldTestReportsCalendar(props) {
  const dispatch = useDispatch();

  const { robotName } = useParams();
  const robotId =
    robotName && robotName.match(/(\d+)/) && robotName.match(/(\d+)/)[0];

  const [weldRun, loadingProgress] = useLoadingWithProgress(
    "robot",
    ({ weldRun }) => weldRun?.[robotId],
    getRobotWeld
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const reportStatus = useSelector(
    //   note the dispatch for getRobotWeldTestReportsStatus is currently under calendar datechange callback
    (state) => state.robot.weldTestReportsStatus
  );
  const [reportStatusCounts, setReportStatusCounts] = useState({
    "pass": 0,
    "fail": 0,
    "not tested": 0,
    "no report": 0
  });
  const [reportStatusGrouped, setReportStatusGrouped] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [groupedWeldRun, setGroupedWeldRun] = useState([]);
  const [activeDateRange, setActiveDateRange] = useState([null, null]);
  const [dateStart, dateEnd] = activeDateRange;
  const [filteredGroups, setFilteredGroups] = useState([]);

  useEffect(() => {
    dispatch(getRobotWeld({ id: robotId }));
  }, [dispatch, robotId]);

  // group qc status by weld id for lookup
  useEffect(() => {
    const existingReports = (reportStatus ?? []).filter(
      (e) => e.reports instanceof Array
    );
    const groups = {};
    for (const entry of existingReports) {
      const validReports = entry.reports.filter((e) => !e.deleted_at);
      if (validReports.length) {
        groups[entry.id] = validReports.map((e) => e.test_status);
      }
    }
    setReportStatusGrouped(groups);
  }, [reportStatus]);

  // create groupedWeldRun from welds (for all time, not just current view)
  useEffect(() => {
    setGroupedWeldRun(
      chain(weldRun ?? [])
        .map((item) => {
          const idx = weldRun.findIndex((i) => i === item);
          if (
            idx < weldRun.length - 1 &&
            moment(weldRun[idx + 1].arc_on_utc, "YYYY-MM-DD").diff(
              moment(item.arc_on_utc, "YYYY-MM-DD"),
              "days"
            ) === 0
          ) {
            const duration = moment.duration(
              moment(item.arc_on_utc).diff(moment(weldRun[idx + 1].arc_off_utc))
            );

            return { ...item, duration };
          } else {
            return { ...item };
          }
        })
        .groupBy((item) => {
          return item.work_order_ref + " " + item.spool_ref;
        })
        .entries()
        .map(([work_order_spool, weld]) => {
          const children = orderBy(
            [...weld],
            [
              (i) => {
                return i.run_number;
              },
            ],
            ["desc"]
          );

          const childrenSize = children.length;
          const first = children[0];
          const last = children[childrenSize - 1];

          return {
            key: work_order_spool,
            children,
            title: work_order_spool,

            time_zone: last.time_zone,
            start: moment
              .tz(last.arc_on_at, getTimeZone(last.time_zone))
              .utc(true)
              .format(),
            end: moment
              .tz(first.arc_off_at, getTimeZone(first.time_zone))
              .utc(true)
              .format(),
            work_order_ref: last.work_order_ref,
            spool_ref: last.spool_ref,
            welder_id_ref: last.welder_id_ref,

            units_system: last.units_system,
            pipe_size_inches: last.pipe_size_inches,
            pipe_schedule: last.pipe_schedule,

            // sum of arc off time (setup time) on grouped weld
            duration: children.slice(1).reduce((prev, curr) => {
              return prev.add(curr.duration);
            }, moment.duration(first.duration)),

            // sum of weld time on grouped weld
            weld_time: children.slice(1).reduce((prev, curr) => {
              return prev.add(curr.weld_time);
            }, moment.duration(first.weld_time))._data,

            weld_inches: last.run_number === 0 ? last.weld_inches : null,
          };
        })
        .value()
    );
  }, [weldRun]);

  // filter and count events by qc status (current date range)
  useEffect(() => {
    if (!reportStatusGrouped || !dateStart) return;

    const isDateInCalendar = (date) => {
      const d = new Date(date);
      return d > dateStart && d < dateEnd;
    };
    const counter = {
      "pass": 0,
      "fail": 0,
      "not tested": 0,
      "no report": 0
    };
    const fg = groupedWeldRun.filter(entry => {
      if (!isDateInCalendar(entry.start) && !isDateInCalendar(entry.end)) {
        return false;
      }
      const runs = entry?.children;
      if (!runs) {
        return false;
      }

      const ids = runs.map(e => e.id);

      let status = getQcStatus(ids);
      counter[status]++;

      // if no filter set, return all
      if (statusFilter === null) return true;

      // otherwise only return the type matching filter
      return status === statusFilter;
    });
    setReportStatusCounts(counter);
    setFilteredGroups(fg);
  }, [activeDateRange, groupedWeldRun, statusFilter, reportStatusGrouped]);

  function getQcStatus(ids) {
    let status = "no report";
    for (const id of ids) {
      const reportStatuses = reportStatusGrouped[id];
      if (reportStatuses) {
        // If any FAIL report submitted (regardless of the rest) → 1 FAIL
        if (reportStatuses.includes("fail")) {
          status = "fail";
        }
        // if no FAIL and any number of PASS report is submitted → 1 PASS
        else if (status !== "fail" && reportStatuses.includes("pass")) {
          status = "pass";
        }
        // If there is a not tested reported submitted (no FAIL or PASS report found) → 1 NOT TESTED
        else if (status !== "fail" && status !== "pass") {
          status = "not tested";
        }
      }
      // if no report submitted → 1 No Reports/Not submitted
    }
    return status;
  }

  if (!loadingProgress.isFinished()) {
    return <LoadingPage size={LOADING_ANIMATION_SIZE_FULL} progress={loadingProgress}/>;
  }

  /** Allow QC report for lite tier customer temporally
  if (props.isCustomer && tier[robotId].tier === "lite") {
    return (
      <Result
        title="This feature is available in Essential Plan"
        subTitle="Please contact customer support for inquiry."
        extra={
          <Button
            onClick={() => {
              history.goBack();
            }}
            type="primary"
          >
            Back
          </Button>
        }
      />
    );
  }
 */

  return (
    <Spin
        spinning={!loadingProgress.isFinished()}
        indicator={<LoadingPage size={LOADING_ANIMATION_SIZE_FULL} progress={loadingProgress}/>}
    >
      <Calendar
        className="weld-qc-report"
        onDateChange={(calendarStart, calendarEnd) => {
          setActiveDateRange([new Date(calendarStart), new Date(calendarEnd)]);
          dispatch(
            getRobotWeldTestReportsStatus({
              id: robotId,
              start: calendarStart,
              end: calendarEnd,
            })
          );
        }}
        overlay={{
          title: "Submitted:",
          alertRed: { value: reportStatusCounts.fail },
          alertGreen: { value: reportStatusCounts.pass },
          alertYellow: {
            label: "Not Tested",
            value: reportStatusCounts["not tested"],
          },
          alertOther: {
            label: "No Report: ",
            value: reportStatusCounts["no report"],
          },
          onChange: (button) => {
            if (button === "green") {
              setStatusFilter("pass");
            } else if (button === "red") {
              setStatusFilter("fail");
            } else if (button === "yellow") {
              setStatusFilter("not tested");
            } else if (button === "other") {
              setStatusFilter("no report");
            } else {
              setStatusFilter(null);
            }
          },
        }}
        events={filteredGroups}
        eventClick={(info) => {
          setSelectedJob({
            start: info.event.start,
            end: info.event.end,
            ...info.event.extendedProps,
          });

          setModalOpen(true);
        }}
        eventClassNames={({ event }) => {
          try {
            // each event on calendar can have multiple welds
            const ids = event.extendedProps.children.map((e) => e.id);
            const status = getQcStatus(ids);
            switch (status) {
              case "pass":
                return ["event-indicator-pass"];
              case "fail":
                return ["event-indicator-fail"];
              case "not tested":
                return ["event-indicator-not-tested"];
              default:
                return ["event-indicator-no-data"];
            }
          } catch (e) {
            // console.warn("error in robotweldtestreports/eventclassnames", e)
            return [];
          }
        }}
      />
      {!!selectedJob && (
        <Modal
          className="WeldTestReport_Modal"
          title={`Weld Test Report of ${selectedJob.work_order_ref} ${selectedJob.spool_ref} ${selectedJob.welder_id_ref}`}
          visible={modalOpen}
          // onOk={handleOk}
          onCancel={() => setModalOpen(false)}
          footer={null}
          width={800}
          style={{ width: "80vw" }}
          destroyOnClose={true}
        >
          <RobotWeldTestReportForm job={selectedJob}></RobotWeldTestReportForm>
        </Modal>
      )}
    </Spin>
  );
}

export default RobotWeldTestReportsCalendar;
