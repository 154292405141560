import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Alert,
  Button,
  Card,
  Col,
  Dropdown,
  List,
  Menu,
  Row,
  Statistic,
  Table,
  Tabs,
  Tooltip,
} from "antd";
import { CaretDownOutlined } from "@ant-design/icons";

import { Area, Bar, Column } from "@ant-design/charts";

import moment from "moment";

import { chain, filter } from "lodash";

import TrendStatistics from "../dashboard/TrendStatistics";
import { getDateList } from "../../libs/utils";
import { getOverallPLCAlarms } from "./alarmSlice";

import { getRobotInfo, getRobotsList } from "../robot/robotSlice";
import { useLoadingWithProgress } from "../../libs/useLoading";
import { useRobotAlarms } from "../../libs/useAlarms";

const { TabPane } = Tabs;

const filterData = [
  {
    key: 1,
    title: "Yesterday",
    value: 1,
    date: moment().subtract(1, "days"),
  },
  {
    key: 2,
    title: "Last 7 days",
    value: 7,
    date: moment().subtract(7, "days"),
  },
  {
    key: 3,
    title: "Last 30 days",
    value: 30,
    date: moment().subtract(30, "days"),
  },
];

export default function AlarmStatistics() {
  const [[robots, robotList, info], progress] = useLoadingWithProgress(
    "robot",
    ({ robots, index, info }) => [robots, index, info],
    [getRobotsList, getRobotInfo]
  );
  const dispatch = useDispatch();
  const [dropdownKey, setDropdownKey] = useState(2);
  const [activeKey, setActiveKey] = useState("key2");

  const today = moment().format("YYYY-MM-DD");
  const lastWeek = moment(today).subtract(7, "days").calendar();
  const last30Days = moment(today).subtract(30, "days").format("YYYY-MM-DD");

  const alarmData = useRobotAlarms(robotList, {
    start: last30Days,
    end: today,
  });

  useEffect(() => {
    if (!robotList.length) return;
    for (const robotId of robotList) {
      dispatch(getRobotInfo({ id: robotId }));
    }

    // not sure the reason for this filter as opposed to getting the data and filtering after
    const robotIdsRecent = robotList.filter((id) => {
      const robotInfo = info[id];
      return (
        robotInfo?.summary?.date &&
        moment(robotInfo.summary.date).isSameOrAfter(last30Days)
      );
    });

    dispatch(
      getOverallPLCAlarms({
        ids: robotIdsRecent,
        start: last30Days,
        end: today,
      })
    );
  }, [dispatch, robotList, today]);

  // config for Trends Graph
  const config = {
    xField: "date",
    yField: "value",
    smooth: true,
    height: 60,
    width: 300,
  };

  const robotDate = robotList.map((id) => {
    return {
      unit: robots[id]?.name,
      date: info[id]?.summary?.date ?? null,
    };
  });

  const inActiveRobots = filter(robotDate, (item) => {
    return moment(item.date).isSameOrBefore(
      filterData
        .find(({ key }) => key === dropdownKey)
        .date.format("YYYY-MM-DD")
    );
  });

  const inActiveRobotsData = chain([...inActiveRobots])
    .uniqBy("date")
    .orderBy(({ date }) => date || "", "asc")
    .map((item) => {
      const filteredRobots = filter([...inActiveRobots], (i) => {
        return i.date === item.date;
      });
      return {
        date: item.date,
        robots: filteredRobots,
        value: filteredRobots.length,
      };
    })
    .value();

  const activeRobots = filter(robotDate, (item) => {
    return new Date(item.date) >= new Date(lastWeek);
  });

  const activeRobotsData = getDateList(lastWeek, today).map((item) => {
    const filteredRobots = filter(activeRobots, (i) => {
      return i.date === item.date;
    });
    item.robots = filteredRobots;
    item.value = filteredRobots.length;
    return item;
  });

  const range = moment.rangeFromInterval("day", -30, moment());

  const filterDataBy = (type, from) =>
    Array.from(range.by("day"))
      .map((day) => {
        if (day.isAfter(from)) {
          return {
            date: day.format("YYYY-MM-DD"),
            value: alarmData.filter(
              (item) =>
                day.format("YYYY-MM-DD") ===
                  moment(item.received_at).format("YYYY-MM-DD") &&
                item.type === type
            ).length,
            type: type,
          };
        }
        return null;
      })
      .filter((item) => item);

  const statsData = {
    warning: alarmData.filter(
      (item) =>
        moment(item.received_at).isSameOrAfter(
          filterData
            .find(({ key }) => key === dropdownKey)
            .date.format("YYYY-MM-DD")
        ) && item.type === "warning"
    ),
    error: alarmData.filter(
      (item) =>
        moment(item.received_at).isSameOrAfter(
          filterData
            .find(({ key }) => key === dropdownKey)
            .date.format("YYYY-MM-DD")
        ) && item.type === "error"
    ),
  };

  const trendsData = [
    ...filterDataBy(
      "warning",
      filterData
        .find(({ key }) => key === dropdownKey)
        .date.format("YYYY-MM-DD")
    ),
    ...filterDataBy(
      "error",
      filterData
        .find(({ key }) => key === dropdownKey)
        .date.format("YYYY-MM-DD")
    ),
  ];

  const columns = [
    {
      title: "Unit",
      dataIndex: "unit",
      sorter: {
        compare: (a, b) => {
          if (a.unit > b.unit) {
            return 1;
          }
          if (a.unit < b.unit) {
            return -1;
          }
          return 0;
        },
      },

      filters: alarmData
        .map((item) => {
          return item.unit;
        })
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((item) => {
          return { text: item, value: item };
        }),
      onFilter: (value, record) => {
        return record.unit === value;
      },

      render: (text, item, index) => {
        return {
          children: (
            <Tooltip placement="topLeft" title={item.detail}>
              <Alert
                className="AlarmStatistics_Alert"
                style={{ margin: "-5px auto" }}
                type={item.type === "warning" ? "warning" : "error"}
                showIcon
                message={
                  <span>
                    <b>{item.unit}</b> <span>{item.message}</span>{" "}
                    <i>{item.received_at}</i>
                  </span>
                }
              ></Alert>
            </Tooltip>
          ),
          props: { colSpan: 3 },
        };
      },
    },

    {
      title: "Message",
      sorter: {
        compare: (a, b) => {
          const aString = a.message.toLowerCase();
          const bString = b.message.toLowerCase();

          if (aString > bString) {
            return 1;
          }
          if (aString < bString) {
            return -1;
          }
          return 0;
        },
      },
      // dataIndex: "message",
    },
    {
      title: "Time",
      sorter: {
        compare: (a, b) => {
          const aDate = new Date(a.received_at);
          const bDate = new Date(b.received_at);

          if (aDate > bDate) {
            return 1;
          }
          if (aDate < bDate) {
            return -1;
          }
          return 0;
        },
        multiple: 1,
      },
      // dataIndex: "received_at",
    },
  ];

  const pagination = { hideOnSinglePage: true };

  return (
    <>
      <Tabs
        activeKey={activeKey}
        size={"small"}
        type="card"
        onTabClick={(key) => setActiveKey(key)}
        tabBarExtraContent={
          <Dropdown
            overlay={
              <Menu>
                {filterData.map((item) => (
                  <Menu.Item
                    key={item.key}
                    onClick={() => {
                      setDropdownKey(item.key);
                    }}
                  >
                    {item.title}
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <Button size={"small"}>
              {filterData.find(({ key }) => key === dropdownKey).title}
              <CaretDownOutlined />
            </Button>
          </Dropdown>
        }
      >
        <TabPane tab={"Statistics"} key="key1" style={{ height: 500 }}>
          <Row gutter={16}>
            <Col span={24}>
              <Card title="" bordered={false}>
                <TrendStatistics
                  title={"Total Active SWRs in last 7 days"}
                  valueKey={"value"}
                  color={"green"}
                  {...{
                    ...config,
                    data: activeRobotsData,
                    height: 30,
                    width: 120,
                    tooltip: true,
                    showMarkers: true,
                  }}
                />
              </Card>
            </Col>
            {/* <Col span={12}> */}
            {/* <Card title="" bordered={false}> */}
            {/* <TrendStatistics
                  title={"Total Inactive SWRs in last 7 days"}
                  valueKey={"value"}
                  color={"red"}
                  {...{
                    ...config,
                    data: inActiveRobotsData,
                    height: 30,
                    width: 120,
                    tooltip: true,
                    showMarkers: true,
                  }}
                /> */}
            {/* </Card> */}
            {/* </Col> */}

            <Col span={8}>
              <Card title="" bordered={false}>
                <Statistic
                  title={"Error"}
                  value={statsData.error.length}
                  valueStyle={{ color: "red" }}
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card title="" bordered={false}>
                <Statistic
                  title={"Warning"}
                  value={statsData.warning.length}
                  valueStyle={{ color: "orange" }}
                />
              </Card>
            </Col>

            <Col span={24}>
              <Card title="" bordered={false} type="inner" size="small">
                <Area
                  {...{
                    ...config,
                    seriesField: "type",
                    data: trendsData,
                    height: 100,
                    width: 120,
                    tooltip: true,
                    showMarkers: true,
                    color: ["#fa8c16", "#f5222d", "#fa541c"],
                    line: {
                      size: 1,
                    },
                    xAxis: {
                      label: null,
                    },
                    yAxis: {
                      label: null,
                      grid: {
                        line: {
                          style: null,
                        },
                      },
                    },
                  }}
                />
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane
          tab={"Alarms List"}
          key="key2"
          style={{ height: 500, overflowY: "scroll" }}
        >
          <Table
            className="AlarmsList_Table"
            size={"small"}
            columns={columns}
            dataSource={alarmData.filter(
              (item) =>
                (item.cleared_at !== "" || item.acknowledged_at !== "") &&
                moment(item.received_at).isSameOrAfter(
                  filterData
                    .find(({ key }) => key === dropdownKey)
                    .date.format("YYYY-MM-DD")
                )
            )}
            pagination={pagination}
          />
        </TabPane>
        <TabPane
          tab={"Inactive"}
          key="key3"
          style={{ height: 500, overflowY: "scroll" }}
        >
          <List
            itemLayout="horizontal"
            dataSource={inActiveRobotsData}
            size="small"
            renderItem={(item) => (
              <List.Item key={item.date} title={item.date}>
                {item.robots.map((i) => (
                  <List.Item.Meta
                    title={item.date}
                    description={i.unit}
                  ></List.Item.Meta>
                ))}
              </List.Item>
            )}
          />
        </TabPane>

        <TabPane
          tab={"Events"}
          key="key4"
          style={{ height: 500, overflowY: "scroll" }}
        >
          <div style={{ height: 200 }}>
            <HighestEventsSWRs
              data={alarmData.filter((item) =>
                moment(item.received_at).isSameOrAfter(
                  filterData
                    .find(({ key }) => key === dropdownKey)
                    .date.format("YYYY-MM-DD")
                )
              )}
            />
          </div>
          <div style={{ paddingTop: 50, height: 200 }}>
            <MostGeneratedEvents
              data={alarmData.filter((item) =>
                moment(item.received_at).isSameOrAfter(
                  filterData
                    .find(({ key }) => key === dropdownKey)
                    .date.format("YYYY-MM-DD")
                )
              )}
            />
          </div>
        </TabPane>
      </Tabs>
    </>
  );
}

function HighestEventsSWRs(props) {
  const { data } = props;
  const NUMBER_OF_SWRS = 5;

  const config = {
    data: chain(data)
      .groupBy("unit")
      .map((item, key) => {
        return { unit: key, count: item.length };
      })
      .flatMap()
      .orderBy("count", "desc")
      .splice(0, NUMBER_OF_SWRS)
      .value(),
    barWidthRatio: 0.9,
    maxBarWidth: 20,
    xField: "count",
    yField: "unit",
  };

  return (
    <>
      <b>{NUMBER_OF_SWRS} Most Event Generating SWRs</b>
      <Bar {...config} />
    </>
  );
}

function MostGeneratedEvents(props) {
  const { data } = props;
  const NUMBER_OF_EVENTS = 10;

  const config = {
    data: chain(data)
      .groupBy("variable_name")
      .map((item, key) => {
        return { message: key, count: item.length };
      })
      .flatMap()
      .orderBy("count", "desc")
      .splice(0, NUMBER_OF_EVENTS)
      .value(),
    barWidthRatio: 0.9,
    maxBarWidth: 20,
    xField: "message",
    yField: "count",
  };

  return (
    <>
      <b>{NUMBER_OF_EVENTS} Most Generated Events</b>
      <Column {...config} />
    </>
  );
}
