import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { createLogger } from "redux-logger";

import rootReducer from "./rootReducer";

export const history = createBrowserHistory();

export default function configureAppStore(preloadedState) {
  const middleware = [...getDefaultMiddleware(), routerMiddleware(history)];

  if (process.env.NODE_ENV === "development") {
    middleware.push(createLogger());
  }

  const store = configureStore({
    reducer: rootReducer(history),
    middleware,
    // preloadedState,
  });

  return store;
}
