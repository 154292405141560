import React from "react";

import jwtDecode from "jwt-decode";

import PasswordRecovery from "../features/auth/PasswordRecovery";
import PasswordReset from "../features/auth/PasswordReset";

import { useQuery } from "../libs/utils";

function PasswordResetPage() {
  const query = useQuery();
  const queryToken = query.get("token");

  let isToken = null;
  try {
    isToken = !!jwtDecode(queryToken);
  } catch (err) {
    console.log("invalid token: err", err);
  }

  return queryToken && isToken ? <PasswordReset /> : <PasswordRecovery />;
}

export default PasswordResetPage;
