import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button, Empty, Space, Select } from "antd";

import { Bar } from "@ant-design/charts";

import moment from "moment";
import { chain } from "lodash";
import { ReloadOutlined } from "@ant-design/icons";

import { getTopOperatorsWeldInchesList } from "../../features/performance/performanceSlice";

import PickerWithType from "../../libs/components/PickerWithType";
import SelectDateType from "../../libs/components/SelectDateType";
import { GetDateRange, normalizeRobotName } from "../../libs/utils";

const today = moment();
const lastWeek = moment().subtract(7, "days");

function TopOperatorsWeldInches(props) {
  const dispatch = useDispatch();
  const top_operators_weld_inches =
    useSelector((state) => state.performance.top_operators_weld_inches) || [];

  const [dateValues, setDateValues] = useState(null);
  const [selectedValues, setSelectedValues] = useState(null);

  const [type, setType] = useState("range");

  useEffect(() => {
    dispatch(
      getTopOperatorsWeldInchesList(null, {
        start: lastWeek.format("YYYY-MM-DD"),
        end: today.format("YYYY-MM-DD"),
      })
    );
  }, [dispatch]);

  const data = [...top_operators_weld_inches]
    .map((item) => ({
      ...item,
    // id uses the name instead of internal id
    // does it use this for display or only for grouping?
    // does it need to be a number?
    id: Number(normalizeRobotName(item.id, false)),
    }))
    .filter((item) => item.value > 0);

  const totalWeldInches = chain([...data])
    .groupBy("id")
    .entries()
    .map(([key, items]) => {
      return {
        id: key,
        value: items.slice(1).reduce((prev, curr) => {
          return prev + curr.value;
        }, items[0].value),
      };
    })
    .value();

  return (
    <>
      <Space>
        <b>Top Operators Weld Inches (FDI)</b>

        <SelectDateType value={type} onChange={setType}></SelectDateType>
        <PickerWithType
          type={type}
          defaultValue={[lastWeek, today]}
          onChange={(value) => setDateValues(value)}
        />
        <Button
          disabled={!dateValues}
          onClick={() => {
            const dateRange = GetDateRange(type, dateValues);

            dispatch(
              getTopOperatorsWeldInchesList(null, {
                start: dateRange[0],
                end: dateRange[1],
              })
            );
          }}
          icon={<ReloadOutlined />}
        ></Button>

        <Select
          style={{ minWidth: 200 }}
          mode="multiple"
          placeholder="Filter by SWR number"
          options={data
            .map((item) => item.id)
            .filter((v, i, a) => a.indexOf(v) === i)
            .map((item) => ({
              label: `SWR-${item}`,
              value: item,
            }))}
          onChange={(values) => {
            setSelectedValues(values);
          }}
        ></Select>
      </Space>

      <div>
        {top_operators_weld_inches &&
        top_operators_weld_inches.length > 0 &&
        data.length > 0 ? (
          <Bar
            {...{
              data:
                !selectedValues || selectedValues.length === 0
                  ? data
                  : data.filter((v, i, a) => {
                      return selectedValues.includes(v.id);
                    }),
              xField: "value",
              yField: "id",
              barWidthRatio: 0.9,
              maxBarWidth: 20,
              legend: false,
              isStack: true,
              seriesField: "operator_name",
              label: {
                position: "middle",
                formatter: function (v) {
                  return v.value.toFixed(1);
                },
              },
              xAxis: {
                label: {
                  formatter: function formatter(v) {
                    return v;
                  },
                },
              },
              yAxis: {
                label: {
                  formatter: function formatter(v) {
                    return `SWR-${v}`;
                  },
                },
              },

              tooltip: {
                title: (v) => {
                  const total = totalWeldInches
                    .find((item) => item.id === v)
                    .value.toFixed(1);
                  return `SWR-${v}: ${total} hours`;
                },
                fields: ["operator_name", "id", "value"],
                formatter: (datum) => {
                  return {
                    name: datum.operator_name,
                    value: datum.value && datum.value.toFixed(1) + " inches",
                  };
                },
              },
            }}
          />
        ) : (
          <Empty />
        )}
      </div>
    </>
  );
}
export default TopOperatorsWeldInches;
