import type { AsyncThunk, Draft } from "@reduxjs/toolkit";
import type {
  AsyncThunkFulfilledActionCreator,
  AsyncThunkPendingActionCreator,
  AsyncThunkRejectedActionCreator,
} from "@reduxjs/toolkit/dist/createAsyncThunk";

type Timestamp = number;

/**
 * Convert a state key generated by createAsyncThunk to its base form
 * thunks have /pending | /fulfilled | /rejected events which are dispatched automatically
 * https://redux-toolkit.js.org/api/createAsyncThunk
 * @param key
 */
function getThunkBaseKey(key: string) {
  const parts = key.split("/");
  const lastIndex = parts.length - 1;
  const suffix = parts[lastIndex];
  if (suffix === "pending" || suffix === "fulfilled" || suffix === "rejected") {
    return parts.slice(0, lastIndex).join("/");
  }
  return key;
}

export interface FetchingState {
  fetching: { [key: string]: boolean };
  // to architect: should errors be per key or per store
  fetchingError: string | null;
  fetchingStart: { [key: string]: Timestamp | null };
  fetchingEnd: { [key: string]: Timestamp | null };
}

export function getInitialState(): FetchingState {
  return {
    fetching: {},
    fetchingError: null,
    fetchingStart: {},
    fetchingEnd: {},
  };
}

export function beginFetch(state: Draft<FetchingState>, key: string): void {
  state.fetching[key] = true;
  state.fetchingStart[key] = Date.now();
  state.fetchingEnd[key] = null;
  state.fetchingError = null;
}

export function abortFetch(
  state: Draft<FetchingState>,
  key: string,
  error?: string
): void {
  state.fetching[key] = false;
  // should we track timestamps for aborted fetches too?
  state.fetchingStart[key] = null;
  state.fetchingEnd[key] = null;
  state.fetchingError = error ?? null;
}

export function completeFetch(state: Draft<FetchingState>, key: string): void {
  state.fetching[key] = false;
  if (state.fetchingStart[key]) {
    state.fetchingEnd[key] = Date.now();
    console.debug(
      `fetching ${key} completed in ${
        state.fetchingEnd[key] - state.fetchingStart[key]
      } ms`
    );
  }
  state.fetchingError = null;
}

export function createBasicPendingCase(
  thunk: AsyncThunk<any, any, any>
): [AsyncThunkPendingActionCreator<any, any>, (state: Draft<any>) => void] {
  return [
    thunk.pending,
    (state) => {
      beginFetch(state, thunk.typePrefix);
    },
  ];
}

export function createBasicFulfilledCase(
  thunk: AsyncThunk<any, any, any>,
  dataProp?: string
): [
  AsyncThunkFulfilledActionCreator<any, any, any>,
  (state: Draft<FetchingState>, action: any) => void
] {
  return [
    thunk.fulfilled,
    (state: Draft<FetchingState>, action) => {
      completeFetch(state, thunk.typePrefix);
      if (dataProp) state[dataProp] = action.payload.data;
    },
  ];
}
export function createNoDataFulfilledCase(
  thunk: AsyncThunk<any, any, any>
): [
  AsyncThunkFulfilledActionCreator<any, any, any>,
  (state: Draft<FetchingState>, action?: any) => void
] {
  return [
    thunk.fulfilled,
    (state: Draft<FetchingState>) => {
      completeFetch(state, thunk.typePrefix);
    },
  ];
}
export function createFulfilledCaseWithObjectMerge(
  thunk: AsyncThunk<any, any, any>,
  dataProp: string
): [
  AsyncThunkFulfilledActionCreator<any, any, any>,
  (state: Draft<FetchingState>, action: any) => void
] {
  return [
    thunk.fulfilled,
    (state: Draft<FetchingState>, action) => {
      completeFetch(state, thunk.typePrefix);
      const prev = state[dataProp];
      if (prev instanceof Array) {
        state[dataProp] = [...prev, ...action.payload];
      } else if (prev instanceof Object) {
        state[dataProp] = { ...prev, ...action.payload };
      } else {
        state[dataProp] = action.payload;
      }
    },
  ];
}
export function createFulfilledCaseWithListAppend(
  thunk: AsyncThunk<any, any, any>,
  dataProp: string,
  checkDuplicate: boolean | ((list, value) => boolean)
): [
  AsyncThunkFulfilledActionCreator<any, any, any>,
  (state: Draft<FetchingState>, action: any) => void
] {
  return [
    thunk.fulfilled,
    (state: Draft<FetchingState>, action) => {
      completeFetch(state, thunk.typePrefix);
      if (state[dataProp] instanceof Array) {
        if (checkDuplicate) {
          const exists =
            typeof checkDuplicate === "function"
              ? checkDuplicate(state[dataProp], action.payload)
              : state[dataProp].contains(action.payload);
          if (!exists) {
            state[dataProp].push(action.payload);
          }
        } else {
          state[dataProp].push(action.payload);
        }
      } else {
        state[dataProp] = [action.payload];
      }
    },
  ];
}

export function createBasicRejectedCase(
  thunk: AsyncThunk<any, any, any>
): [
  AsyncThunkRejectedActionCreator<any, any>,
  (state: Draft<FetchingState>, action: any) => void
] {
  return [
    thunk.rejected,
    (state: Draft<FetchingState>, action) => {
      abortFetch(state, thunk.typePrefix, action.error.message);
    },
  ];
}
