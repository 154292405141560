import React from "react";
import { Alert, Tooltip } from "antd";

const AlarmsList = (props) => {
  const { alarms } = props;

  return (
    <>
      {alarms.length > 0 &&
        alarms.map((item, index) => {
          return (
            <Tooltip placement="topLeft" title={item._raw}>
              <Alert
                style={{ margin: "10px auto" }}
                type={item.type === "crash" ? "error" : item.type}
                showIcon
                message={
                  <span>
                    <b>{item.message}</b>
                    <br />
                    {item.received_at && (
                      <React.Fragment>
                        <span> received at </span>
                        <i>{item.received_at}</i>
                      </React.Fragment>
                    )}
                    {item.cleared_at && (
                      <React.Fragment>
                        <span>, cleared at </span>
                        <i>{item.cleared_at}</i>
                      </React.Fragment>
                    )}
                    {item.acknowledged_at && (
                      <React.Fragment>
                        <span>, acknowledged at </span>
                        <i>{item.acknowledged_at}</i>
                      </React.Fragment>
                    )}
                    <br />
                    {item.variable_name && (
                      <React.Fragment>
                        <span> Detail: </span>
                        <span>{item.variable_name}</span>
                        {item.value && (
                          <React.Fragment>
                            {/* <span>  </span> */}: (<i>{item.value}</i>)
                          </React.Fragment>
                        )}{" "}
                      </React.Fragment>
                    )}
                    {/* <b>{alarmMessage[4]}</b> */}

                    {/* <b>{alarmMessage[5]}</b> */}

                    {/* <b>{alarmMessage[6]}</b> */}

                    {/* <b>{alarmMessage[7]}</b> */}

                    {/* <b>{alarmMessage[8]}</b> */}
                  </span>
                }
              ></Alert>
            </Tooltip>
          );
        })}
    </>
  );
};

export default AlarmsList;
