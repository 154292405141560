import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Table, Tabs, Modal } from "antd";
import { ReloadOutlined, FilePdfOutlined } from "@ant-design/icons";
import { chain, orderBy } from "lodash";

import Moment from "moment";
import "moment-timezone";
import { extendMoment } from "moment-range";

import {
  getPipeSchedule,
  GetDateRange,
  convertInchesTomm,
  getTimeZone,
} from "../../libs/utils";

import LoadingPage from "../../components/LoadingPage";

import PickerWithType from "../../libs/components/PickerWithType";
import SelectDateType from "../../libs/components/SelectDateType";

import { getRobotWeld } from "./robotSlice";
import { PDFDownload, PDFPreview } from "../reports/GeneratePDFReports";

const { TabPane } = Tabs;
const moment = extendMoment(Moment);

const HOURS_IN_SHIFT = 8;

const filterData = [
  {
    key: 1,
    title: "Last 24 hours",
    value: 1,
    date: moment().subtract(1, "days"),
  },
  {
    key: 2,
    title: "Last 7 days",
    value: 7,
    date: moment().subtract(7, "days"),
  },
  {
    key: 3,
    title: "Last 30 days",
    value: 30,
    date: moment().subtract(30, "days"),
  },
];

const today = moment();
const lastWeek = moment().subtract(7, "days");

//unused
function RobotWeldHistory(props) {
  const dispatch = useDispatch();
  const { robotName } = useParams();

  const buttonRef = useRef();

  const robotId =
    robotName && robotName.match(/(\d+)/) && robotName.match(/(\d+)/)[0];
  const {
    // weldRun = [],
    tier: { tier },
    isCustomer,
  } = props;

  const weld_run = useSelector((state) => state.robot.weldRun);

  const [activeKey, setActiveKey] = useState("key3");
  const [expandedKeys, setExpandedKeys] = useState([]);

  const [filterButtonKey, setFilterButtonKey] = useState(3); // default view as last 30 days

  const [dateValues, setDateValues] = useState(null);
  const [type, setType] = useState("range");

  const [openModal, setOpenModal] = useState(false);

  const activeButtonStyle = {
    color: "#40a9ff",
    borderColor: "#40a9ff",
  };

  const operations = (
    <>
      <Button
        onClick={() => setFilterButtonKey(1)}
        style={filterButtonKey === 1 ? activeButtonStyle : {}}
      >
        {filterData.find((item) => item.key === 1).title}
      </Button>
      <Button
        onClick={() => setFilterButtonKey(2)}
        style={filterButtonKey === 2 ? activeButtonStyle : {}}
      >
        {filterData.find((item) => item.key === 2).title}
      </Button>
      <Button
        onClick={() => setFilterButtonKey(3)}
        style={filterButtonKey === 3 ? activeButtonStyle : {}}
      >
        {filterData.find((item) => item.key === 3).title}
      </Button>
    </>
  );

  const customOperations = (
    <>
      <SelectDateType
        value={type}
        onChange={(value) => {
          setType(value);
          if (type === "range") {
            // set to the default
            setDateValues([lastWeek, today]);
          }
        }}
      ></SelectDateType>
      <PickerWithType
        type={type}
        value={dateValues}
        defaultValue={[lastWeek, today]}
        onChange={(value) => {
          setDateValues(value);
          setTimeout(() => {
            buttonRef.current.click();
          }, 100);
        }}
      />
      <Button
        style={{ display: "none" }}
        ref={buttonRef}
        disabled={!dateValues}
        onClick={() => {
          const dateRange = GetDateRange(type, dateValues);
          dispatch(
            getRobotWeld({
              id: robotId,
              start: dateRange[0],
              end: dateRange[1],
            })
          );
        }}
        icon={<ReloadOutlined />}
      ></Button>
      <Button
        icon={<FilePdfOutlined />}
        onClick={() => {
          setOpenModal(true);
        }}
      ></Button>
      {/* <PDFDownload
        robotName={robotName}
        weldRun={weldReportData}
        date={
          dateValues
            ? [
                GetDateRange(type, dateValues)[0],
                GetDateRange(type, dateValues)[1],
              ]
            : [lastWeek.format("YYYY-MM-DD"), today.format("YYYY-MM-DD")]
        }
      ></PDFDownload> */}
    </>
  );

  useEffect(() => {
    if (!(isCustomer && tier === "lite")) {
      dispatch(
        getRobotWeld({
          id: robotId,
          start: lastWeek.format("YYYY-MM-DD"),
          end: today.format("YYYY-MM-DD"),
        })
      );
    }
  }, [dispatch, robotId, isCustomer, tier]);

  if (!weld_run && !(weld_run && weld_run[robotId])) {
    return <LoadingPage></LoadingPage>;
  }

  const weldRun = weld_run[robotId];

  const isMetric =
    weldRun && weldRun[0] && weldRun[0].units_system === "metric";

  const weldRunFilters = (column) => {
    return weldRun
      .filter((item) => {
        return moment(item.arc_on_utc).isSameOrAfter(
          filterData
            .find((i) => i.key === filterButtonKey)
            .date.format("YYYY-MM-DD")
        );
      })
      .map((item) => item[column])
      .filter((value, index, self) => self.indexOf(value) === index)
      .map((item) => {
        return { text: item, value: item };
      });
  };

  const weldRunColumns = (isParent) =>
    [
      // { title: "" },
      {
        title: "Date",

        render: (_, item) => {
          if (item.children) {
            return <span>{moment(item.arc_off_utc).format("YYYY-MM-DD")}</span>;
          } else {
            return <span>{moment(item.arc_on_utc).format("YYYY-MM-DD")}</span>;
          }
        },
      },
      {
        title: "Order",
        dataIndex: "work_order_ref",
        filters: weldRunFilters("work_order_ref"),
        onFilter: (value, record) => record.work_order_ref.indexOf(value) === 0,
      },
      {
        title: "Spool",
        dataIndex: "spool_ref",
        filters: weldRunFilters("spool_ref"),
        onFilter: (value, record) => record.spool_ref.indexOf(value) === 0,
      },
      !isParent && {
        title: "Weld ID",
        dataIndex: "welder_id_ref",
        // filters: weldRunFilters("welder_id_ref"),
        onFilter: (value, record) =>
          record.welder_id_ref && record.welder_id_ref.indexOf(value) === 0,
      },
      !isParent && {
        title: "Run",
        align: "center",
        dataIndex: "run_number", // should have count
      },
      {
        title: "Start Time",
        align: "center",
        render: (_, item) => {
          return (
            <span>
              {moment
                .tz(item.arc_on_utc, getTimeZone(item.time_zone))
                .format("HH:mm:ss")}
            </span>
          );
        },
      },
      {
        title: "Arc Off Time",
        align: "center",
        render: (_, item) => {
          if (item.duration) {
            return (
              <span>
                {Number(item.duration.hours()) * 60 +
                  Number(item.duration.minutes()) +
                  "m "}
                {item.duration.seconds() + "s"}
              </span>
            );
          } else {
            return <span>{null}</span>;
          }
        },
      },
      {
        title: "Arc On Time",
        align: "center",
        render: (_, item) => {
          return (
            <span>
              {Number(moment.duration({ ...item.weld_time }).hours()) * 60 +
                Number(moment.duration({ ...item.weld_time }).minutes()) +
                "m "}
              {moment.duration({ ...item.weld_time }).seconds() + "s"}
            </span>
          );
        },
      },
      {
        title: "Pipe Size",
        align: "center",
        filters: weldRun
          .filter((item) =>
            moment(item.arc_on_utc).isSameOrAfter(
              filterData
                .find(({ key }) => key === filterButtonKey)
                .date.format("YYYY-MM-DD")
            )
          )
          .map((item) => {
            return `${
              isMetric
                ? convertInchesTomm(item.pipe_size_inches).toFixed(0)
                : item.pipe_size_inches
            }${isMetric ? "mm" : "in"}`;
          })
          .filter((value, index, self) => self.indexOf(value) === index)
          .map((item) => {
            return { text: item, value: item };
          }),
        onFilter: (value, record) => {
          const unit =
            !record.units_system || record.units_system === "imperial"
              ? "in"
              : "mm";
          return `${record.pipe_size_inches}${unit}` === value;
        },
        render: (_, item) => {
          const unit =
            !item.units_system || item.units_system === "imperial"
              ? "in"
              : "mm";
          return <span>{`${item.pipe_size_inches}${unit}`}</span>;
        },
      },
      !isParent && {
        title: "Pipe Sched",
        align: "center",
        filters: weldRun
          .filter((item) =>
            moment(item.arc_on_utc).isSameOrAfter(
              filterData
                .find(({ key }) => key === filterButtonKey)
                .date.format("YYYY-MM-DD")
            )
          )
          .map((item) => {
            return getPipeSchedule(item.pipe_schedule);
          })
          .filter((value, index, self) => self.indexOf(value) === index)
          .map((item) => {
            return { text: item, value: item };
          }),
        onFilter: (value, record) => {
          return record.pipe_schedule === value;
        },
        render: (_, item) => {
          return <span>{getPipeSchedule(item.pipe_schedule)}</span>;
        },
      },
      {
        title: "FDI",
        align: "center",
        render: (_, item) => {
          if (
            Number(moment.duration({ ...item.weld_time }).asMinutes()) >=
            Number(moment.duration({ minutes: 1 }).asMinutes())
          ) {
            return <span>{item.weld_inches}</span>;
          } else {
            return <span></span>;
          }
        },
      },
      !isParent && {
        title: "Operator",
        align: "center",
        dataIndex: "operator_name",
        // filters: weldRunFilters("operator_name"),
        onFilter: (value, record) => {
          if (record.children && record.children.length > 0) {
            return record.children
              .map((child) => child.operator_name.indexOf(value) === 0)
              .filter((c) => c);
          } else {
            return (
              record.operator_name && record.operator_name.indexOf(value) === 0
            );
          }
        },
      },
      !isParent && {
        title: "Procedure",
        align: "center",
        dataIndex: "procedure_name",
        // filters: weldRunFilters("procedure_name"),
        onFilter: (value, record) =>
          record.procedure_name && record.procedure_name.indexOf(value) === 0,
      },
    ].filter((item) => item);

  const pagination = { hideOnSinglePage: true };

  const weldReportData = chain([...weldRun])
    .map((item) => {
      const idx = weldRun.findIndex((i) => i === item);

      if (
        idx < weldRun.length - 1 &&
        moment(
          moment(weldRun[idx + 1].arc_on_utc).utc(true),
          "YYYY-MM-DD"
        ).diff(
          moment(moment(item.arc_on_utc).utc(true), "YYYY-MM-DD"),
          "hour"
        ) >= -HOURS_IN_SHIFT
      ) {
        const duration = moment.duration(
          moment(item.arc_on_utc).diff(moment(weldRun[idx + 1].arc_off_utc))
        );

        return {
          ...item,
          duration,

          pipe_size_inches: isMetric
            ? convertInchesTomm(item.pipe_size_inches).toFixed(0)
            : item.pipe_size_inches,
        };
      } else {
        return {
          ...item,

          pipe_size_inches: isMetric
            ? convertInchesTomm(item.pipe_size_inches).toFixed(0)
            : item.pipe_size_inches,
        };
      }
    })
    .value()
    .reverse();

  const groupedWeldRun = chain(weldRun)
    .map((item) => {
      const idx = weldRun.findIndex((i) => i === item);
      if (
        idx < weldRun.length - 1 &&
        moment(weldRun[idx + 1].arc_on_utc, "YYYY-MM-DD").diff(
          moment(item.arc_on_utc, "YYYY-MM-DD"),
          "days"
        ) === 0
      ) {
        const duration = moment.duration(
          moment(item.arc_on_utc).diff(moment(weldRun[idx + 1].arc_off_utc))
        );

        return {
          ...item,
          duration,

          pipe_size_inches: isMetric
            ? convertInchesTomm(item.pipe_size_inches).toFixed(0)
            : item.pipe_size_inches,
        };
      } else {
        return {
          ...item,

          pipe_size_inches: isMetric
            ? convertInchesTomm(item.pipe_size_inches).toFixed(0)
            : item.pipe_size_inches,
        };
      }
    })
    .groupBy((item) => {
      return item.work_order_ref + " " + item.spool_ref;
    })
    .entries()
    .map(([work_order_spool, weld]) => {
      const items = orderBy(
        [...weld],
        [
          (i) => {
            return i.run_number;
          },
        ],
        ["desc"]
      );

      const childrenSize = items.length;
      const first = items[0];
      const last = items[childrenSize - 1];

      return {
        key: work_order_spool,
        items,
        arc_on_utc: last.arc_on_utc,
        arc_off_utc: first.arc_off_utc,
        work_order_ref: last.work_order_ref,
        spool_ref: last.spool_ref,

        units_system: last.units_system,
        pipe_size_inches: last.pipe_size_inches,
        pipe_schedule: last.pipe_schedule,

        // sum of arc off time (setup time) on grouped weld
        duration: items.slice(1).reduce((prev, curr) => {
          return prev.add(curr.duration);
        }, moment.duration(first.duration)),

        // sum of weld time on grouped weld
        weld_time: items.slice(1).reduce((prev, curr) => {
          return prev.add(curr.weld_time);
        }, moment.duration(first.weld_time))._data,

        // sum of weld inches with more than 1 min weld time
        weld_inches: items
          .filter((item) => {
            return (
              Number(moment.duration({ ...item.weld_time }).asMinutes()) >=
              Number(moment.duration({ minutes: 1 }).asMinutes())
            );
          })
          .reduce((prev, curr) => {
            return prev + Number(curr.weld_inches);
          }, 0)
          .toFixed(1),

        // sum of weld fdi with more than 1 min weld time
        weld_fdi: items
          .filter((item) => {
            return (
              Number(moment.duration({ ...item.weld_time }).asMinutes()) >=
              Number(moment.duration({ minutes: 1 }).asMinutes())
            );
          })
          .reduce((prev, curr) => {
            return prev + Number(curr.weld_fdi);
          }, 0)
          .toFixed(1),

        // sum of weld di with more than 1 min weld time
        weld_di: items
          .filter((item) => {
            return (
              Number(moment.duration({ ...item.weld_time }).asMinutes()) >=
              Number(moment.duration({ minutes: 1 }).asMinutes())
            );
          })
          .reduce((prev, curr) => {
            return prev + Number(curr.weld_di);
          }, 0)
          .toFixed(1),
      };
    })
    .value();

  return (
    <>
      {!(isCustomer && tier === "lite") ? (
        <Tabs
          activeKey={activeKey}
          size={"small"}
          type="card"
          onTabClick={(key) => setActiveKey(key)}
          tabBarExtraContent={customOperations}
        >
          <TabPane
            tab={"Weld History"}
            key="key3"
            style={{ height: 417, overflowY: "scroll", overflowX: "scroll" }}
          >
            <Table
              className="WeldHistoryList_Table"
              size={"small"}
              columns={weldRunColumns(true)}
              dataSource={groupedWeldRun.filter((item) => {
                if (dateValues) {
                  const dateRange = GetDateRange(type, dateValues);
                  return moment(
                    moment(item.arc_on_utc).utc(true).format("YYYY-MM-DD")
                  ).isBetween(dateRange[0], dateRange[1], undefined, "[]");
                } else {
                  return moment(
                    moment(item.arc_on_utc).utc(true).format("YYYY-MM-DD")
                  ).isBetween(
                    lastWeek.format("YYYY-MM-DD"),
                    today.format("YYYY-MM-DD"),
                    undefined,
                    "[]"
                  );
                }
              })}
              onRow={({ key }) =>
                expandedKeys.includes(key) && { className: "expand-parent" }
              }
              expandable={{
                showExpandColumn: true,
                rowExpandable: (record) => !!record.items,

                onExpand: (expanded, { key }) => {
                  const keys = expandedKeys;
                  setExpandedKeys(
                    expanded ? keys.concat(key) : keys.filter((k) => k !== key)
                  );
                },
                expandedRowRender: (record) => {
                  return (
                    <Table
                      className="WeldHistoryList_Table WeldHistoryList_Table_Nested"
                      size={"small"}
                      columns={weldRunColumns()}
                      dataSource={record.items}
                      pagination={{
                        ...pagination,
                        pageSize: record.items.length,
                      }}
                      expandable={{
                        showExpandColumn: false,
                      }}
                    />
                  );
                },
              }}
              pagination={{ ...pagination, pageSize: groupedWeldRun.length }}
            />
            <Modal
              style={{ width: "80wv" }}
              visible={openModal}
              width={window.innerWidth / 1.4}
              footer={[
                <PDFDownload
                  title="Operations Report"
                  robotName={robotName}
                  weldRun={weldReportData}
                  type={type}
                  date={
                    dateValues
                      ? [
                          GetDateRange(type, dateValues)[0],
                          GetDateRange(type, dateValues)[1],
                        ]
                      : [
                          lastWeek.format("YYYY-MM-DD"),
                          today.format("YYYY-MM-DD"),
                        ]
                  }
                ></PDFDownload>,
                <Button
                  style={{ marginLeft: 8 }}
                  key="submit"
                  type="primary"
                  onClick={() => setOpenModal(false)}
                >
                  Close
                </Button>,
              ]}
              onCancel={() => {
                setOpenModal(false);
              }}
              onOk={() => {
                setOpenModal(false);
              }}
            >
              <PDFPreview
                title="Operations Report"
                robotName={robotName}
                weldRun={weldReportData}
                type={type}
                date={
                  dateValues
                    ? [
                        GetDateRange(type, dateValues)[0],
                        GetDateRange(type, dateValues)[1],
                      ]
                    : [
                        lastWeek.format("YYYY-MM-DD"),
                        today.format("YYYY-MM-DD"),
                      ]
                }
              />
            </Modal>
          </TabPane>
        </Tabs>
      ) : (
        <Tabs
          activeKey={activeKey}
          size={"small"}
          type="card"
          onTabClick={(key) => setActiveKey(key)}
          tabBarExtraContent={operations}
        >
          <TabPane
            tab={"Weld History"}
            key="key3"
            style={{ height: 417, overflowY: "scroll", overflowX: "scroll" }}
          >
            <Table
              className="WeldHistoryList_Table"
              size={"small"}
              columns={weldRunColumns(true)}
              dataSource={groupedWeldRun.filter((item) => {
                return moment(item.arc_on_utc).isSameOrAfter(
                  filterData
                    .find((i) => i.key === filterButtonKey)
                    .date.format("YYYY-MM-DD")
                );
              })}
              onRow={({ key }) =>
                expandedKeys.includes(key) && { className: "expand-parent" }
              }
              expandable={{
                showExpandColumn: true,
                rowExpandable: (record) => !!record.items,

                onExpand: (expanded, { key }) => {
                  const keys = expandedKeys;
                  setExpandedKeys(
                    expanded ? keys.concat(key) : keys.filter((k) => k !== key)
                  );
                },
                expandedRowRender: (record) => {
                  return (
                    <Table
                      className="WeldHistoryList_Table WeldHistoryList_Table_Nested"
                      size={"small"}
                      columns={weldRunColumns()}
                      dataSource={record.items}
                      pagination={{
                        ...pagination,
                        pageSize: record.items.length,
                      }}
                      expandable={{
                        showExpandColumn: false,
                      }}
                    />
                  );
                },
              }}
              pagination={{ ...pagination, pageSize: groupedWeldRun.length }}
            />
          </TabPane>
        </Tabs>
      )}
    </>
  );
}

export function RobotWeldActivity(props) {
  const { weldRun, isMetric } = props;

  const [expandedKeys, setExpandedKeys] = useState([]);

  if (!weldRun) return null;

  const weldRunFilters = (column) => {
    return weldRun
      .map((item) => item[column])
      .filter((value, index, self) => self.indexOf(value) === index)
      .map((item) => {
        return { text: item, value: item };
      });
  };

  const weldRunColumns = (isParent) =>
    [
      // { title: "" },
      {
        title: "Date",

        render: (_, item) => {
          // This will bypass all of timezone handling from browsers and libraries (momentjs, fullcalendar)
          // To provide consistent timestamp

          // Rendering weld time date
          // if it is grouped (item.children) then it will use last welded time
          if (item.children) {
            return (
              <span>
                {moment
                  .tz(item.arc_off_at, getTimeZone(item.time_zone))
                  .format("YYYY-MM-DD")}
              </span>
            );
          } else {
            // if it is grouped (item.children) then it will use the welded time
            return (
              <span>
                {moment
                  .tz(item.arc_on_at, getTimeZone(item.time_zone))
                  .format("YYYY-MM-DD")}
              </span>
            );
          }
        },
      },
      {
        title: "Order",
        dataIndex: "work_order_ref",
        filters: weldRunFilters("work_order_ref"),
        onFilter: (value, record) => record.work_order_ref.indexOf(value) === 0,
      },
      {
        title: "Spool",
        dataIndex: "spool_ref",
        filters: weldRunFilters("spool_ref"),
        onFilter: (value, record) => record.spool_ref.indexOf(value) === 0,
      },
      !isParent && {
        title: "Weld ID",
        dataIndex: "welder_id_ref",
        // filters: weldRunFilters("welder_id_ref"),
        onFilter: (value, record) =>
          record.welder_id_ref && record.welder_id_ref.indexOf(value) === 0,
      },
      !isParent && {
        title: "Run",
        align: "left",
        dataIndex: "run_number", // should have count
      },
      {
        title: "Start Time",
        align: "left",
        render: (_, item) => {
          return (
            <span>
              {moment
                .tz(item.arc_on_at, getTimeZone(item.time_zone))
                .format("HH:mm:ss")}
            </span>
          );
        },
      },
      {
        title: "Arc Off Time",
        align: "left",
        render: (_, item) => {
          if (item.duration) {
            return (
              <span>
                {Number(item.duration.hours()) * 60 +
                  Number(item.duration.minutes()) +
                  "m "}
                {item.duration.seconds() + "s"}
              </span>
            );
          } else {
            return <span>{null}</span>;
          }
        },
      },
      {
        title: "Arc On Time",
        align: "left",
        render: (_, item) => {
          return (
            <span>
              {Number(moment.duration({ ...item.weld_time }).hours()) * 60 +
                Number(moment.duration({ ...item.weld_time }).minutes()) +
                "m "}
              {moment.duration({ ...item.weld_time }).seconds() + "s"}
            </span>
          );
        },
      },
      {
        title: "Pipe Size",
        align: "left",
        filters: weldRun
          .map((item) => {
            return `${
              isMetric
                ? convertInchesTomm(item.pipe_size_inches).toFixed(0) + " mm"
                : item.pipe_size_inches + " in"
            }`;
          })
          .filter((value, index, self) => self.indexOf(value) === index)
          .map((item) => {
            return { text: item, value: item };
          }),
        onFilter: (value, record) => {
          const unit = isMetric ? "mm" : "in";
          return `${record.pipe_size_inches}${unit}` === value;
        },
        render: (_, item) => {
          const unit = isMetric ? "mm" : "in";
          return <span>{`${item.pipe_size_inches}${unit}`}</span>;
        },
      },
      !isParent && {
        title: "Pipe Sched",
        align: "left",
        filters: weldRun
          .map((item) => {
            return getPipeSchedule(item.pipe_schedule);
          })
          .filter((value, index, self) => self.indexOf(value) === index)
          .map((item) => {
            return { text: item, value: item };
          }),
        onFilter: (value, record) => {
          return record.pipe_schedule === value;
        },
        render: (_, item) => {
          return <span>{getPipeSchedule(item.pipe_schedule)}</span>;
        },
      },
      {
        title: "FDI",
        align: "left",
        render: (_, item) => {
          if (
            Number(moment.duration({ ...item.weld_time }).asMinutes()) >=
            Number(moment.duration({ minutes: 1 }).asMinutes())
          ) {
            return <span>{item.weld_fdi}</span>;
          } else {
            return <span></span>;
          }
        },
      },
      {
        title: "DI",
        align: "left",
        render: (_, item) => {
          if (
            Number(moment.duration({ ...item.weld_time }).asMinutes()) >=
            Number(moment.duration({ minutes: 1 }).asMinutes())
          ) {
            return <span>{item.weld_di}</span>;
          } else {
            return <span></span>;
          }
        },
      },
      !isParent && {
        title: "Operator",
        align: "center",
        dataIndex: "operator_name",
        // filters: weldRunFilters("operator_name"),
        onFilter: (value, record) => {
          if (record.children && record.children.length > 0) {
            return record.children
              .map((child) => child.operator_name.indexOf(value) === 0)
              .filter((c) => c);
          } else {
            return (
              record.operator_name && record.operator_name.indexOf(value) === 0
            );
          }
        },
      },
      !isParent && {
        title: "Procedure",
        align: "center",
        dataIndex: "procedure_name",
        // filters: weldRunFilters("procedure_name"),
        onFilter: (value, record) =>
          record.procedure_name && record.procedure_name.indexOf(value) === 0,
      },
    ].filter((item) => item);

  const pagination = { hideOnSinglePage: true };

  const groupedWeldRun = chain(weldRun)
    .map((item) => {
      const idx = weldRun.findIndex((i) => i === item);
      if (
        idx < weldRun.length - 1 &&
        moment(weldRun[idx + 1].arc_on_utc, "YYYY-MM-DD").diff(
          moment(item.arc_on_utc, "YYYY-MM-DD"),
          "days"
        ) === 0
      ) {
        const duration = moment.duration(
          moment(item.arc_on_utc).diff(moment(weldRun[idx + 1].arc_off_utc))
        );

        return {
          ...item,
          duration,

          pipe_size_inches: isMetric
            ? convertInchesTomm(item.pipe_size_inches).toFixed(0)
            : item.pipe_size_inches,
        };
      } else {
        return {
          ...item,

          pipe_size_inches: isMetric
            ? convertInchesTomm(item.pipe_size_inches).toFixed(0)
            : item.pipe_size_inches,
        };
      }
    })
    .groupBy((item) => {
      return item.work_order_ref + " " + item.spool_ref;
    })
    .entries()
    .map(([work_order_spool, weld]) => {
      const items = orderBy(
        [...weld],
        [
          (i) => {
            return i.run_number;
          },
        ],
        ["desc"]
      );

      const childrenSize = items.length;
      const first = items[0];
      const last = items[childrenSize - 1];

      return {
        key: work_order_spool,
        items,
        time_zone: last.time_zone,
        arc_on_utc: last.arc_on_utc,
        arc_off_utc: first.arc_off_utc,
        arc_on_at: last.arc_on_at,
        arc_off_at: first.arc_off_at,
        work_order_ref: last.work_order_ref,
        spool_ref: last.spool_ref,

        units_system: last.units_system,
        pipe_size_inches: last.pipe_size_inches,
        pipe_schedule: last.pipe_schedule,

        // sum of arc off time (setup time) on grouped weld
        duration: items.slice(1).reduce((prev, curr) => {
          return prev.add(curr.duration);
        }, moment.duration(first.duration)),

        // sum of weld time on grouped weld
        weld_time: items.slice(1).reduce((prev, curr) => {
          return prev.add(curr.weld_time);
        }, moment.duration(first.weld_time))._data,

        // sum of weld inches with more than 1 min weld time
        weld_inches: items
          .filter((item) => {
            return (
              Number(moment.duration({ ...item.weld_time }).asMinutes()) >=
              Number(moment.duration({ minutes: 1 }).asMinutes())
            );
          })
          .reduce((prev, curr) => {
            return prev + Number(curr.weld_inches);
          }, 0)
          .toFixed(1),

        // sum of weld fdi with more than 1 min weld time
        weld_fdi: items
          .filter((item) => {
            return (
              Number(moment.duration({ ...item.weld_time }).asMinutes()) >=
              Number(moment.duration({ minutes: 1 }).asMinutes())
            );
          })
          .reduce((prev, curr) => {
            return prev + Number(curr.weld_fdi);
          }, 0)
          .toFixed(1),

        // sum of weld di with more than 1 min weld time
        weld_di: items
          .filter((item) => {
            return (
              Number(moment.duration({ ...item.weld_time }).asMinutes()) >=
              Number(moment.duration({ minutes: 1 }).asMinutes())
            );
          })
          .reduce((prev, curr) => {
            return prev + Number(curr.weld_di);
          }, 0)
          .toFixed(1),
      };
    })
    .value();

  const parentCols = weldRunColumns(true);
  const subCols = weldRunColumns(false);

  return (
    <Table
      className="WeldHistoryList_Table"
      size={"small"}
      columns={parentCols}
      dataSource={groupedWeldRun}
      onRow={({ key }) =>
        expandedKeys.includes(key) && { className: "expand-parent" }
      }
      expandable={{
        showExpandColumn: true,
        rowExpandable: (record) => !!record.items,

        onExpand: (expanded, { key }) => {
          const keys = expandedKeys;
          setExpandedKeys(
            expanded ? keys.concat(key) : keys.filter((k) => k !== key)
          );
        },
        expandedRowRender: (record) => {
          return (
            <Table
              className="WeldHistoryList_Table WeldHistoryList_Table_Nested"
              size={"small"}
              columns={subCols}
              dataSource={record.items}
              pagination={{
                ...pagination,
                pageSize: record.items.length,
              }}
              expandable={{
                showExpandColumn: false,
              }}
            />
          );
        },
      }}
      pagination={{ ...pagination, pageSize: groupedWeldRun.length }}
    />
  );
}
