import React from "react";

import { Col, Row } from "antd";
import { blue, cyan } from "@ant-design/colors";
import { Bar, Line } from "@ant-design/charts";

import Moment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);
const today = moment();
const lastWeek = today.clone().subtract(7, "days");
const last30Days = today.clone().subtract(30, "days");

export function RobotWeldInches(props) {
  const { range, weldData } = props;

  if (!weldData) return null;
  let data = [];

  const fdi_data = range.map((item) => {
    const date = item.format("YYYY-MM-DD");
    const weldDoneDate = weldData.filter((i) => i.date === date);

    return {
      date: date,
      type: "FDI",
      value:
        (weldDoneDate && weldDoneDate.length > 0 && weldDoneDate[0].value) || 0,
    };
  });

  const di_data = range.map((item) => {
    const date = item.format("YYYY-MM-DD");
    const weldDoneDate = weldData.filter((i) => i.date === date);

    return {
      date: date,
      type: "DI",
      value:
        (weldDoneDate && weldDoneDate.length > 0 && weldDoneDate[0].value_di) ||
        0,
    };
  });

  const sum_fdi_data = fdi_data
    .reduce((a, b) => {
      return a + b.value;
    }, 0)
    .toFixed(1);

  const sum_di_data = di_data
    .reduce((a, b) => {
      return a + b.value;
    }, 0)
    .toFixed(1);

  data = [...fdi_data, ...di_data, sum_fdi_data, sum_di_data];

  return (
    <>
      <div
        style={{
          position: "absolute",
          right: "1em",
          display: "flex",
          flexDirection: "column",
          textAlign: "right",
        }}
      >
        <span style={{ color: "#6295F9", fontSize: 18, fontWeight: 600 }}>
          <span style={{ color: "#BFBFBF", fontSize: 12, fontWeight: 400 }}>
            Total FDI
          </span>{" "}
          {data.slice(-2, -1)}″
        </span>
        <span style={{ color: "#6CDAAB", fontSize: 18, fontWeight: 600 }}>
          <span style={{ color: "#BFBFBF", fontSize: 12, fontWeight: 400 }}>
            Total DI
          </span>{" "}
          {data.slice(-1)}″
        </span>
      </div>
      <Line
        {...{
          height: 350,
          data: data.slice(0, -2),

          xField: "date",
          yField: "value",
          seriesField: "type",
          xAxis: {
            label: {
              formatter: function formatter(v) {
                return moment(v).format("MMM-DD");
              },
            },
          },
          yAxis: {
            label: {
              formatter: function formatter(v) {
                return v + "″";
              },
            },
          },

          legend: {
            layout: "vertical",
            position: "top-left",
            marker: { symbol: "circle" },
          },

          lineStyle: ({ type }) => {
            if (type === "DI") {
              return {
                lineDash: [4, 4],
              };
            }
          },

          tooltip: {
            enterable: true,

            //TODO: Rendering with array adds , in between the list item
            customContent: (title, data) => {
              const aHrefLink = window.location.href.replace(
                "analytics",
                `search#video/${title}`
              );

              if (data.length > 0)
                return `
          <div className="g2-tooltip-title" style="margin-bottom: 12px; margin-top: 12px">
              <a href="${aHrefLink}">${title}</a>
          </div>
          <ul
            className="g2-tooltip-list"
            style="margin: 0px; list-style-type: none; padding: 0px"
          >
          ${data
            .map(
              (item, idx) => `<li
            className="g2-tooltip-list-item"
            data-index="${idx}"
            style="list-style-type: none; padding: 0px; margin: 12px 0px"
          >
            <span
              className="g2-tooltip-marker"
              style="
            background: ${item.color};
            width: 8px;
            height: 8px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 8px;
          "
            ></span>
            <span className="g2-tooltip-name">${item.name}</span>:
            <span
              className="g2-tooltip-value"
              style="display: inline-block; float: right; margin-left: 30px"
            >
              ${item.value}
            </span>
          </li>`
            )
            .join(" ")}
          </ul>`;
            },

            formatter: (datum) => {
              return {
                name: datum.type,
                value: Number(datum.value).toFixed(1) + "″",
              };
            },
          },
        }}
      />
    </>
  );
}

export function RobotWeldHours(props) {
  const { range, weldData } = props;

  if (!weldData) return null;
  let data = [];

  data = range.map((item) => {
    const date = item.format("YYYY-MM-DD");
    const weldDoneDate = weldData
      .map((item) => ({
        ...item,
        weld_time: moment.duration(item.value).asMinutes(),
      }))
      .filter((item) => item.date === date);

    return {
      date: date,
      weld_time:
        (weldDoneDate &&
          weldDoneDate.length > 0 &&
          weldDoneDate[0].weld_time) ||
        0,
    };
  });

  const sum_weld_time = data.reduce((a, b) => {
    return a + b.weld_time;
  }, 0);

  return (
    <>
      <div
        style={{
          position: "absolute",
          right: "1em",
          display: "flex",
          flexDirection: "column",
          textAlign: "right",
        }}
      >
        <span style={{ color: "#6295F9", fontSize: 18, fontWeight: 600 }}>
          <span style={{ color: "#BFBFBF", fontSize: 12, fontWeight: 400 }}>
            Total Arc On Time
          </span>{" "}
          {moment.duration(sum_weld_time, "minutes").asHours().toFixed(1)} hrs
        </span>
      </div>
      <div style={{ marginTop: 60 }}>
        <Line
          {...{
            height: 290,
            data: data,

            xField: "date",
            yField: "weld_time",
            xAxis: {
              label: {
                formatter: function formatter(v) {
                  return moment(v).format("MMM-DD");
                },
              },
            },
            yAxis: {
              label: {
                formatter: function formatter(v) {
                  return (
                    moment.duration({ minutes: v }).asMinutes().toFixed(0) +
                    " min"
                  );
                },
              },
            },

            tooltip: {
              formatter: (datum) => {
                return {
                  name: datum.date,
                  value:
                    moment
                      .duration({ minutes: datum.weld_time })
                      .asMinutes()
                      .toFixed(1) + " min",
                };
              },
            },
          }}
        />
      </div>
    </>
  );
}

export function RobotWeldOperators(props) {
  const { weldInches, weldHours } = props;

  let data = [];

  data = [
    ...weldHours,
    {
      weld_hours: weldHours.slice(1).reduce((prev, curr) => {
        return prev.add(curr.weld_hours);
      }, moment.duration((weldHours[0] && weldHours[0].weld_hours) || 0))._data,

      operator_name: "Overall",
    },
  ].map((item) => ({
    ...item,
    weld_time: moment.duration(item.weld_hours).asMinutes(),
  }));

  return (
    <>
      <Row style={{ height: 350 }}>
        <Col span={24} style={{ paddingBottom: 20 }}>
          <>
            <b>Top Operators by Arc On Time</b>
            <div>
              <Bar
                {...{
                  data: data,
                  xField: "weld_time",
                  yField: "operator_name",
                  seriesField: "operator_name",
                  color: ({ operator_name }) => {
                    return operator_name === "Overall"
                      ? blue[7] //#0050b3
                      : blue[5]; //#1890ff
                  },

                  legend: false,
                  height: 150,
                  barWidthRatio: 0.9,
                  maxBarWidth: 20,
                  xAxis: {
                    label: {
                      formatter: function formatter(v) {
                        return (
                          moment.duration({ minutes: v }).asHours().toFixed(1) +
                          "hr"
                        );
                      },
                    },
                  },
                  tooltip: {
                    formatter: (datum) => {
                      return {
                        name: datum.operator_name,
                        value:
                          datum.weld_time &&
                          moment
                            .duration({ minute: datum.weld_time })
                            .asHours()
                            .toFixed(1) + " hours",
                      };
                    },
                  },
                }}
              />
            </div>
          </>
        </Col>
        <Col span={24}>
          <>
            <b>Top Operators by Weld Inches (FDI/DI)</b>
            <div>
              <Bar
                {...{
                  data: [
                    ...weldInches.map((item) => ({ ...item, type: "FDI" })),
                    {
                      value: weldInches.reduce(
                        (prev, curr) => prev + curr.value,
                        0
                      ),
                      type: "FDI",
                      operator_name: "Overall",
                    },
                    ...weldInches.map((item) => ({
                      ...item,
                      type: "DI",
                      value: item.value_di,
                    })),
                    {
                      value: weldInches.reduce(
                        (prev, curr) => prev + curr.value_di,
                        0
                      ),
                      operator_name: "Overall",
                      type: "DI",
                    },
                  ],
                  isGroup: true,
                  xField: "value",
                  yField: "operator_name",
                  seriesField: "type",
                  color: ({ type }) =>
                    type === "FDI"
                      ? blue[5] //#1890ff
                      : cyan[5], //#13c2c2
                  legend: false,
                  height: 150,
                  barWidthRatio: 0.45,
                  maxBarWidth: 20,
                  xAxis: {
                    label: {
                      formatter: function formatter(v) {
                        return v + "″";
                      },
                    },
                  },
                  tooltip: {
                    formatter: (datum) => {
                      return {
                        name: datum.type,
                        value: datum.value && datum.value.toFixed(1) + "″",
                      };
                    },
                  },
                }}
              />
            </div>
          </>
        </Col>
      </Row>
    </>
  );
}
