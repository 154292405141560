import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Card, Col, Row, Statistic, Table, Tabs } from "antd";

import { DualAxes, Line, Pie, Scatter } from "@ant-design/charts";

import Moment from "moment";
import { extendMoment } from "moment-range";

import { filter, flatten } from "lodash";

import LoadingPage from "../../components/LoadingPage";

import {
  getTickets,
  getTimeLogs,
  getCustomStatuses,
  getCustomFields,
} from "../wrike/wrikeSlice";

import {
  CUSTOMER_SUPPORT_WRIKE_ID,
  WRIKE_TASK_PARAMS,
  CARD_CONFIG_STYLE,
} from "../../libs/constants";

const { TabPane } = Tabs;

const moment = extendMoment(Moment);

const START_DATE = "2020-01-01";

const TOTAL_COMMISSIONED = [
  17, // "2020-01-01"
  17,
  17,
  18,
  18,
  21,
  22,
  22,
  24,
  24,
  26,
  26,
  28,
  28,
  30, // "2021-03-01"
];

function WrikeTrends() {
  const allTasks = useSelector((state) => state.wrike.tasks);
  const timelogs = useSelector((state) => state.wrike.timelogs);
  const workflows = useSelector((state) => state.wrike.workflows);
  const customFields = useSelector((state) => state.wrike.customfields);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getTickets(
        `folders/${CUSTOMER_SUPPORT_WRIKE_ID}/tasks`,
        WRIKE_TASK_PARAMS
      )
    );
    dispatch(getTimeLogs(`folders/${CUSTOMER_SUPPORT_WRIKE_ID}/timelogs`));
    dispatch(getCustomStatuses());
    dispatch(getCustomFields());
  }, [dispatch]);

  if (!(allTasks && timelogs && workflows && customFields)) {
    return <LoadingPage />;
  }

  let customStatusesList = [];
  workflows.map((item) => {
    customStatusesList = customStatusesList.concat(item.customStatuses);
    return item;
  });

  const tasksColumns = [
    {
      title: "Title",
      render: (_, item) => {
        return (
          <a
            href={item.permalink}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "black" }}
          >
            {item.title}
          </a>
        );
      },
      key: "title",
    },
    {
      title: "Status",
      render: (_, item) => {
        const customStatus = customStatusesList.find((i) => {
          return i.id === item.customStatusId;
        });
        return (
          <>
            {(customStatus && customStatus.name && customStatus.name.length > 9
              ? customStatus.name.slice(0, 8) + "..."
              : customStatus.name) || ""}
          </>
        );
      },
      key: "status",
    },
    {
      title: "Created date",
      render: (_, item) => {
        return <span>{moment(item.createdDate).format("YYYY-MM-DD")}</span>;
      },
      key: "createdDate",
    },
    {
      title: "Completed date",
      render: (_, item) => {
        return (
          <span>
            {item.completedDate &&
              moment(item.completedDate).format("YYYY-MM-DD")}
          </span>
        );
      },
      key: "completedDate",
    },
    {
      title: "Due date",
      render: (_, item) => {
        return <span>{item.dates && item.dates.due}</span>;
      },
      key: "due",
    },
    {
      title: "Time Spent",
      render: (_, item) => {
        return (
          <span>
            {item.timelogs &&
              item.timelogs.length > 0 &&
              item.timelogs.reduce((a, b) => {
                return a + b.hours;
              }, 0)}
          </span>
        );
      },
      key: "timelogged",
    },
    /**
     * This should be enabled back in case we need billing type from Wrike
     * along with modifying field on Wrike API request.
     * However, this now requires owner's privilege 
    {
      title: "Billing type",
      dataIndex: "billingType",
      key: "title",
    },
     */
    {
      title: "Failure Root Cause",
      render: (_, item) => {
        const customField = customFields.find((i) => {
          return i.title === "Failure Root Cause";
        });

        const failureType = item.customFields.find((i) => {
          return i.id === customField.id;
        });

        if (failureType) {
          return failureType.value;
        }
      },
      key: "failureType",
    },
    {
      title: "Device",
      render: (_, item) => {
        const customField = customFields.find((i) => {
          return i.title === "Device";
        });

        const device = item.customFields.find((i) => {
          return i.id === customField.id;
        });

        if (device) {
          return device.value;
        }
      },
      key: "device",
    },
    {
      title: "Days to resolution",
      render: (_, item) => {
        if (item.completedDate) {
          return moment(item.completedDate).diff(
            moment(item.createdDate),
            "days"
          );
        }
      },
      key: "title",
    },
  ];

  const trendsColumns = [
    {
      title: "",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Start",
      dataIndex: "start",
      key: "start",
    },
    {
      title: "End",
      dataIndex: "end",
      key: "end",
    },
    {
      title: "Open",
      dataIndex: "opened_tickets",
      key: "open",
    },
    {
      title: "Closed",
      dataIndex: "closed_tickets",
      key: "close",
    },
    {
      title: "Total",
      dataIndex: "total_tickets",
      key: "total",
    },
    {
      title: "Total Commissioned SWRs",
      dataIndex: "total_commissioned",
      key: "total_commissioned",
    },
    {
      title: "Opened tickets per SWR",
      dataIndex: "opened_tickets_per_swr",
      key: "opened_tickets_per_swr",
    },
    {
      title: "Total tickets per SWR",
      dataIndex: "total_tickets_per_swr",
      key: "total_tickets_per_swr",
    },
    {
      title: "Instances of hours logged on a ticket",
      dataIndex: "hours_logged_instances",
      key: "hours_logged_instances",
    },
    {
      title: "Fill rate (right axis, %)",
      dataIndex: "fill_rate",
      key: "fill_rate",
    },
  ];

  const { tasks, total_tasks, trends, stats } = generateTrendData(
    allTasks,
    timelogs,
    customStatusesList
  );

  return (
    <>
      <Card {...CARD_CONFIG_STYLE} title="Customer Support Wrike Tasks">
        <Row gutter={16} justify={"space-around"}>
          <Col span={12}>
            <Card title="" bordered={false}>
              <Row gutter={16} justify={"space-around"}>
                <Col span={3}>
                  <Statistic
                    title="Currently Urgent Open"
                    value={stats.currently_urgent_open}
                  />
                </Col>
                <Col span={3}>
                  <Statistic
                    title="Currently Active"
                    value={stats.currently_active}
                  />
                </Col>
                <Col span={3}>
                  <Statistic
                    title="Currently Monitoring"
                    value={stats.currently_monitoring}
                  />
                </Col>

                <Col span={3}>
                  <Statistic
                    title="Currently On Hold"
                    value={stats.currently_on_hold}
                  />
                </Col>
                <Col span={3}>
                  <Statistic
                    title="Pending Engineering Release"
                    value={stats.pending_engineering_release}
                  />
                </Col>
                <Col span={3}>
                  <Statistic
                    title="Pending Site Visit"
                    value={stats.pending_site_visit}
                  />
                </Col>
                <Col span={3}>
                  <Statistic
                    title="Average time spent per ticket"
                    value={
                      moment
                        .duration(stats.time_spent_on_cs / total_tasks, "hours")
                        .asHours()
                        .toFixed(0) +
                      ":" +
                      moment
                        .duration(stats.time_spent_on_cs / total_tasks, "hours")
                        .minutes() +
                      ":" +
                      moment
                        .duration(stats.time_spent_on_cs / total_tasks, "hours")
                        .seconds()
                    }
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={12}>
            <Card title="" bordered={false}>
              <Row gutter={16} justify={"space-around"}>
                <Col span={4}>
                  <Statistic
                    title="Time spent on CS"
                    value={
                      moment
                        .duration(stats.time_spent_on_cs, "hours")
                        .asHours()
                        .toFixed(0) +
                      ":" +
                      moment
                        .duration(stats.time_spent_on_cs, "hours")
                        .minutes() +
                      ":" +
                      moment.duration(stats.time_spent_on_cs, "hours").seconds()
                    }
                  />
                </Col>
                <Col span={4}>
                  <Statistic title="Total tickets" value={total_tasks} />
                </Col>
                <Col span={4}>
                  <Statistic
                    title="Total tickets time logged against"
                    value={
                      tasks.filter((item) => {
                        return item.timelogs && item.timelogs.length > 0;
                      }).length
                    }
                  />
                </Col>
                <Col span={4}>
                  <Statistic
                    title="Average time per ticket (with time logged against)"
                    value={
                      moment
                        .duration(stats.time_spent_on_cs / total_tasks, "hours")
                        .asHours()
                        .toFixed(0) +
                      ":" +
                      moment
                        .duration(stats.time_spent_on_cs / total_tasks, "hours")
                        .minutes() +
                      ":" +
                      moment
                        .duration(stats.time_spent_on_cs / total_tasks, "hours")
                        .seconds()
                    }
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Tabs defaultActiveKey="1">
          <TabPane tab="Graph" key="1">
            <Row gutter={[4, 4]}>
              <Col span={12}>
                <Card title={"Customer Service Tickets"}>
                  <CustomerServiceTickets data={trends} />
                </Card>
              </Col>
              <Col span={12}>
                <Card title="Count Of Failure Type">
                  <CountOfFailureType
                    data={tasks}
                    customFields={customFields}
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card title="Total tickets per SWR">
                  <TotalTicketsPerSWR data={trends} />
                </Card>
              </Col>
              <Col span={12}>
                <Card title="Days to resolution">
                  <DaysToResolution data={tasks} />
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="KPI" key="2">
            <Table
              dataSource={trends}
              columns={trendsColumns}
              pagination={{
                pageSize: trends.length,
                hideOnSinglePage: true,
              }}
            />
          </TabPane>
          <TabPane tab="Data" key="3">
            <Table
              dataSource={tasks}
              columns={tasksColumns}
              pagination={{
                pageSize: total_tasks,
                hideOnSinglePage: true,
              }}
            />
          </TabPane>
        </Tabs>
      </Card>
    </>
  );
}

const generateStatsData = (tasks, customStatusesList) => {
  return {
    currently_urgent_open: tasks.filter((item) => {
      const customStatus = customStatusesList.find((i) => {
        return i.id === item.customStatusId;
      });
      return customStatus && customStatus.name === "1. URGENT";
    }).length,

    currently_active: tasks.filter((item) => {
      const customStatus = customStatusesList.find((i) => {
        return i.id === item.customStatusId;
      });
      return (
        (customStatus && customStatus.name === "New") ||
        (customStatus && customStatus.name === "1. URGENT") ||
        (customStatus && customStatus.name === "2. IMPORTANT") ||
        (customStatus && customStatus.name === "3. LOW URGENCY") ||
        (customStatus && customStatus.name === "In Progress")
      );
    }).length,

    currently_monitoring: tasks.filter((item) => {
      const customStatus = customStatusesList.find((i) => {
        return i.id === item.customStatusId;
      });
      return customStatus && customStatus.name === "MONITORING";
    }).length,

    currently_on_hold: tasks.filter((item) => {
      const customStatus = customStatusesList.find((i) => {
        return i.id === item.customStatusId;
      });
      return (
        (customStatus && customStatus.name === "HOLD") ||
        (customStatus && customStatus.name === "ON HOLD")
      );
    }).length,

    pending_engineering_release: tasks.filter((item) => {
      const customStatus = customStatusesList.find((i) => {
        return i.id === item.customStatusId;
      });
      return (
        customStatus && customStatus.name === "PENDING ENGINEERING RELEASE"
      );
    }).length,

    pending_site_visit: tasks.filter((item) => {
      const customStatus = customStatusesList.find((i) => {
        return i.id === item.customStatusId;
      });
      return customStatus && customStatus.name === "PENDING SITE VISIT";
    }).length,

    time_spent_on_cs: tasks.reduce((a, b) => {
      return a + b.time_logged;
    }, 0),
  };
};

const generateTrendData = (tasks, timelogs, customStatusesList) => {
  const overallTasks = tasks.map((item) => {
    let newItem = { ...item };
    newItem.date = moment(item.createdDate).format("YYYY-MM-DD");
    newItem.timelogs = filter(timelogs, (i) => {
      return i.taskId === item.id;
    });
    newItem.time_logged = newItem.timelogs.reduce((a, b) => {
      return a + b.hours;
    }, 0);
    newItem.resolution_days = item.completedDate
      ? moment(item.completedDate).diff(moment(item.createdDate), "days")
      : null;

    return newItem;
  });

  return {
    tasks: overallTasks,
    total_tasks: overallTasks.length,
    stats: generateStatsData(overallTasks, customStatusesList),
    trends: generateDashboardData(overallTasks),
  };
};

const generateDashboardData = (tasks) => {
  const fromDate = moment(START_DATE);
  const toDate = moment();

  const range = moment().range(fromDate, toDate);

  const dateData = Array.from(range.by("month")).map((item, index) => {
    const datum = {
      date: item.format("MMM YYYY"),
      start:
        index === 0 ? "2019-10-01" : item.startOf("month").format("YYYY-MM-DD"),
      end: item.endOf("month").format("YYYY-MM-DD"),
      total_commissioned:
        index < TOTAL_COMMISSIONED.length
          ? TOTAL_COMMISSIONED[index]
          : TOTAL_COMMISSIONED[TOTAL_COMMISSIONED.length - 1],
    };

    return datum;
  });

  const dashboardData = dateData.map((item, index) => {
    item.opened_tickets = filter(tasks, (i) => {
      return moment(i.createdDate).isBetween(item.start, item.end);
    }).length;

    item.closed_tickets = filter(tasks, (i) => {
      return moment(i.completedDate).isBetween(item.start, item.end);
    }).length;

    item.total_tickets =
      index === 0
        ? item.opened_tickets - item.closed_tickets
        : dateData[index - 1].total_tickets +
          item.opened_tickets -
          item.closed_tickets;

    item.opened_tickets_per_swr = Number(
      (item.opened_tickets / item.total_commissioned).toFixed(2)
    );

    item.total_tickets_per_swr = Number(
      (item.total_tickets / item.total_commissioned).toFixed(2)
    );

    item.hours_logged_instances = filter(tasks, (i) => {
      return (
        moment(i.createdDate).isBetween(item.start, item.end) &&
        i.timelogs &&
        i.timelogs.length > 0
      );
    }).length;

    item.fill_rate =
      (item.hours_logged_instances / item.opened_tickets) * 100 || 0;

    return item;
  });

  return dashboardData;
};

const CustomerServiceTickets = ({ data }) => {
  const plotData = flatten(
    data.map((item) => {
      return [
        {
          date: item.date,
          type: "opened_tickets",
          value: item.opened_tickets,
        },
        {
          date: item.date,
          type: "closed_tickets",
          value: item.closed_tickets,
        },
        { date: item.date, type: "total_tickets", value: item.total_tickets },
      ];
    })
  );

  const transformData = flatten(
    data.map((item) => {
      return [{ date: item.date, type: "fill_rate", rate: item.fill_rate }];
    })
  );

  const config = {
    data: [plotData, transformData],
    xField: "date",
    yField: ["value", "rate"],
    geometryOptions: [
      {
        geometry: "line",
        seriesField: "type",
        lineStyle: {
          lineWidth: 3,
        },
      },
      {
        geometry: "line",
        seriesField: "type",
        lineStyle: {
          lineWidth: 3,
          lineDash: [5, 5],
        },
      },
    ],

    legend: { position: "top" },
    // smooth: true,
    animation: {
      appear: {
        duration: 5000,
      },
    },
  };
  // #0000ff  fill rate
  // #ff9900 opened
  // #8064a2 close
  // #ff0000 total
  return <DualAxes {...config} />;
};

const CountOfFailureType = ({ data, customFields }) => {
  const failureTypeData = customFields.find((i) => {
    return i.title === "Failure Root Cause";
  });

  const pieData =
    (failureTypeData &&
      failureTypeData.settings.values.map((i) => {
        return {
          title: i,
          value: filter(data, (item) => {
            return item.customFields.find((j) => {
              return j.value === i;
            });
          }).length,
        };
      })) ||
    [];

  const config = {
    appendPadding: 10,
    data: pieData,
    angleField: "value",
    colorField: "title",
    radius: 1,
    label: {
      type: "spider",
      labelHeight: 28,
      content: "{name} {percentage}",
    },
    interactions: [{ type: "pie-legend-active" }, { type: "element-active" }],
    animation: {
      appear: {
        duration: 5000,
      },
    },
  };
  return <Pie {...config} />;
};

const TotalTicketsPerSWR = ({ data }) => {
  const config = {
    data: data,
    padding: "auto",

    xField: "date",
    yField: "total_tickets_per_swr",
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
  };
  return <Line {...config} />;
};

const DaysToResolution = ({ data }) => {
  const config = {
    appendPadding: 10,
    data: data.slice(0),
    xField: "date",
    yField: "resolution_days",
    shape: "circle",
    size: 4,
    yAxis: {
      nice: true,
      line: { style: { stroke: "#aaa" } },
    },
    xAxis: {
      grid: { line: { style: { stroke: "#eee" } } },
      line: { style: { stroke: "#aaa" } },
    },
    animation: {
      appear: {
        duration: 5000,
      },
    },
  };
  return <Scatter {...config} />;
};

export default WrikeTrends;
