import React from "react";

import { Typography } from "antd";

import logo from "../assets/novarc-logo.black.svg";

const { Title } = Typography;

function Error500Page() {
  return (
    <div
      style={{
        textAlign: "center",
        verticalAlign: "middle",
        maxWidth: 400,
        top: "50%",
        left: "50%",
        position: "absolute",
        MsTransform: "translate(-50%, -50%)",
        transform: "translate(-50%, -50%)",
      }}
    >
      <img
        className="logo"
        alt="logo"
        src={logo}
        style={{ height: 80, margin: "12px" }}
      />

      <p>Cloud Web Dashboard for SWR</p>

      <Title level={3} style={{ margin: "2.6em auto" }}>
        Whoops! Something went wrong
      </Title>
    </div>
  );
}
export default Error500Page;
