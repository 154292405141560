import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getGroups,
  getUsers,
  getRobots,
  updateUsage,
  editRoleResource,
  newGroup,
  editGroupResource,
  newTier,
  resendEmail,
  deleteUser,
} from "../../services/api/admin";
import * as FetchingHelper from "../../redux/fetchingHelper";

const sliceName = "admin";

interface AdminState {
  groups: any[] | null;
  roles: any[] | null;
  users: any[] | null;
  robots: any[] | null;
}
const adminSlice = createSlice({
  name: sliceName,
  initialState: {
    groups: null,
    roles: null,
    users: null,
    robots: null,
    ...FetchingHelper.getInitialState(),
  } as AdminState & FetchingHelper.FetchingState,

  reducers: {
    //   currently no standalone or synchronous reducers
  },
  extraReducers: (builder) => {
    builder
      .addCase(...FetchingHelper.createBasicPendingCase(getGroupsList))
      .addCase(
        ...FetchingHelper.createBasicFulfilledCase(getGroupsList, "groups")
      )
      .addCase(...FetchingHelper.createBasicRejectedCase(getGroupsList))
      .addCase(...FetchingHelper.createBasicPendingCase(createGroupOnly))
      .addCase(createGroupOnly.fulfilled, (state, action) => {
        FetchingHelper.completeFetch(state, createGroupOnly.typePrefix);
        // new change for null handling
        if (!state.groups) state.groups = [];
        // carried over from legacy code: action.payload written before unpacking groups
        state.groups = [action.payload, ...state.groups];
      })
      .addCase(...FetchingHelper.createBasicRejectedCase(createGroupOnly))
      .addCase(...FetchingHelper.createBasicPendingCase(updateGroupResource))
      .addCase(updateGroupResource.fulfilled, (state, action) => {
        FetchingHelper.completeFetch(state, updateGroupResource.typePrefix);
        if (!state.groups) state.groups = [];
        state.groups = state.groups.map((group) =>
          action.payload?.data?.id === group.id ? action.payload.data : group
        );
      })
      .addCase(...FetchingHelper.createBasicRejectedCase(updateGroupResource))
      .addCase(...FetchingHelper.createBasicPendingCase(getUsersList))
      .addCase(
        ...FetchingHelper.createBasicFulfilledCase(getUsersList, "users")
      )
      .addCase(...FetchingHelper.createBasicRejectedCase(getUsersList))
      .addCase(...FetchingHelper.createBasicPendingCase(updateRoleResource))
      .addCase(updateRoleResource.fulfilled, (state, action) => {
        FetchingHelper.completeFetch(state, updateRoleResource.typePrefix);
        if (!state.users) state.users = [];
        state.users = state.users.map((user) =>
          action.payload?.data?.id === user.id
            ? {
                ...user,
                ...action.payload.data,
              }
            : user
        );
      })
      .addCase(...FetchingHelper.createBasicRejectedCase(updateRoleResource))
      .addCase(...FetchingHelper.createBasicPendingCase(getAdminRobotsList))
      .addCase(
        ...FetchingHelper.createBasicFulfilledCase(getAdminRobotsList, "robots")
      )
      .addCase(...FetchingHelper.createBasicRejectedCase(getAdminRobotsList))
      .addCase(...FetchingHelper.createBasicPendingCase(createRobotTier))
      .addCase(createRobotTier.fulfilled, (state, action) => {
        FetchingHelper.completeFetch(state, createRobotTier.typePrefix);
        // new change for null handling
        if (!state.robots) state.robots = [];
        // carried over from legacy code:
        state.robots = state.robots.map((item) =>
          item.id === action.payload.data.robot_id
            ? { ...item, tier: { ...action.payload.data } }
            : item
        );
      })
      .addCase(...FetchingHelper.createBasicRejectedCase(createRobotTier))
      .addCase(...FetchingHelper.createBasicPendingCase(updateRobotTier))
      .addCase(updateRobotTier.fulfilled, (state, action) => {
        FetchingHelper.completeFetch(state, updateRobotTier.typePrefix);
        // new change for null handling
        if (!state.robots) state.robots = [];
        // carried over from legacy code:
        // this does the exact same thing as createRobotTierSuccess
        state.robots = state.robots.map((item) =>
          item.id === action.payload.data.robot_id
            ? { ...item, tier: { ...action.payload.data } }
            : item
        );
      })
      .addCase(...FetchingHelper.createBasicRejectedCase(updateRobotTier));
  },
});

export default adminSlice.reducer;
export const getGroupsList = createAsyncThunk(
  `${sliceName}/fetchingGroupsList`,
  async () => getGroups()
);
export const createGroupOnly = createAsyncThunk<
  { users; robots } & any,
  Object
>(`${sliceName}/creatingGroup`, async (payload) => {
  const { data } = await newGroup(payload);
  return { ...data, users: null, robots: null };
});
export const createGroup = (payload) => async (dispatch) => {
  try {
    await dispatch(createGroupOnly(payload));
    await dispatch(getGroupsList());
  } catch (err) {
    console.error("unexpected error in createGroup", err);
  }
};
createGroup.typePrefix = createGroupOnly.typePrefix;
export const updateGroupResource = createAsyncThunk<any, { id; requestData }>(
  `${sliceName}/updatingGroupResource`,
  async ({ id, requestData }) => editGroupResource(id, requestData)
);

export const getUsersList = createAsyncThunk(
  `${sliceName}/fetchingUsersList`,
  async () => getUsers()
);

export const resendWelcomeEmail = (id) => async (dispatch) => {
  try {
    await resendEmail(id);
  } catch (err) {
    console.error("resend welcome email error", err);
  }
};

export const removeUser = (id) => async (dispatch) => {
  try {
    await deleteUser(id);
    await dispatch(getUsersList());
  } catch (err) {
    console.error("removeUser", err);
  }
};
export const updateRoleResource = createAsyncThunk<any, { id; requestData }>(
  `${sliceName}/fetchingRolesList`,
  async ({ id, requestData }) => {
    await editRoleResource(id, requestData);
    return {
      data: {
        id: id,
        role: getRoleById(requestData.role_id),
      },
    };
  }
);

export const getAdminRobotsList = createAsyncThunk(
  `${sliceName}/fetchingRobotsList`,
  async () => {
    // for this endpoint, api does not wrap the response array in a "data" key
    // so we are adding one manually to normalize before sending to reducer
    return { data: await getRobots() };
  }
);

export const createRobotTier = createAsyncThunk(
  `${sliceName}/creatingRobotTier`,
  async (id) => newTier(id)
);
export const updateRobotTier = createAsyncThunk<any, { id; requestData }>(
  `${sliceName}/updatingRobotTier`,
  async ({ id, requestData }) => updateUsage(id, requestData)
  // not sure what this comment means but it was carried over, payload -> requestData

  // const payload = {
  //   tier: {}, // goes to // put users/robots/robot_id/bandwidth
  //   // tier, capacity only

  //   groups, // goes to group management
  //   customer, location // goes to other
  // };
);
function getRoleById(id) {
  switch (id) {
    case 1:
      return "admin";
    case 2:
      return "support";
    case 3:
      return "customer";
    case 4:
      return "demo";
    case 5:
      return "analyzer";
    case 6:
      return "supervisor";
    default:
      return;
  }
}
