import React from "react";
import RobotNavigation from "../features/robot/RobotNavigation";
import RobotSupport from "../features/robot/RobotSupport";

export default function SupportPage(props) {
    return (
        <>
            <RobotNavigation {...props} />
            <RobotSupport />
        </>
    );
}
