import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getProgramData } from "../../services/api/robot";

import { novDataDailyReport } from "../../libs/utils";
import { removeUndefinedKeys } from "../../libs/utilsTyped";
import * as FetchingHelper from "../../redux/fetchingHelper";

const sliceName = "reports";

const reportSlice = createSlice({
  name: sliceName,
  initialState: {
    ...FetchingHelper.getInitialState(),
    reports: null,
    pdfCurrentPage: 0,
    pdfTotalPages: 0,
  },

  reducers: {
    pdfLoad: (state, action) => ({
      ...state,
      pdfCurrentPage: 1,
      pdfTotalPages: action.payload,
    }),

    pdfNextPage: (state) => ({
      ...state,
      pdfCurrentPage: Math.min(state.pdfCurrentPage + 1, state.pdfTotalPages),
    }),

    pdfPrevPage: (state) => ({
      ...state,
      pdfCurrentPage: Math.max(state.pdfCurrentPage - 1, 1),
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(...FetchingHelper.createBasicPendingCase(getRobotReports))
      .addCase(
        ...FetchingHelper.createBasicFulfilledCase(getRobotReports, "reports")
      )
      .addCase(...FetchingHelper.createBasicRejectedCase(getRobotReports));
  },
});

export const { pdfLoad, pdfNextPage, pdfPrevPage } = reportSlice.actions;

export default reportSlice.reducer;

export const getRobotReports = createAsyncThunk<
  any,
  { id: string; start?: string; end?: string }
>(`${sliceName}/getRobotReports`, async ({ id, start, end }) => {
  const query = removeUndefinedKeys(
    {
      key: "daily_reports",
      start,
      end,
    },
    ["start", "end"]
  );
  const { data } = await getProgramData(id, query);
  const reports = novDataDailyReport(data);
  return { data: reports };
});
