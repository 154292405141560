import React, { useState } from "react";

import { Button, Col, Dropdown, Empty, Menu, Row, Tabs } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { Bar } from "@ant-design/charts";

import { isEmpty, sortBy, sumBy } from "lodash";
import moment from "moment";
import { isStagingRobotInProd } from "../../libs/utils";
import { useLoadingWithProgress } from "../../libs/useLoading";
import { getRobotInfo, getRobotsList } from "./robotSlice";

const { TabPane } = Tabs;

const filterData = [
  {
    key: 1,
    title: "Yesterday",
    value: 1,
    date: moment().subtract(1, "days"),
  },
  {
    key: 2,
    title: "Last 7 days",
    value: 7,
    date: moment().subtract(7, "days"),
  },
  {
    key: 3,
    title: "Last 30 days",
    value: 30,
    date: moment().subtract(30, "days"),
  },
];

export default function RobotTopPerformance() {
  const [[robots, robotList, info], progress] = useLoadingWithProgress(
    "robot",
    ({ robots, index, info }) => [robots, index, info],
    [getRobotsList, getRobotInfo]
  );
  const [dropdownKey, setDropdownKey] = useState(2);

  const today = moment().format("YYYY-MM-DD");

  const start = filterData
    .find((item) => item.key === dropdownKey)
    .date.format("YYYY-MM-DD");

  const data = robotList
    .map((id) => {
      const robot = robots[id];
      const robotInfo = info[id];
      const isValidRobot = !isStagingRobotInProd(robot);
      const isValidDate =
        robotInfo?.summary &&
        (moment(robotInfo?.summary?.date).isSameOrAfter(start) ||
          robotInfo?.summary?.date === today);
      const hasSummaryData =
        robotInfo?.summary?.date &&
        (!isEmpty(robotInfo?.summary?.data?.[1]) ||
          !isEmpty(robotInfo?.summary?.data?.[2]));

      if (isValidRobot && isValidDate && hasSummaryData) {
        return {
          unit: robot.name,
          hours:
            sumBy(
              robotInfo.summary.data[1].weld_time_per_day_in_month,
              (item) => {
                if (
                  moment(item.date).isSameOrAfter(start) ||
                  item.date === today
                )
                  return moment.duration(item.sum);
              }
            ) || 0,
          inches:
            sumBy(
              robotInfo.summary.data[2].weld_inches_per_day_in_month,
              (item) => {
                if (
                  moment(item.date).isSameOrAfter(start) ||
                  item.date === today
                )
                  return item.sum;
              }
            ) || 0,
        };
      } else {
        return null;
      }
    })
    .filter((item) => item && (item.hours || item.inches));

  const weldHoursData = sortBy([...data], (item) => item.hours)
    .reverse()
    .slice(0, 5);

  const weldInchesData = sortBy([...data], (item) => item.inches)
    .reverse()
    .slice(0, 5);

  return (
    <>
      <Tabs
        className="Blank_active_tab"
        defaultActiveKey="1"
        size={"small"}
        type="card"
        tabBarExtraContent={
          <Dropdown
            overlay={
              <Menu>
                {filterData.map((item) => (
                  <Menu.Item
                    key={item.key}
                    onClick={() => {
                      setDropdownKey(item.key);
                    }}
                  >
                    {item.title}
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <Button size={"small"}>
              {filterData.find(({ key }) => key === dropdownKey).title}
              <CaretDownOutlined />
            </Button>
          </Dropdown>
        }
      >
        <TabPane tab={null} key="1" style={{ height: 200 }}>
          <Row>
            <Col span={12}>
              <>
                <b>Top Weld Hours</b>
                <div>
                  {weldHoursData.length > 0 ? (
                    <Bar
                      {...{
                        data: weldHoursData,
                        xField: "hours",
                        yField: "unit",
                        color: "#1890ff",
                        legend: false,
                        height: 150,
                        barWidthRatio: 0.9,
                        maxBarWidth: 20,
                        xAxis: {
                          label: {
                            formatter: function formatter(v) {
                              return moment.duration(v).asHours().toFixed(1);
                            },
                          },
                        },
                        tooltip: {
                          formatter: (datum) => {
                            return {
                              name: datum.unit,
                              value:
                                datum.hours &&
                                moment
                                  .duration(datum.hours)
                                  .asHours()
                                  .toFixed(1) + " hours",
                            };
                          },
                        },
                      }}
                    />
                  ) : (
                    <Empty />
                  )}
                </div>
              </>
            </Col>
            <Col span={12}>
              <>
                <b>Top Weld Inches (FDI)</b>
                <div>
                  {weldInchesData.length > 0 ? (
                    <Bar
                      {...{
                        data: weldInchesData,
                        xField: "inches",
                        yField: "unit",
                        color: "#13c2c2",
                        legend: false,
                        height: 150,
                        barWidthRatio: 0.9,
                        maxBarWidth: 20,
                        xAxis: {
                          label: {
                            formatter: function formatter(v) {
                              return v;
                            },
                          },
                        },
                        tooltip: {
                          formatter: (datum) => {
                            return {
                              name: datum.unit,
                              value:
                                datum.inches &&
                                datum.inches.toFixed(1) + " inches",
                            };
                          },
                        },
                      }}
                    />
                  ) : (
                    <Empty />
                  )}
                </div>
              </>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </>
  );
}
