import React from "react";

import PasswordReset from "../features/auth/PasswordReset";
import Error500Page from "./Error500Page";

import { isLoggedIn } from "../libs/auth";

function PasswordChangePage() {
  return isLoggedIn ? (
    <PasswordReset isAuthenticated={isLoggedIn} />
  ) : (
    <Error500Page />
  );
}

export default PasswordChangePage;
