import React from "react";

import RobotNavigation from "../features/robot/RobotNavigation";
import VideosList from "../features/videos/VideosList";

function VideosPage() {
  return (
    <>
      <RobotNavigation />
      <VideosList />
    </>
  );
}

export default VideosPage;
