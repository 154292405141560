import { combineReducers } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";

import admin from "../features/admin/adminSlice";

import auth from "../features/auth/authSlice";
import robot from "../features/robot/robotSlice";
import alarms from "../features/alarms/alarmSlice";
import syncInfo from "../features/alarms/syncSlice";

import videos from "../features/videos/videoSlice";
import novEyeLogs from "../features/videos/g3LogsSlice";

import reports from "../features/reports/reportSlice";
import novDataLogs from "../features/reports/ndLogsSlice";

import wrike from "../features/wrike/wrikeSlice";
import performance from "../features/performance/performanceSlice";

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),

    admin,
    auth,
    alarms,
    syncInfo,

    robot,
    videos,
    reports,

    noveye_logs: novEyeLogs,
    novdata_logs: novDataLogs,

    wrike,
    performance,
  });

const rootReducer = (history) => (state, action) => {
  if (action.type === "auth/logout") {
    return appReducer(history)(undefined, action);
  }
  return appReducer(history)(state, action);
};

export default rootReducer;
