import { useDispatch } from "react-redux";
import React, { useMemo } from "react";
import { push } from "connected-react-router";
import { Marker, Tooltip } from "react-leaflet";
import L from "leaflet";

interface Props {
  robotName:string;
  coordinates:[number,number];
  customer:string;
  location:string;
}
export function MapToolTip(props: Props) {
  const { robotName, coordinates, customer, location } = props;

  const dispatch = useDispatch();
  const eventHandlers = useMemo(
    () => ({
      click() {
        dispatch(push(`/${robotName}/health`));
      },
    }),
    [dispatch, robotName]
  );

  return (
    <Marker
      eventHandlers={eventHandlers}
      position={coordinates}
      // @ts-ignore
      icon={L.divIcon({
        html: "<div><span></span></div>",
        className: "marker-cluster-small",
        iconSize: L.point(40, 40, true),
      })}
    >
      <Tooltip>
        {robotName} <br /> {customer} <br /> {location}
      </Tooltip>
    </Marker>
  );
}
