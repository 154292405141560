import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Table, Tag } from "antd";

import { filter } from "lodash";
import moment from "moment";

import LoadingPage from "../../components/LoadingPage";

import { WRIKE_TASK_PARAMS } from "../../libs/constants";
import { getWrikeDataList } from "../../libs/utils";

import { getSubTasks } from "./wrikeSlice";

function TicketsList(props) {
  const dispatch = useDispatch();

  const workflows = useSelector((state) => state.wrike.workflows);
  const subTickets = useSelector((state) => state.wrike.subtasks);

  const { tasks: tickets } = props;

  if (!(tickets && workflows)) {
    return <LoadingPage />;
  }

  let customStatusesList = [];
  workflows.map((item) => {
    customStatusesList = customStatusesList.concat(item.customStatuses);
    return item;
  });

  const columns = [
    {
      title: "Unit",
      key: "unit",
      dataIndex: "unit",
      width: "12%",
      sorter: { compare: (a, b) => b.unit - a.unit },
    },
    {
      title: "Title",
      key: "title",

      render: (_, item) => {
        return (
          <a
            href={item.permalink}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "black" }}
          >
            {item.title}
          </a>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      width: "23%",
      sorter: {
        compare: (a, b) => {
          const customStatusA = customStatusesList.find((i) => {
            return i.id === a.customStatusId;
          });
          const customStatusB = customStatusesList.find((i) => {
            return i.id === b.customStatusId;
          });

          if (customStatusA.name > customStatusB.name) {
            return 1;
          }
          if (customStatusA.name < customStatusB.name) {
            return -1;
          }
          return 0;
        },
        multiple: 2,
      },
      render: (_, item) => {
        // const currentHeadingKey = Object.keys(workflows).find((section) => {
        //   return workflows[section].customStatuses.some((i) => {
        //     return i.id === item.customStatusId;
        //   });
        // });
        // const currentHeading =
        //   currentHeadingKey != null ? workflows[currentHeadingKey] : null;

        const customStatus = customStatusesList.find((i) => {
          return i.id === item.customStatusId;
        });
        return (
          <>
            <Tag
              style={{ padding: 4, marginRight: 4 }}
              color={customStatus?.color?.toLowerCase?.() || "black"}
            >
              {/* {(customStatus && customStatus.name) || ""} */}
            </Tag>
            {(customStatus && customStatus.name && customStatus.name.length > 9
              ? customStatus.name.slice(0, 8) + "..."
              : customStatus.name) || ""}
          </>
        );
      },
    },
    {
      title: "Created date",
      key: "createdDate",
      width: "25%",
      sorter: {
        compare: (a, b) => {
          const aDate = new Date(a.createdDate);
          const bDate = new Date(b.createdDate);

          if (aDate > bDate) {
            return 1;
          }
          if (aDate < bDate) {
            return -1;
          }
          return 0;
        },
        multiple: 1,
      },
      render: (_, item) => {
        return (
          <span>
            {item.createdDate && moment(item.createdDate).format("YYYY-MM-DD")}
          </span>
        );
      },
    },

    // {
    //   title: "Start Date",
    //   key: "startDate",
    //   render: (_, item) => {
    //     return (
    //       <span>
    //         {item.dates &&
    //           item.dates.start &&
    //           moment(item.dates.start).format("YYYY-MM-DD")}
    //       </span>
    //     );
    //   },
    // },
    // {
    //   title: "Due Date",
    //   key: "dueDate",
    //   render: (_, item) => {
    //     return (
    //       <span>
    //         {item.dates &&
    //           item.dates.due &&
    //           moment(item.dates.due).format("YYYY-MM-DD")}
    //       </span>
    //     );
    //   },
    // },
    // {
    //   title: "Duration",
    //   key: "duration",
    //   render: (_, item) => {
    //     return (
    //       <span>
    //         {item.dates && item.dates.duration
    //           ? item.dates.duration / 480 + "d"
    //           : ""}
    //       </span>
    //     );
    //   },
    // },
    // {
    //   title: "Allocated Hours",
    //   dataIndex: "allocatedHours",
    //   key: "allocatedHours",
    // },
    // {
    //   title: "Status",
    //   key: "customStatus",
    //   render: (_, item) => {
    //     const status = filter(
    //       workflows,
    //       flow(
    //         property("customStatuses"),
    //         partialRight(some, { id: item.customStatusId })
    //       )
    //     );

    //     return <span>{status.name}</span>;
    //   },
    // },
    // { title: "Assignee", dataIndex: "createdAt", key: "createdAt" },
    // {
    //   title: "Link",

    //   key: "link",
    //   render: (_, item) => {
    //     return (
    //       <a href={item.permalink} target="_blank">
    //         Check in Wrike
    //       </a>
    //     );
    //   },
    // },
  ];

  const expandedRowRender = (item) => {
    if (!(subTickets && subTickets[item.id])) {
      return <LoadingPage />;
    } else {
      return (
        <Table
          columns={columns}
          size={"small"}
          dataSource={
            (subTickets && getWrikeDataList(subTickets[item.id])) || []
          }
          pagination={false}
        />
      );
    }
  };

  const onExpand = (expanded, item) => {
    dispatch(
      getSubTasks(
        item.id,
        `/tasks/${item.subTaskIds.toString()}`,
        WRIKE_TASK_PARAMS
      )
    );
  };

  const rowExpandable = (item) => item.subTaskIds && item.subTaskIds.length > 0;

  const columnWidth = () => "20px";

  return (
    <Table
      className="Wrike_TaskLists"
      pagination={{ pageSize: tickets.length, hideOnSinglePage: true }}
      scroll={{ x: "fit-content", y: 180 }}
      columns={columns}
      size={"small"}
      expandable={{
        indentSize: 0,
        columnWidth,
        rowExpandable,
        onExpand,
        expandedRowRender,
      }}
      dataSource={filter(tickets, (item) => {
        if (item.status === "Active") {
          return item;
        }
      })}
    />
  );
}

export default TicketsList;
