import React, { useEffect } from "react";

import { Table } from "antd";

import { getLegacySwrInformation } from "./robotSlice";
import { useDispatch, useSelector } from "react-redux";

const dataKeys = [
  "Power Source",
  "Positioners",
  "Wire Feeder MPN",
  "Spool or Drum Wire Dispensing",
];

export default function RobotDetail(props) {
  const { robotId } = props;
  const swr_information = useSelector(
    (state) => state.robot.legacySwrInfo[robotId]?.swr_information
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLegacySwrInformation([robotId]));
  }, [dispatch, robotId]);

  const columns = [
    { title: "Type", dataIndex: "name", key: "name", width: "50%" },
    { title: "Model / Name", dataIndex: "value", key: "value", width: "25%" },
    { title: "Note", dataIndex: "comment", key: "comment", width: "25%" },
  ];

  const dataSource = (swr_information ?? []).filter((item) =>
    dataKeys.includes(item.name)
  );
  const pagination = { hideOnSinglePage: true, pageSize: dataSource.length };
  return (
    <Table
      className="SWRDetailList_Table"
      // style={{ height: 333 }}
      columns={columns}
      scroll={{ x: "fit-content", y: 300 }}
      expandable={{
        rowExpandable: (record) => !!record.children,
      }}
      dataSource={dataSource}
      pagination={pagination}
    />
  );
}
