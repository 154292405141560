/**
 * This module renders an in-browser preview of an existing PDF file
 * This uses lib "react-pdf" (https://github.com/wojtekmaj/react-pdf/blob/v5.x/README.md)
 * Not to be confused with "@react-pdf/renderer" (https://github.com/diegomura/react-pdf)
 */

import React from "react";
import { Document, Page } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import { pdfLoad, pdfNextPage, pdfPrevPage } from "./reportSlice";

export default function PreviewPDF({ item }) {
    const state = useSelector((state)=> state.reports);
    const dispatch = useDispatch();

    function onDocumentLoadSuccess({ numPages }) {
        dispatch(pdfLoad(numPages));
    }

    function previousPage() {
        dispatch(pdfPrevPage());
    }

    function nextPage() {
        dispatch(pdfNextPage());
    }

    return (
        <div className="pdf-preview pdf-preview-container">
            <Document
                file={item.download}
                renderMode="svg"
                onLoadSuccess={onDocumentLoadSuccess}
                className="pdf-preview"
            >
                <Page
                    pageNumber={state.pdfCurrentPage}
                    renderTextLayer={false}
                />
            </Document>
            {/* <p>
                Page {state.currentPage} of {state.pages}
            </p> */}
        </div>
    );
}
