import React from "react";

import Settings from "../features/auth/Settings";
import Error500Page from "./Error500Page";

import { isLoggedIn } from "../libs/auth";

function SettingsPage() {
  return isLoggedIn ? (
    <Settings isAuthenticated={isLoggedIn} />
  ) : (
    <Error500Page />
  );
}

export default SettingsPage;
