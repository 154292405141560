import React from "react";

import { Statistic } from "antd";

import { Area } from "@ant-design/charts";

function TrendStatistics(props) {
  const { title, value, valueKey, color, ...config } = props;

  return (
    <>
      <Statistic
        title={title}
        value={value || config.data.reduce((a, b) => a + b[valueKey], 0) || 0}
        valueStyle={{ color: color }}
      />
      {/* TODO: Switch to TinyArea for simpler design but require more tweaks on the code */}
      <Area
        style={{ height: 60 }}
        {...{
          ...config,
          line: {
            size: 1,
            color: color,
          },
          areaStyle: { fill: color },
          xAxis: {
            label: null,
          },
          yAxis: {
            label: null,
            grid: {
              line: {
                style: null,
              },
            },
          },
        }}
      />
    </>
  );
}

export default TrendStatistics;
