import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { push } from "connected-react-router";

import {
  Button,
  Card,
  Col,
  Divider,
  Modal,
  Row,
  Skeleton,
  Typography,
} from "antd";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";

import { chain } from "lodash";
import Moment from "moment";
import { extendMoment } from "moment-range";

import {
  CARD_CONFIG_STYLE,
  CUSTOMER_MAINTENANCE_WRIKE_ID,
  CUSTOMER_SUPPORT_WRIKE_ID,
  LOADING_ANIMATION_SIZE_FULL,
  LOADING_ANIMATION_SIZE,
  WRIKE_TASK_PARAMS,
} from "../../libs/constants";

import {
  GetDateRange,
  convertInchesTomm,
  getWrikeDataList,
  normalizeRobotId,
} from "../../libs/utils";

import LoadingPage from "../../components/LoadingPage";
import Error404Page from "../../components/Error404Page";

import PickerWithType from "../../libs/components/PickerWithType";
import SelectDateType from "../../libs/components/SelectDateType";

import { PDFDownload, PDFPreview } from "../reports/GeneratePDFReports";

import {
  RobotWeldInches,
  RobotWeldHours,
  RobotWeldOperators,
} from "./RobotPerformance";
import { RobotWeldActivity } from "./RobotWeldHistory";
import RobotStatsCard from "./RobotStatsCard";

import {
  getRobotWeld,
  getRobotLastWeldDone,
  getRobotTier,
  getRobotWeldHoursList,
  getRobotWeldInchesList,
  getRobotOperatorsWeldHoursList,
  getRobotOperatorsWeldInchesList,
  getRobotTimezone,
} from "./robotSlice";
import StatusBanner from "../alarms/StatusBanner";
import RobotScheduledEvents from "./RobotScheduledEvents";
import {
  getCustomStatuses,
  getTickets,
  getUpcomingEvents,
} from "../wrike/wrikeSlice";
import RobotDetail from "./RobotDetail";
import TicketsList from "../wrike/TicketsList";
import TicketsStatistics from "../wrike/TicketsStatistics";
import { generateCsvReports } from "../reports/generateCsvReports";
import { useLoadingWithProgress } from "../../libs/useLoading";

const moment = extendMoment(Moment);

const HOURS_IN_SHIFT = 8;
const LOADING_DELAY_IN_MS = 1500;

const activeButtonStyle = {
  color: "#40a9ff",
  borderColor: "#40a9ff",
};

function RobotAnalytics(props) {
  const { isCustomer } = props;
  const { robotName } = useParams();
  const robotId = normalizeRobotId(robotName);

  const [[robotWeldRun, robotLastWeldRun, robotTier, robotTimezone], progress] =
    useLoadingWithProgress(
      "robot",
      ({ weldRun, lastWeldRun, tier, timezone }) => [
        weldRun?.[robotId],
        lastWeldRun?.[robotId],
        tier?.[robotId],
        timezone?.[robotId],
      ],
      [getRobotWeld, getRobotLastWeldDone, getRobotTier, getRobotTimezone]
    );
  const [
    [
      robotWeldHours,
      robotWeldInches,
      robotWeldHoursOperators,
      robotWeldInchesOperators,
    ],
    chartsProgress,
  ] = useLoadingWithProgress(
    "robot",
    ({
      weldRun,
      lastWeldRun,
      tier,
      weldHours,
      weldInches,
      weldHoursOperators,
      weldInchesOperators,
    }) => [
      weldHours?.[robotId],
      weldInches?.[robotId],
      weldHoursOperators?.[robotId],
      weldInchesOperators?.[robotId],
    ],
    [
      getRobotWeldHoursList,
      getRobotWeldInchesList,
      getRobotOperatorsWeldHoursList,
      getRobotOperatorsWeldInchesList,
    ]
  );

  const tickets = useSelector((state) => state.wrike.tasks ?? []);
  const events = useSelector((state) => state.wrike.events);

  const today = robotTimezone ? moment().tz(robotTimezone) : moment();
  const yesterday = today.clone().subtract(1, "days");
  const lastWeek = today.clone().subtract(7, "days");
  const last30Days = today.clone().subtract(30, "days");

  const last24Hours = {
    start: yesterday.format("YYYY-MM-DD"),
    end: today.format("YYYY-MM-DD"),
  };
  const filterData = [
    {
      key: 1,
      title: "Last 24 hours",
      value: 1,
      date: yesterday.clone(),
    },
    {
      key: 2,
      title: "Last 7 days",
      value: 7,
      date: lastWeek.clone(),
    },
    {
      key: 3,
      title: "Last 30 days",
      value: 30,
      date: last30Days.clone(),
    },
  ];

  // dateValues is moment instance
  const [dateValues, setDateValues] = useState([lastWeek, today]);
  const [type, setType] = useState("range");

  // dateRange is day string
  const dateRange = GetDateRange(type, dateValues);
  const [start, end] = dateRange;

  const [filterButtonKey, setFilterButtonKey] = useState(2); // default view as last 7 days

  const [openModal, setOpenModal] = useState(null);

  const [loadingDelay, setLoadingDelay] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRobotTier(robotId));

    //TODO: can this one be merged into the same daterange picker?
    dispatch(
      getRobotLastWeldDone({
        id: robotId,
        ...last24Hours,
      })
    );

    // this component expects date to always be defined
    // when date is empty (canceled selection in datepicker) do not reload
    if (!start || !end) {
      return;
    }

    dispatch(getRobotWeldHoursList({ id: robotId, start, end }));
    dispatch(getRobotWeldInchesList({ id: robotId, start, end }));
    dispatch(getRobotOperatorsWeldHoursList({ id: robotId, start, end }));
    dispatch(getRobotOperatorsWeldInchesList({ id: robotId, start, end }));
    dispatch(getRobotWeld({ id: robotId, start, end }));
    dispatch(getRobotTimezone({ id: robotId }));
  }, [dispatch, robotId, start, end]);

  useEffect(() => {
    if (!isCustomer) {
      dispatch(
        getTickets(
          `folders/${CUSTOMER_SUPPORT_WRIKE_ID}/tasks`,
          WRIKE_TASK_PARAMS
        )
      );
      dispatch(
        getUpcomingEvents(
          `folders/${CUSTOMER_MAINTENANCE_WRIKE_ID}/tasks`,
          WRIKE_TASK_PARAMS
        )
      );
      dispatch(getCustomStatuses());
    }
  }, [dispatch, isCustomer]);

  useEffect(() => {
    setTimeout(() => {
      if (loadingDelay) {
        setLoadingDelay(false);
      }
    }, LOADING_DELAY_IN_MS);
  }, [loadingDelay]);

  useEffect(() => {
    if(robotTimezone){
      setDateValues([lastWeek,today])
    }
  }, [robotTimezone]);

  if (!robotId) {
    return <Error404Page />;
  }

  if (!progress.isFinished()) {
    return (
      <LoadingPage size={LOADING_ANIMATION_SIZE_FULL} progress={progress} />
    );
  }

  const isLite = robotTier?.tier === "lite";
  const isEssential = robotTier?.tier === "basic";

  let range = [];
  if (start && end) {
    //warning - never attempt to call moment.range with null values, it will hang
    range = Array.from(moment.range(start, end).by("day"));
  }

  const filteredWrike = isCustomer
    ? null
    : getWrikeDataList(tickets).filter((ticket) => robotId === ticket.unit);

  const isMetric = robotWeldRun?.slice(-1)[0]?.units_system === "metric";

  return (
    <div className="analytics-page">
      {isCustomer && (
        <>
          <StatusBanner
            robotName={robotName}
            weldData={robotLastWeldRun}
            showLink={
              <span style={{ fontSize: 14 }}>
                View details in{" "}
                <span
                  style={{
                    cursor: "pointer",
                    color: "#1890FF",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    dispatch(push(`/${robotName}/health`));
                  }}
                >
                  Health
                </span>
              </span>
            }
            dateRange={last24Hours}
          />
          <RobotStatsCard robotId={robotId} />
          <Divider />
        </>
      )}

      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: 8,
          paddingRight: 8,
        }}
      >
        <Typography.Title style={{ color: "#082145" }} level={2}>
          Performance
        </Typography.Title>

        {isCustomer && isLite && (
          <div>
            <Button
              onClick={() => {
                setLoadingDelay(true);
                setDateValues([filterData[0].date, today]);
                setFilterButtonKey(1);
              }}
              style={filterButtonKey === 1 ? activeButtonStyle : {}}
            >
              {filterData.find((item) => item.key === 1).title}
            </Button>
            <Button
              onClick={() => {
                setLoadingDelay(true);
                setDateValues([filterData[1].date, today]);
                setFilterButtonKey(2);
              }}
              style={filterButtonKey === 2 ? activeButtonStyle : {}}
            >
              {filterData.find((item) => item.key === 2).title}
            </Button>
            <Button
              onClick={() => {
                setLoadingDelay(true);
                setDateValues([filterData[2].date, today]);
                setFilterButtonKey(3);
              }}
              style={filterButtonKey === 3 ? activeButtonStyle : {}}
            >
              {filterData.find((item) => item.key === 3).title}
            </Button>
          </div>
        )}
        {(!isCustomer || isEssential) && (
          <div className="Analytics_Date_Picker" style={{ textAlign: "right" }}>
            {/*<SelectDateType value={type} onChange={setType}></SelectDateType>*/}
            <PickerWithType
              type={type}
              defaultValue={dateValues}
              lastSelectableDay={today.format("YYYY-MM-DD")}
              onChange={(value) => {
                setLoadingDelay(true);
                setDateValues(value);
              }}
            />
          </div>
        )}
      </Row>

      <Row gutter={[16, 16]} style={{ padding: 8 }}>
        <Col span={8}>
          <Card {...CARD_CONFIG_STYLE} title="Weld Inches">
            {chartsProgress.isSuccessful() ? (
              <RobotWeldInches weldData={robotWeldInches} range={range} />
            ) : (
              <Skeleton.Node
                active={true}
                style={{ height: 350, width: "100%", background: "white" }}
              >
                <LoadingPage size={LOADING_ANIMATION_SIZE} />
              </Skeleton.Node>
            )}
          </Card>
        </Col>
        <Col span={8}>
          <Card {...CARD_CONFIG_STYLE} title="Arc On Time">
            {chartsProgress.isSuccessful() ? (
              <RobotWeldHours weldData={robotWeldHours} range={range} />
            ) : (
              <Skeleton.Node
                active={true}
                style={{ height: 350, width: "100%", background: "white" }}
              >
                <LoadingPage size={LOADING_ANIMATION_SIZE} />
              </Skeleton.Node>
            )}
          </Card>
        </Col>
        <Col span={8}>
          <Card {...CARD_CONFIG_STYLE} title="Operator Activity">
            {chartsProgress.isSuccessful() && robotWeldInchesOperators ? (
              <RobotWeldOperators
                weldInches={robotWeldInchesOperators.filter(
                  (i) => i.id === Number(robotId)
                )}
                weldHours={robotWeldHoursOperators.filter(
                  (i) => i.robot_id === Number(robotId)
                )}
              />
            ) : (
              <Skeleton.Node
                active={true}
                style={{ height: 350, width: "100%", background: "white" }}
              >
                <LoadingPage size={LOADING_ANIMATION_SIZE} />
              </Skeleton.Node>
            )}
          </Card>
        </Col>
      </Row>

      <Divider />

      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: 8,
          paddingRight: 8,
        }}
      >
        <Typography.Title style={{ color: "#082145" }} level={2}>
          Activity
        </Typography.Title>

        {(!isCustomer || isEssential) && (
          <div className="weld-activity-download-buttons">
            <Button
              icon={<DownloadOutlined />}
              onClick={() => {
                setOpenModal(true);
              }}
            >
              PDF
            </Button>
            <Button
              icon={<DownloadOutlined />}
              onClick={() => {
                const range = dateValues
                  ? GetDateRange(type, dateValues)
                  : [lastWeek.format("YYYY-MM-DD"), today.format("YYYY-MM-DD")];

                // weldRun should be the same as PdfModal weldReportData
                const data = generateCsvReports(robotWeldRun);
                const dataStr =
                  "data:text/plain;charset=utf-8," + encodeURIComponent(data);
                const a = document.createElement("a");
                a.setAttribute("href", dataStr);
                a.setAttribute(
                  "download",
                  `weld activity ${range[0]} - ${range[1]}.csv`
                );
                document.body.appendChild(a);
                a.click();
                a.remove();
              }}
            >
              Excel
            </Button>
          </div>
        )}
      </Row>
      <Row gutter={[4, 4]}>
        <Col span={24}>
          <Card
            {...CARD_CONFIG_STYLE}
            title="Weld History"
            className="WeldHistory_Card"
          >
            <RobotWeldActivity weldRun={robotWeldRun} isMetric={isMetric} />
            {(!isCustomer || isEssential) && robotWeldRun?.length && (
              <PdfModal
                weldRun={robotWeldRun}
                openModal={openModal}
                robotName={robotName}
                type={type}
                dateValues={
                  dateRange || [
                    lastWeek.format("YYYY-MM-DD"),
                    today.format("YYYY-MM-DD"),
                  ]
                }
                onModalClose={() => {
                  setOpenModal(false);
                }}
              />
            )}
          </Card>
        </Col>
      </Row>
      {!isCustomer && (
        <>
          <Divider />
          <Typography.Title style={{ color: "#082145" }} level={2}>
            CS Request and Maintenance
          </Typography.Title>
          <Row gutter={[16, 16]} style={{ padding: 8 }}>
            <Col span={12}>
              <Card
                {...CARD_CONFIG_STYLE}
                title="Detail Information"
                className="support-robot-detail"
              >
                <RobotDetail robotId={robotId} />
              </Card>
            </Col>
            <Col span={12}>
              <Card
                {...CARD_CONFIG_STYLE}
                title="Preventative Maintenance Checklist"
                style={{ height: "100%" }}
              >
                <RobotScheduledEvents
                  events={events}
                  style={{ height: 272, overflowY: "scroll", paddingTop: 10 }}
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ padding: 8 }}>
            <Col span={16}>
              <Card
                {...CARD_CONFIG_STYLE}
                title="Wrike Tasks List"
                className="support-robot-wrike-tickets"
              >
                <TicketsList tasks={filteredWrike} />
              </Card>
            </Col>
            <Col span={8}>
              <Card
                {...CARD_CONFIG_STYLE}
                title="Wrike Task Statistics"
                className="support-robot-wrike-stats"
              >
                <TicketsStatistics tasks={{ data: filteredWrike }} />
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

function PdfModal(props) {
  const { openModal, robotName, onModalClose, dateValues, weldRun, type } =
    props;

  const setOpenModal = (value) => {
    return onModalClose(value);
  };

  const isMetric =
    weldRun && weldRun[0] && weldRun[0].units_system === "metric";

  // weldRun should be the same as generateCsvReports 's weldRun data
  const weldReportData = chain([...weldRun])
    .map((item) => {
      const idx = weldRun.findIndex((i) => i === item);

      if (
        idx < weldRun.length - 1 &&
        moment(
          moment(weldRun[idx + 1].arc_on_utc).utc(true),
          "YYYY-MM-DD"
        ).diff(
          moment(moment(item.arc_on_utc).utc(true), "YYYY-MM-DD"),
          "hour"
        ) >= -HOURS_IN_SHIFT
      ) {
        const duration = moment.duration(
          moment(item.arc_on_utc).diff(moment(weldRun[idx + 1].arc_off_utc))
        );

        return {
          ...item,
          duration,

          weld_inches: isMetric
            ? convertInchesTomm(item.weld_inches).toFixed(0)
            : item.weld_inches,
        };
      } else {
        return {
          ...item,

          weld_inches: isMetric
            ? convertInchesTomm(item.weld_inches).toFixed(0)
            : item.weld_inches,
        };
      }
    })
    .value()
    .reverse();

  return (
    <Modal
      style={{ width: "80wv" }}
      visible={openModal}
      width={window.innerWidth / 1.4}
      footer={[
        <PDFDownload
          title="Operations Report"
          robotName={robotName}
          weldRun={weldReportData}
          type={type}
          date={dateValues}
        ></PDFDownload>,
        <Button
          style={{ marginLeft: 8 }}
          key="submit"
          type="primary"
          onClick={() => setOpenModal(false)}
        >
          Close
        </Button>,
      ]}
      onCancel={() => {
        setOpenModal(false);
      }}
      onOk={() => {
        setOpenModal(false);
      }}
    >
      <PDFPreview
        title="Operations Report"
        robotName={robotName}
        weldRun={weldReportData}
        type={type}
        date={dateValues}
      />
    </Modal>
  );
}

export default RobotAnalytics;
