import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getProgramData } from "../../services/api/robot";
import { getFromS3URL } from "../../services/api";

import { novEyeLogs } from "../../libs/utils";
import * as FetchingHelper from "../../redux/fetchingHelper";
import { removeUndefinedKeys } from "../../libs/utilsTyped";

const sliceName = "noveye_logs";
const g3LogsSlice = createSlice({
  name: sliceName,
  initialState: {
    ...FetchingHelper.getInitialState(),
    logs: null,
    selectedItem: null,
  },

  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(...FetchingHelper.createBasicPendingCase(getRobotNovEyeLogs))
      .addCase(
        ...FetchingHelper.createBasicFulfilledCase(getRobotNovEyeLogs, "logs")
      )
      .addCase(...FetchingHelper.createBasicRejectedCase(getRobotNovEyeLogs))
      .addCase(...FetchingHelper.createBasicPendingCase(getNovEyeLogContents))
      .addCase(
        ...FetchingHelper.createBasicFulfilledCase(
          getNovEyeLogContents,
          "selectedItem"
        )
      )
      .addCase(...FetchingHelper.createBasicRejectedCase(getNovEyeLogContents));
  },
});

export default g3LogsSlice.reducer;

export const getRobotNovEyeLogs = createAsyncThunk<
  any,
  { id: string; start?: string; end?: string }
>(`${sliceName}/getRobotNovEyeLogs`, async ({ id, start, end }) => {
  const query = removeUndefinedKeys({
    key: "noveye_logs",
    start,
    end,
  }, ["start", "end"]);
  const { data } = await getProgramData(id, query);
  const logs = novEyeLogs(data);
  return { data: logs };
});

export const getNovEyeLogContents = createAsyncThunk<any, string>(
  `${sliceName}/getNovEyeLogContents`,
  async (url) => {
    return { data: await getFromS3URL(url) };
  }
);
