import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Card, Row, Avatar, Tooltip } from "antd";
import {
  AlertOutlined,
  AuditOutlined,
  CheckOutlined,
  EllipsisOutlined,
  RobotOutlined,
} from "@ant-design/icons";

import { green } from "@ant-design/colors";

import LoadingPage from "../../components/LoadingPage";

import {
  getRobotNotificationsList,
  createRobotNotification,
  updateRobotNotification,
  getRobotsList,
} from "../robot/robotSlice";

import { CARD_CONFIG_STYLE } from "../../libs/constants";
import { normalizeRobotName } from "../../libs/utils";
import { useLoadingWithProgress } from "../../libs/useLoading";

const { Meta } = Card;

const isUserSubscribed = (notifications, robotId, type, frequency) => {
  const filteredNotifications = notifications.filter((item) => {
    return (
      item.robot_id === robotId &&
      item.subscribed &&
      item.type === type &&
      item.frequency === frequency
    );
  });
  if (filteredNotifications && filteredNotifications.length > 0)
    return filteredNotifications[0].id;

  return false;
};
export default function Settings() {
  const dispatch = useDispatch();
  const [[robotList, notifications], progress] = useLoadingWithProgress(
    "robot",
    ({ index, notifications }) => [index, notifications],
    [getRobotsList, getRobotNotificationsList]
  );

  useEffect(() => {
    for (const id of robotList) {
      dispatch(getRobotNotificationsList(id));
    }
  }, [robotList, dispatch]);

  if (!progress.isFinished()) {
    return <LoadingPage />;
  }

  return (
    <>
      <Card {...CARD_CONFIG_STYLE} title={"Notifications"}>
        <Row>
          {robotList.map((id, index) => {
            console.log("notifications", notifications);
            const subscribedToWeeklyReport = isUserSubscribed(
              notifications,
              id,
              "report",
              "weekly"
            );

            return (
              <Card
                key={index}
                style={{ width: 300, marginRight: 10 }}
                actions={[
                  <Tooltip title={<span>Weekly Report</span>}>
                    <div
                      onClick={() => {
                        subscribedToWeeklyReport
                          ? dispatch(
                              updateRobotNotification({
                                robot_id: id,
                                notification_id: subscribedToWeeklyReport,
                                payload: {
                                  type: "report",
                                  frequency: "weekly",
                                  channel: "email",
                                },
                              })
                            )
                          : dispatch(
                              createRobotNotification({
                                robot_id: id,
                                payload: {
                                  type: "report",
                                  frequency: "weekly",
                                  channel: "email",
                                },
                              })
                            );
                      }}
                    >
                      {subscribedToWeeklyReport && (
                        <CheckOutlined
                          key="check"
                          style={{ color: green[5] }}
                        />
                      )}
                      <AuditOutlined
                        key="report"
                        style={
                          subscribedToWeeklyReport ? { color: green[5] } : {}
                        }
                      />
                    </div>
                  </Tooltip>,
                  <Tooltip title={<span>Error Alert - Coming Soon</span>}>
                    <AlertOutlined key="alert" />
                  </Tooltip>,
                  <Tooltip title={<span>More - Coming Soon</span>}>
                    <EllipsisOutlined key="ellipsis" />
                  </Tooltip>,
                ]}
              >
                <Meta
                  avatar={<Avatar size={64} icon={<RobotOutlined />} />}
                  title={normalizeRobotName(id)}
                  description="Click below icon to subscribe to email notifications"
                />
              </Card>
            );
          })}
        </Row>
      </Card>
    </>
  );
}
