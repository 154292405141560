import {
  DATE_REGEX,
  DATETIME_REGEX,
  LEGACY_WELD_REGEX,
} from "../../libs/constants";
import { chain, map, filter } from "lodash";
import moment from "moment";
import type { WeldRunWithFiles } from "./robotSlice";
import {utcToLocal} from "../../libs/utilsTyped";
export interface S3File {
  fileName: string;
  uploadedAt: string;
  url: string;
  size: number;
}
interface S3FileLists {
  videos?: S3File[];
  noveye_logs?: S3File[];
  reports?: S3File[];
  novdata_logs?: S3File[];
}
export function loadData(
  data: S3FileLists,
  current_files?: any
): { dates: string[] } {
  const {
    videos: local_videos,
    tsv: local_novdata_logs,
    reports: local_daily_reports,
    noveye_logs: local_noveye_logs,
  } = current_files;

  const {
    videos: cloud_videos,
    reports: cloud_daily_reports,
    novdata_logs: cloud_novdata_logs,
    noveye_logs: cloud_noveye_logs,
  } = data;

  const merged_videos = mergeObject(
    cloud_videos,
    local_videos
  );

  const merged_daily_reports = mergeObject(
    cloud_daily_reports,
    local_daily_reports
  );

  const merged_novdata_logs = mergeObject(
    cloud_novdata_logs,
    local_novdata_logs
  );

  const merged_noveye_logs = mergeObject(
    cloud_noveye_logs,
    local_noveye_logs
  );

  // LEGACY CODE - it has been tested and confirmed that this part is defective
  // lodash.filter is being used without a predicate (second function param) so it does nothing
  // and the sort comparator is incorrect because it doing numeric compare on strings "2023-06-29",
  const dates = filter([
    ...getDates(merged_videos),
    ...getDates(merged_daily_reports),
    ...getDates(merged_novdata_logs),
    ...getDates(merged_noveye_logs),
  ]).sort((a, b) => a - b);

  return {
    dates,
  };
}

const mergeObject = (cloudData, localData) => {
  const mergedData = map(cloudData, (item) => {
    item = { ...getInfoFromFileName(item) };

    map(localData, (obj) => {
      if (item.fileName === obj.file_name) {
        item = { ...item, ...obj };
      }
    });

    return { ...item, isLeaf: true };
  });

  return mergedData;
};

const getInfoFromFileName = (item) => {
  const matchDateTime = [...item.fileName.matchAll(DATETIME_REGEX)][0];
  const matchDate = [...item.fileName.matchAll(DATE_REGEX)][0];
  const matchWeldInfo = [...item.fileName.matchAll(LEGACY_WELD_REGEX)][0];

  return {
    ...item,
    key: item.fileName,
    title: item.fileName,
    extractedTime: matchDateTime
      ? new Date(
          matchDateTime[1],
          parseInt(matchDateTime[2], 10) - 1,
          matchDateTime[3],
          matchDateTime[4],
          matchDateTime[5],
          matchDateTime[6]
        ).toISOString()
      : null,
    extractedDate: matchDate
      ? new Date(
          matchDate[1],
          parseInt(matchDate[2], 10) - 1,
          matchDate[3]
        ).toISOString()
      : null,
    weldInfo: matchWeldInfo
      ? {
          work_order_ref: matchWeldInfo[1],
          spool_ref: matchWeldInfo[2],
          welder_id_ref: matchWeldInfo[3],
          run_number: matchWeldInfo[4],
        }
      : null,
  };
};

const getDates = (list) => {
  // console.log("get dates from", list)
  return chain(list)
    .map((item) => {
      return moment(
        item.modified_time ||
          item.created_time ||
          item.extracted_time ||
          item.extracted_date ||
          item.extractedDate ||
          item.extractedTime ||
          item.uploadedAt
      ).format("YYYY-MM-DD");
    })
    .uniqBy()
    .value();
};

export function getDailyReport(fileList: S3File[], weld: WeldRunWithFiles): S3File | null {
  try {
    const file = fileList.find((i) => {
      return i.fileName.includes(
        `${utcToLocal(weld.arc_on_utc, weld.time_zone).format("YYYY-MM-DD")}`
      );
    });

    return file || null;
  } catch (err) {
    console.log(
      "robotSearchUtils/getDailyReport: unexpected value",
      fileList,
      weld
    );
    return null;
  }
}

export function getNovDataLogLink(
  fileList: S3File[],
  weld: WeldRunWithFiles
): S3File | null {
  try {
    const fileName = weld.log_filename;

    const file = fileList.find((curr) => {
      return curr.fileName === fileName;
    });

    return file || null;
  } catch (err) {
    console.log(
      "robotSearchUtils/getNovDataLogLink: unexpected value",
      fileList,
      weld,
      err
    );
    return null;
  }
}

export function getVideoLink(
  fileList: S3File[],
  weld: WeldRunWithFiles
): S3File | null {
  try {
    const fileName = weld.vid_filename;

    const file = fileList.find((curr) => {
      return curr.fileName === fileName;
    });

    return file || null;
  } catch (err) {
    console.log(
      "robotSearchUtils/getVideoLink: unexpected value",
      fileList,
      weld,
      err
    );
    return null;
  }
}
