import React from "react";

import RobotStats from "../features/robot/RobotStats";
import RobotNavigation from "../features/robot/RobotNavigation";

function StatisticsPage() {
  return (
    <>
      <RobotNavigation />
      <RobotStats />
    </>
  );
}

export default StatisticsPage;
