import React from "react";

import { Card, Col, Row, Statistic } from "antd";

import { groupBy } from "lodash";
import moment from "moment";

import TrendStatistics from "../dashboard/TrendStatistics";
import { getDateList } from "../../libs/utils";
import { DEFAULT_LOCALE } from "../../libs/constants";

function TicketsStatistics(props) {
  const {
    tasks: { data = [] } = {},
    // workflows: { data: customStatuses = [] } = {},
  } = props;

  const today = new Date().toLocaleDateString(DEFAULT_LOCALE);

  // const customerSupportStatus =
  //   customStatuses.length > 0 &&
  //   customStatuses.filter((item) => {
  //     return item.name === "Customer Support Workflow";
  //   })[0].customStatuses;

  // URGENT Customer Support Workflow status id in wrike
  const urgentStatus = "IEAB6KBHJMBHDGWO";

  const wrikeTasks = [...data].map((item) => {
    return {
      ...item,
      date: new Date(item.createdDate).toLocaleDateString(DEFAULT_LOCALE),
      resolveTime: !!item.completedDate
        ? moment(item.completedDate) - moment(item.createdDate)
        : null,
    };
  });

  const grouped = groupBy(wrikeTasks, (item) => {
    return item.date;
  });

  const aWeekTrend = getDateList(
    moment(today).subtract(1, "week").calendar(),
    today
  );

  const trendData = Object.keys(grouped)
    .map((item) => {
      return { date: item, value: grouped[item].length };
    })
    .sort((item) => item.date)
    .reverse();

  // eslint-disable-next-line
  aWeekTrend.map((item) => {
    // eslint-disable-next-line
    trendData.map((i) => {
      if (i.date === item.date) {
        item.value = i.value;
      } else if (item.value) {
        // item.value = item.value;
      } else {
        item.value = 0;
      }
    });
  });

  const avgResolveTime =
    wrikeTasks.reduce((a, b) => {
      return a + b.resolveTime;
    }, 0) /
    wrikeTasks.filter((item) => {
      return !item.resolveTime;
    }).length;

  // config for Trends Graph
  const config = {
    data: aWeekTrend,
    xField: "date",
    yField: "value",
    smooth: true,
    height: 60,
    width: 300,
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={8}>
          <Card title="" bordered={false}>
            <Statistic
              title="Urgent"
              value={
                data &&
                data.filter((item) => {
                  return item.customStatusId === urgentStatus;
                }).length
              }
              valueStyle={{ color: "red" }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card title="" bordered={false}>
            <Statistic
              title="Active"
              value={
                data &&
                data.filter((item) => {
                  return item.status === "Active";
                }).length
              }
              valueStyle={{ color: "orange" }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card title="" bordered={false}>
            <Statistic
              title="Completed"
              value={
                data &&
                data.filter((item) => {
                  return item.status === "Completed";
                }).length
              }
              valueStyle={{ color: "grey" }}
            />
          </Card>
        </Col>

        <Col span={12}>
          <Card title="" bordered={false}>
            <TrendStatistics
              title={"New Tasks in last 7 days"}
              valueKey={"value"}
              color={"green"}
              {...config}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="" bordered={false}>
            <Statistic
              title="Average Task Resolution Time"
              value={
                avgResolveTime !== Infinity
                  ? moment.duration(avgResolveTime).humanize(true)
                  : "N/A"
              }
              valueStyle={{ color: "black" }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default TicketsStatistics;
