import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getRobotAlarms } from "../../services/api/robot";

import { getPLCAlarms } from "../../libs/utils";
import * as FetchingHelper from "../../redux/fetchingHelper";
const sliceName = "alarms";

const alarmSlice = createSlice({
  name: sliceName,
  initialState: {
    ...FetchingHelper.getInitialState(),
    alarms: {} as { [key: string]: any[] },
  },

  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(...FetchingHelper.createBasicPendingCase(getOverallPLCAlarms))
      .addCase(...FetchingHelper.createNoDataFulfilledCase(getOverallPLCAlarms))
      .addCase(...FetchingHelper.createBasicRejectedCase(getOverallPLCAlarms))
      .addCase(...FetchingHelper.createBasicPendingCase(getRobotPLCAlarms))
      .addCase(
        ...FetchingHelper.createFulfilledCaseWithObjectMerge(
          getRobotPLCAlarms,
          "alarms"
        )
      )
      .addCase(...FetchingHelper.createBasicRejectedCase(getRobotPLCAlarms));
  },
});

export default alarmSlice.reducer;

export const getOverallPLCAlarms = createAsyncThunk<
  void,
  { ids: number[]; start?: string; end?: string }
>(`${sliceName}/getOverallPLCAlarms`, async ({ ids, start, end }, thunkAPI) => {
  await Promise.all(
    ids.map(async (id) => {
      return thunkAPI.dispatch(getRobotPLCAlarms({ id, start, end }));
    })
  );
});

export const getRobotPLCAlarms = createAsyncThunk<
  any,
  { id: number; start?: string; end?: string }
>(`${sliceName}/getRobotPLCAlarms`, async ({ id, start, end }) => {
  const { data, headers } = await getRobotAlarms(id, {
    start,
    end,
  });
  if(!data || !headers?.length){
    throw new Error(`getRobotPLCAlarms: failed to fetch alarm data with id ${id}, date ${start}-${end}`);
  }

  return {
    [id]: getPLCAlarms(data, headers, id),
  };
});
