import React from "react";

import ReportsList from "../features/reports/ReportsList";
import RobotNavigation from "../features/robot/RobotNavigation";

function ReportsPage() {
  return (
    <>
      <RobotNavigation />
      <ReportsList />
    </>
  );
}

export default ReportsPage;
