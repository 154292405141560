import React from "react";
import { useParams } from "react-router-dom";

import { Empty, Timeline, Tabs } from "antd";

import moment from "moment";
import "moment-timezone";

const { TabPane } = Tabs;

function RobotScheduledEvents(props) {
  const {
    events = [],
    style = { height: 116, overflowY: "scroll", paddingTop: 10 },
  } = props;
  const { robotName } = useParams();
  const robotId = robotName && robotName.match(/(\d+)/)[0];
  const SWR_NUMBER_REGEX = /SWR(\s|\d)+/;

  const today = moment();

  const data = events
    .map((item, index) => {
      const findUnitNumber = item.title.match(SWR_NUMBER_REGEX);
      const unitNumber = findUnitNumber ? findUnitNumber[0] : "";

      return {
        ...item,
        key: index,
        unit: unitNumber.replace(/ /g, "").slice(3),
        date: item.dates && item.dates.due ? new Date(item.dates.due) : null,
      };
    })
    .filter((item) => {
      if (robotId) {
        return robotId === item.unit;
      } else {
        return item;
      }
    });

  const RobotEvent = ({ date, name, event, link }, color = "blue") => {
    if (date) {
      return (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "black" }}
        >
          <Timeline.Item color={color}>
            {moment.tz(date, "America/Vancouver").format("YYYY-MM-DD")} :{" "}
            {/* <b>{name ? `SWR-${name}` : ""}</b> - {event} */}
            {!!name ? (
              <>
                <b>{`SWR-${name}`}</b> - {event}
              </>
            ) : (
              event
            )}
          </Timeline.Item>
        </a>
      );
    } else {
      return (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "black" }}
        >
          <Timeline.Item color={color}>
            {!!name ? (
              <>
                <b>{`SWR-${name}`}</b> - {event}
              </>
            ) : (
              event
            )}
          </Timeline.Item>
        </a>
      );
    }
  };

  const parseEvent = (item) => {
    return {
      key: item.unit + item.title,
      date: (item.dates && item.dates.due) || null,
      name: item.unit,
      event: item.title,
      link: item.permalink,
    };
  };

  const upcomingEvents = {
    thisWeek: data.filter((item) => {
      return (
        item.dates &&
        item.dates.due &&
        today.isoWeek() === moment(item.dates.due).isoWeek()
      );
    }),
    nextWeek: data.filter((item) => {
      return (
        item.dates &&
        item.dates.due &&
        today.isoWeek() - moment(item.dates.due).isoWeek() === -1
      );
    }),
    others: data.filter((item) => {
      if (item.dates && item.dates.due) {
        return !(
          today.isoWeek() === moment(item.dates.due).isoWeek() ||
          today.isoWeek() - moment(item.dates.due).isoWeek() === -1
        );
      } else {
        return item;
      }
    }),
  };

  return (
    <Tabs type="card">
      <TabPane tab={"This Week"} key="1" style={style}>
        <Timeline>
          {upcomingEvents.thisWeek && upcomingEvents.thisWeek.length > 0 ? (
            upcomingEvents.thisWeek.map((item) => RobotEvent(parseEvent(item)))
          ) : (
            <Empty
              description={"No events"}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        </Timeline>
      </TabPane>
      <TabPane tab={"Next Week"} key="2" style={style}>
        <Timeline>
          {upcomingEvents.nextWeek && upcomingEvents.nextWeek.length > 0 ? (
            upcomingEvents.nextWeek.map((item) =>
              RobotEvent(parseEvent(item), "green")
            )
          ) : (
            <Empty
              description={"No events"}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        </Timeline>
      </TabPane>
      <TabPane tab={"Other"} key="3" style={style}>
        <Timeline>
          {upcomingEvents.others && upcomingEvents.others.length > 0 ? (
            upcomingEvents.others.map((item) =>
              RobotEvent(parseEvent(item), "grey")
            )
          ) : (
            <Empty
              description={"No events"}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        </Timeline>
      </TabPane>
    </Tabs>
  );
}

export default RobotScheduledEvents;
