import React from "react";

import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import configureStore, { history } from "./redux/configureStore";

import Router from "./routes";

import "./App.compiled.css";
const { version } = require("../package.json");

const store = configureStore(history);

function App() {
  console.log("Novarc Dashboard: ", version);
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Router />
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
