import React from "react";
import { useDispatch } from "react-redux";

import { push } from "connected-react-router";

import { Button, Result } from "antd";
import { LockTwoTone } from "@ant-design/icons";

function Error401Page() {
  const dispatch = useDispatch();

  return (
    <Result
      icon={<LockTwoTone />}
      title="401"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Button
          type="primary"
          onClick={() => {
            dispatch(push("/"));
          }}
        >
          Back to Home
        </Button>
      }
    />
  );
}
export default Error401Page;
