import React from "react";

import { Tabs } from "antd";

import UsersList from "../../features/admin/UsersList";
import GroupsList from "../../features/admin/GroupsList";
import RobotsList from "../../features/admin/RobotsList";

const { TabPane } = Tabs;

function AdminDashboardPage() {
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Users" key="1">
        <UsersList />
      </TabPane>
      <TabPane tab="Groups" key="2">
        <GroupsList />
      </TabPane>
      <TabPane tab="SWRs" key="3">
        <RobotsList />
      </TabPane>
    </Tabs>
  );
}

export default AdminDashboardPage;
