import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { push } from "connected-react-router";

import {Card, Col, Row, Popover, Skeleton, Button} from "antd";
import { ExportOutlined } from "@ant-design/icons";

import LoadingPage from "../../components/LoadingPage";

import RobotTopPerformance from "../robot/RobotTopPerformance";
import RobotOperationHours from "../robot/RobotOperationHours";
import RobotScheduledEvents from "../robot/RobotScheduledEvents";
import AlarmStatistics from "../alarms/AlarmStatistics";
import GeoLocationMap from "./GeoLocationMap";
import Wrike from "../wrike/Wrike";

import {
  getTickets,
  getCustomStatuses,
  getUpcomingEvents,
} from "../wrike/wrikeSlice";

import {
  CUSTOMER_MAINTENANCE_WRIKE_ID,
  CUSTOMER_SUPPORT_WRIKE_ID,
  WRIKE_TASK_PARAMS,
  CARD_CONFIG_STYLE,
  ROUTER_PERFORMANCE_ROOT,
  LOADING_ANIMATION_SIZE_FULL,
  LOADING_ANIMATION_SIZE,
} from "../../libs/constants";
import { getRobotInfo } from "../robot/robotSlice";
import { normalizeRobotName } from "../../libs/utils";

const LOADING_DELAY_IN_MS = 5000;

function Dashboard(props) {
  const { isCustomer } = props;
  const [showSplashPage, setShowSplashPage] = useState(!isCustomer);
  const dispatch = useDispatch();

  const robotList = useSelector((state) => state.robot.index);
  const tickets = useSelector((state) => state.wrike.tasks);
  const events = useSelector((state) => state.wrike.events);

  const [loadingDelay, setLoadingDelay] = useState(null);

  useEffect(() => {
    if (!isCustomer && !showSplashPage) {
      dispatch(
        getTickets(
          `folders/${CUSTOMER_SUPPORT_WRIKE_ID}/tasks`,
          WRIKE_TASK_PARAMS
        )
      );
      dispatch(
        getUpcomingEvents(
          `folders/${CUSTOMER_MAINTENANCE_WRIKE_ID}/tasks`,
          WRIKE_TASK_PARAMS
        )
      );

      dispatch(getCustomStatuses());
    }
  }, [dispatch, isCustomer, showSplashPage]);

  useEffect(() => {
    if(!showSplashPage){
      setTimeout(() => {
      setLoadingDelay(true);
    }, LOADING_DELAY_IN_MS);}
  }, [loadingDelay, showSplashPage]);

  //note - robotlist is requested by the sidebar widget, so no dispatch here
  if (!robotList.length) {
    return <LoadingPage size={LOADING_ANIMATION_SIZE_FULL} />;
  }

  //skip front page for single unit users
  if (isCustomer && robotList.length === 1)
    dispatch(push(`/${normalizeRobotName(robotList[0])}/analytics`));

  function handlePerformanceDetail () {
    dispatch(push(ROUTER_PERFORMANCE_ROOT));
  }

  return (
    <>
      <Row gutter={[4, 4]}>
        <Col span={24}>
          <GeoLocationMap {...CARD_CONFIG_STYLE} title="World Map" />
        </Col>

        {showSplashPage ? (
          <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{
                display: "flex",
                width: 400,
                height: 300,
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h1 style={{ fontSize:"1.5em", fontWeight:500}}>Welcome to NovSync Dashboard!</h1>
              <h2 style={{ fontSize:"1.3em", fontWeight:400,textAlign: "justify" }}>
                Select a unit using map or menu sidebar, or click here to view full
                Dashboard
              </h2>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={() => setShowSplashPage(false)}
                  style={{ border:"1px solid #1890FF", borderRadius: 4, fontWeight:600 }}
                >
                  Load Dashboard
                </Button>
              </div>
            </Card>
          </Col>
        ) : (
          <>
            {!isCustomer && (
              <Col span={8}>
                <Card {...CARD_CONFIG_STYLE} title="Wrike Tasks">
                  <Wrike tasks={tickets} />
                </Card>
                <Card {...CARD_CONFIG_STYLE} title="Upcoming Events">
                  <RobotScheduledEvents events={events} />
                </Card>
              </Col>
            )}

            <Col span={isCustomer ? 12 : 8}>
              <Card {...CARD_CONFIG_STYLE} title="Activity / Health">
                <AlarmStatistics />
              </Card>
            </Col>

            <Col span={isCustomer ? 12 : 8}>
              <Card
                {...CARD_CONFIG_STYLE}
                title={
                  <Popover
                    title={"Performance Details"}
                    content={
                      <p>
                        Please click to see overall SWRs' performance details
                      </p>
                    }
                    placement="topLeft"
                    trigger={"hover"}
                  >
                    <div
                      onClick={handlePerformanceDetail}
                      style={{ cursor: "pointer" }}
                    >
                      Top Performing Unit
                    </div>
                  </Popover>
                }
                extra={
                  <span
                    onClick={handlePerformanceDetail}
                    style={{ cursor: "pointer" }}
                  >
                    <ExportOutlined />
                  </span>
                }
              >
                {loadingDelay ? (
                  <RobotTopPerformance />
                ) : (
                  <Skeleton.Node
                    active={true}
                    style={{ height: 200, width: "100%", background: "white" }}
                  >
                    <LoadingPage size={LOADING_ANIMATION_SIZE} />
                  </Skeleton.Node>
                )}
              </Card>
              <Card {...CARD_CONFIG_STYLE} title="Operating Hours">
                <RobotOperationHours />
              </Card>
            </Col>
          </>
        )}
      </Row>
    </>
  );
}

export default Dashboard;
