import React from "react";

import { Typography } from "antd";

const { Title, Paragraph, Text } = Typography;

function EULAPage() {
  return (
    <>
      <Title level={3}>Novarc End User License Agreement</Title>
      <Paragraph>
        This Novarc End-User License Agreement (“<Text strong>Agreement</Text>”)
        is a legal agreement between you and Novarc governing the use of Novarc
        software associated with Novarc’s Spool Welding Robot (SWR), including
        but not limited to Novarc’s Programmable Logic Control software, NovEye
        <Text style={{ verticalAlign: "super", fontSize: "smaller" }}>TM</Text>,
        NovSync
        <Text style={{ verticalAlign: "super", fontSize: "smaller" }}>
          TM
        </Text>{" "}
        and NovData
        <Text style={{ verticalAlign: "super", fontSize: "smaller" }}>TM</Text>,
        which may include associated software components, media, printed
        materials, and "online" or electronic documentation (collectively, the
        "<Text strong>Software</Text>"). As used in this Agreement, the words “Novarc”, “we”, “us”
        and “our” means Novarc Technologies Inc. The words "you" and "your"
        means each person who uses the Software.
      </Paragraph>
      <Paragraph>
        BY USING THE SOFTWARE, YOU AGREE TO BE BOUND BY THE TERMS OF THIS
        AGREEMENT. This Agreement represents the entire agreement concerning the
        licensing of the Software between you and us, and it supersedes any
        prior proposal, representation, agreement, negotiation, offer,
        counteroffer, acceptance, understanding, or any contrary terms between
        you and us. If you do not agree to the terms of this Agreement, you are
        not authorized to download, operate, or otherwise use the Software.
      </Paragraph>

      <Title level={3}>1. Grant of License</Title>
      <Paragraph>
        Subject to the terms of this Agreement, and conditional upon your
        compliance therewith, we hereby grant you a limited, revocable,
        non-exclusive license and right to use the Software solely for your
        business purposes.
      </Paragraph>

      <Title level={3}>2. Data Collection</Title>
      <Paragraph>
        You acknowledge and agree that in order to provide updates to the
        Software or to improve our products and services, we may receive,
        collect and store images, video, diagnostics, input parameters, weld and
        motion parameters and other information regarding your use of the Spool
        Welding Robot.
      </Paragraph>

      <Title level={3}>3. Rights and Limitations</Title>
      <Title level={3}>a) Distribution</Title>
      <Paragraph>
        The license granted to you may not be sold, assigned, transferred,
        sublicensed, rented or lent to anyone else. Any such sale, assignment,
        transfer, sublicense, rental or loan is void and of no legal effect.
      </Paragraph>

      <Title level={3}>
        b) Prohibition on Modification, Reverse Engineering, Decompilation, and
        Disassembly.
      </Title>
      <Paragraph>
        You will not, and will not permit any other person to, copy the
        Software, in whole or in part, modify, reverse engineer, decompile,
        disassemble, decode or adapt the Software or otherwise attempt to derive
        or gain access to the source code of the Software, in whole or in part.
      </Paragraph>

      <Title level={3}>c) Updates and upgrades</Title>
      <Paragraph>
        We may, but are not required to add additional features or functions or
        provide programming fixes, updates and upgrades, to the Software. You
        acknowledge and agree that we have no obligation to make available to
        you any subsequent versions of the Software. You also agree that we may
        require you to enter into a revised version of this Agreement as a
        condition of downloading, installing or using a new version of the
        Software
      </Paragraph>

      <Title level={3}>d) Support Services</Title>
      <Paragraph>
        We may in our sole discretion provide you with support services related
        to the Software (“Support Services”). However, we are not required to
        provide Support Services and will not be in default if we fail to
        provide or discontinue any Support Services. Any supplemental software
        code we provide to you in connection with any Support Services is deemed
        to be part of the Software, is our sole property, and will be subject to
        the terms and conditions of this Agreement. Novarc support may be
        reached by email at support@novarctech.com.
      </Paragraph>

      <Title level={3}>e) Compliance with Applicable Laws</Title>
      <Paragraph>
        You will comply fully with all applicable laws regarding use of the
        Software.
      </Paragraph>

      <Title level={3}>
        4. Term, Termination and Modification of Agreement
      </Title>
      <Paragraph>
        The term of this Agreement will begin when you first use the Software
        and will continue for so long as you have in your possession or control
        any copies of the Software. Without prejudice to any of our rights, we
        may unilaterally terminate or modify this Agreement, at any time and for
        any reason or for no reason, with no notice to you. For example, but
        without limitation, we may terminate this Agreement and your rights to
        use the Software if we, in our sole determination, stop supporting or
        maintaining the Software, cease to provide updates, no longer offers the
        Software for license, or believe you have failed to comply with the
        terms and conditions of this Agreement.
      </Paragraph>

      <Title level={3}>5. Intellectual Property</Title>
      <Paragraph>
        The Software is licensed, not sold. As between you and Novarc, all
        right, title and interest, including but not limited to copyrights, in
        and to the Software and any copies of the Software are Novarc’s sole and
        exclusive property. As between you and Novarc, all title and
        intellectual property rights in and to the content which may be accessed
        through use of the Software is Novarc’s property and may be protected by
        applicable copyright or other intellectual property laws and treaties.
        This Agreement grants you no rights to use such content except as
        expressly and unambiguously set forth in this Agreement. You will not
        take any action to jeopardize, limit or interfere with our rights under
        this Agreement. You acknowledge and agree that any unauthorized use of
        the Software is a violation of this Agreement and applicable
        intellectual property laws. You agree that all title and rights in and
        to any third party content is the property of the respective content
        owners and may be protected by applicable patent, copyright, or other
        intellectual property laws and treaties. We reserve all rights not
        expressly granted by this Agreement. You must not remove or alter any
        copyright notices or other notices pertaining to intellectual property
        rights on any copies of the Software.
      </Paragraph>

      <Title level={3}>6. No Warranties</Title>
      <Paragraph>
        NOVARC EXPRESSLY DISCLAIMS ANY WARRANTY IN, TO, OR FOR THE SOFTWARE. THE
        SOFTWARE IS PROVIDED "AS IS" AND "WHERE IS" WITHOUT ANY EXPRESS OR
        IMPLIED WARRANTY OF ANY KIND. YOU EXPRESSLY WAIVE ANY WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR
        CONTINUOUS OPERATION. NOVARC MAKES NO WARRANTY THAT THE SOFTWARE WILL
        MEET YOUR REQUIREMENTS, THAT USE OF THE SOFTWARE WILL BE UNINTERRUPTED,
        SECURE, TIMELY OR ERROR FREE, OR THAT ANY DEFECTS IN THE SOFTWARE WILL
        BE CORRECTED. YOU ACKNOWLEDGE THAT ANY DATA OR INFORMATION DOWNLOADED,
        TRANSMITTED OR OTHERWISE OBTAINED OR ACQUIRED THROUGH THE USE OF THE
        SOFTWARE IS AT YOUR SOLE RISK AND DISCRETION. NOVARC WILL NOT BE LIABLE
        OR RESPONSIBLE FOR ANY DAMAGE TO YOU OR YOUR PROPERTY. YOU ASSUME THE
        ENTIRE RISK ASSOCIATED WITH OPERATION OF THE SOFTWARE.
      </Paragraph>
      <Paragraph>
        NOVARC DISCLAIMS ANY WARRANTY OR RESPONSIBILITY FOR THE ACCURACY OR
        COMPLETENESS OF ANY INFORMATION, TEXT, GRAPHICS, LINKS OR OTHER ITEMS
        CONTAINED WITHIN THE SOFTWARE. NOVARC EXPRESSLY DISCLAIMS ANY WARRANTIES
        RESPECTING ANY HARM THAT MAY BE CAUSED BY THE TRANSMISSION OF A COMPUTER
        VIRUS, WORM, TIME BOMB, LOGIC BOMB, OR OTHER SUCH COMPUTER PROGRAM.
        NOVARC FURTHER EXPRESSLY DISCLAIMS ANY WARRANTY OR REPRESENTATION TO ANY
        THIRD PARTY WHO MAY OPERATE OR OTHERWISE UTILIZE THE SOFTWARE.
      </Paragraph>

      <Title level={3}>7. Limitation of Liability</Title>
      <Paragraph>
        IN NO EVENT WILL NOVARC BE LIABLE FOR ANY DAMAGES (INCLUDING, WITHOUT
        LIMITATION, DIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE
        DAMAGES, LOST PROFITS, BUSINESS INTERRUPTION, OR LOST INFORMATION)
        ARISING OUT OF YOUR USE OF, OR INABILITY TO USE, THE SOFTWARE, OR THE
        USE OR INABILITY TO USE THE SOFTWARE BY ANY THIRD PARTY THAT MAY HAVE
        ACCESS TO THE SOFTWARE BY OR THROUGH YOU, EVEN IF NOVARC HAS BEEN
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND WITHOUT REGARD TO
        WHETHER SUCH DAMAGES, OR CLAIMS OF DAMAGES ARISE BASED IN CONTRACT, TORT
        OR OTHERWISE. NOVARC WILL HAVE NO LIABILITY WITH RESPECT TO THE ALL OR
        ANY PART OF THE CONTENT OF THE SOFTWARE, INCLUDING BUT NOT LIMITED TO,
        ERRORS OR OMISSIONS, INFRINGEMENTS OF RIGHTS OF PUBLICITY, PRIVACY,
        TRADEMARK RIGHTS, BUSINESS INTERRUPTION, PERSONAL INJURY, LOSS OF
        PRIVACY, MORAL RIGHTS OR THE DISCLOSURE OF CONFIDENTIAL INFORMATION.
        NOVARC'S TOTAL LIABILITY FOR ANY AND ALL DAMAGES, REGARDLESS OF THE FORM
        OF THE ACTION, WILL BE LIMITED AND CAPPED IN THEIR ENTIRETY TO THE
        GREATER OF ONE DOLLAR (CAD $1.00) OR THE TOTAL AMOUNT PAID, IF ANY, BY
        YOU FOR THE SOFTWARE. THIS LIMITATION OF LIABILITY MAY NOT BE
        ENFORCEABLE IN CERTAIN JURISDICTIONS, OR UNDER CERTAIN CIRCUMSTANCES, AS
        A MATTER OF LOCAL LAW, AND IS NOT INTENDED TO REPLACE OR SUPERCEDE LOCAL
        LAW.
      </Paragraph>

      <Title level={3}>8. Indemnification</Title>
      <Paragraph>
        You agree to indemnify, defend and hold harmless Novarc, its affiliates,
        directors, officers, employees, shareholders, and agents from and
        against all liabilities, losses, costs, expenses (including reasonable
        attorneys' fees), and damages resulting from (i) any negligent acts,
        omissions or willful misconduct by you, (ii) your use of the Software,
        (iii) any breach of this Agreement by you, and/or (iv) your violation of
        any law or of any rights of any third party. The provisions of this
        section are for the benefit of Novarc and its officers, directors,
        employees, agents, licensors, suppliers, and any third-party information
        providers. Each of these individuals or entities expressly retains the
        right to assert and enforce those provisions directly against you on its
        own behalf. You acknowledge that if any third party claims that your
        possession and use of the Software infringes upon that third party's
        intellectual property rights, Novarc, and not any third-party, will be
        solely responsible for the investigation, defense, settlement and
        discharge of any such intellectual property infringement claim.
      </Paragraph>

      <Title level={3}>9. Your representation to Novarc</Title>
      <Paragraph>
        As a material inducement to Novarc entering into this Agreement with
        you, you represent to Novarc that you have the full and unrestricted
        capacity and legal right to enter into agreements. You also acknowledge
        that the Software may be subject to other Canadian, U.S. and foreign
        laws and regulations governing the export of software by physical or
        electronic means. You agree to comply with all applicable Canadian, U.S.
        and foreign laws as well as end-user, enduse, and destination
        restrictions imposed by Canadian, U.S. and foreign governments.
      </Paragraph>

      <Title level={3}>10. Miscellaneous</Title>
      <Paragraph>
        If any part of this Agreement is held invalid or unenforceable, the
        remaining portions will remain in full force and effect. The failure of
        Novarc to exercise or enforce any right or provision of this Agreement
        will not constitute a waiver of such right or provision. Subject to any
        restrictions on transfer and assignment described above, this Agreement
        will be binding on and inure to the benefit of the parties, their
        successors, permitted assigns and legal representatives. The provisions
        of this Agreement relating to copyrights, intellectual property
        ownership, restrictions on use, disclaimers of warranties, limitations
        of liability and indemnification will survive any termination of this
        Agreement for any reason. This Agreement which cannot be changed except
        by a written agreement executed between you and Novarc. This Agreement
        will be governed by and construed in accordance with the laws of the
        Province of British Columbia in the country and Canada. You submit to
        the jurisdiction of the courts in the Province of British Columbia
      </Paragraph>
    </>
  );
}

export default EULAPage;
