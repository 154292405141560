import { shallowEqual, useSelector } from "react-redux";
import type { FetchingState } from "../redux/fetchingHelper";

//todo move to rootreducer file
type SliceKeys =
  | "router"
  | "admin"
  | "auth"
  | "alarms"
  | "syncInfo"
  | "robot"
  | "videos"
  | "reports"
  | "noveye_logs"
  | "novdata_logs"
  | "wrike"
  | "performance";

type ThunkAction = Function & { typePrefix: string };
function isThunkAction(e): e is ThunkAction {
  return typeof e === "function" && typeof e.typePrefix === "string";
}

class LoadingProgress {
  ready: number;
  error: number; //todo
  total: number;
  constructor(count: number) {
    this.total = count;
    this.ready = 0;
    this.error = 0;
  }
  isFinished() {
    return this.error + this.ready === this.total;
  }
  isSuccessful() {
    return this.ready === this.total;
  }
}

export function combineLoadingProgress(
  ...args: LoadingProgress[]
): LoadingProgress {
  return args.reduce((acc, curr) => {
    acc.total += curr.total;
    acc.ready += curr.ready;
    acc.error += curr.error;
    return acc;
  }, new LoadingProgress(0));
}

/**
 * Makes a Select from the Redux state or slice, with an additional user-provided loading check.
 * @param sliceKey id of Slice to select from. Maximum 1 level deep. Passing empty str selects from root state.
 * @param sliceSelector maps sliced state to value.
 * @param checkIfLoaded checks the selected value with user-provided function.
 * @returns tuple of selected value and loading check
 */
export function useLoading<ReturnType extends any, SliceState extends {}>(
  sliceKey: SliceKeys | "",
  sliceSelector: (sliceState: SliceState) => ReturnType,
  checkIfLoaded: (val: ReturnType) => boolean
): [ReturnType, boolean] {
  return useSelector((rootState) => {
    const sliceState: SliceState = sliceKey ? rootState[sliceKey] : rootState;
    const value = sliceSelector(sliceState);
    const isLoaded = checkIfLoaded(value);
    return [value, isLoaded];
  }, shouldSkipUpdate);

  function shouldSkipUpdate(
    a: [ReturnType, boolean],
    b: [ReturnType, boolean]
  ): boolean {
    return a[1] === b[1] && shallowEqual(a[0], b[0]);
  }
}

/**
 * Makes a Select from the Redux state or slice, with an additional loading check based on fetching state.
 * Intended for use with Thunks and reducers created using fetchingHelper
 * @param sliceKey id of Slice to select from. Maximum 1 level deep. Passing empty str selects from root state.
 * @param sliceSelector maps sliced state to value.
 * @param loadingStateKeys one or more state keys to be looked up against the FetchingState interface
 * @param checkIfLoaded checks the selected value with user-provided function. Default checks for undefined
 * @returns tuple of selected value and loading progress obj
 */
export function useLoadingWithProgress<
  ReturnType extends any,
  SliceState extends FetchingState
>(
  sliceKey: SliceKeys | "",
  sliceSelector: (sliceState: Partial<SliceState>) => ReturnType,
  loadingStateKeys: string | string[] | ThunkAction | ThunkAction[],
  checkIfLoaded?: (val: ReturnType) => boolean
): [ReturnType, LoadingProgress] {
  return useSelector((rootState) => {
    const sliceState: SliceState = sliceKey ? rootState[sliceKey] : rootState;
    const loadingKeyArr: string[] =
      loadingStateKeys instanceof Array
        ? loadingStateKeys.map((e) => (isThunkAction(e) ? e.typePrefix : e))
        : isThunkAction(loadingStateKeys)
        ? [loadingStateKeys.typePrefix]
        : [loadingStateKeys];
    const loadingProps: (boolean | undefined)[] = loadingKeyArr.map(
      (k) => sliceState.fetching?.[k]
    );
    const loadingProgress = new LoadingProgress(loadingProps.length);

    const selection = sliceSelector(sliceState);
    const dataReady:boolean =
      typeof checkIfLoaded === "function"
        ? checkIfLoaded(selection)
        : selection instanceof Array
        ? selection.every((e) => e !== undefined)
        : selection instanceof Object
        ? Object.keys(selection).every((k) => selection[k] !== undefined)
        : selection !== undefined;

    loadingProgress.ready = loadingProps.filter((e) => e === false).length;
    // todo .fetchingError can be error when .fetching is false
    // loadingProgress.error = loadingProps.filter((e) => e === undefined).length;
    return [selection, loadingProgress];
  }, shouldSkipUpdate);

  function shouldSkipUpdate(
    a: [ReturnType, LoadingProgress],
    b: [ReturnType, LoadingProgress]
  ): boolean {
    // may need to unpack deep equality
    return shallowEqual(a[0], b[0]) && shallowEqual(a[1], b[1]);
  }
}
