import React from "react";
import { Select } from "antd";

const { Option } = Select;

function SelectDateType(props) {
  return (
    <Select {...props}>
      <Option value="range">Custom</Option>
      <Option value="date">Date</Option>
      <Option value="week">Week</Option>
      <Option value="month">Month</Option>
      <Option value="quarter">Quarter</Option>
      <Option value="year">Year</Option>
    </Select>
  );
}
export default SelectDateType;
