import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button, Empty, Space, List } from "antd";

import moment from "moment";
import { ReloadOutlined } from "@ant-design/icons";

import { getInactiveRobotsList } from "../../features/performance/performanceSlice";
import LoadingPage from "../../components/LoadingPage";

import PickerWithType from "../../libs/components/PickerWithType";
import SelectDateType from "../../libs/components/SelectDateType";
import { GetDateRange, normalizeRobotName } from "../../libs/utils";

const today = moment();
const lastWeek = moment().subtract(7, "days");

function InactiveRobots(props) {
  const dispatch = useDispatch();

  const inactive_units = useSelector(
    (state) => state.performance.inactive_units
  );

  const [dateValues, setDateValues] = useState(null);
  const [type, setType] = useState("range");

  useEffect(() => {
    dispatch(
      getInactiveRobotsList(null, {
        start: lastWeek.format("YYYY-MM-DD"),
        end: today.format("YYYY-MM-DD"),
      })
    );
  }, [dispatch]);

  if (!inactive_units) {
    return <LoadingPage></LoadingPage>;
  }

  return (
    <>
      <Space>
        <b>Inactive Units</b>

        <SelectDateType value={type} onChange={setType}></SelectDateType>
        <PickerWithType
          type={type}
          defaultValue={[lastWeek, today]}
          onChange={(value) => setDateValues(value)}
        />

        <Button
          disabled={!dateValues}
          onClick={() => {
            const dateRange = GetDateRange(type, dateValues);

            dispatch(
              getInactiveRobotsList(null, {
                start: dateRange[0],
                end: dateRange[1],
              })
            );
          }}
          icon={<ReloadOutlined />}
        ></Button>
      </Space>

      <div>
        {!inactive_units || inactive_units.length === 0 ? (
          <Empty />
        ) : (
          <List
            style={{ height: 300, overflowY: "scroll" }}
            itemLayout="horizontal"
            dataSource={inactive_units}
            size="small"
            renderItem={(item) => (
              <List.Item
                key={item.robot_id}
                title={normalizeRobotName(item.robot_id)}
              >
                {normalizeRobotName(item.robot_id)}
              </List.Item>
            )}
          />
        )}
      </div>
    </>
  );
}
export default InactiveRobots;
