import { Card, Space, Switch, Table } from "antd";
import { CARD_CONFIG_STYLE } from "../../libs/constants";
import React, { useState } from "react";
import moment from "moment";

interface Props {
  columns: any[];
  data: any[];
  filterData: any[];
  dateSelectKey: number;
  pagination: { hideOnSinglePage: boolean };
}
export default function AlarmListCard(props: Props) {
  const { columns, data, filterData, dateSelectKey, pagination } = props;
  const [showCleared, setShowCleared] = useState(false);
  return (
    <>
      {/*@ts-ignore*/}
      <Card {...CARD_CONFIG_STYLE} title="Alarm List">
        <Space align="center" style={{ margin: "16px 16px 4px 16px" }}>
          <Switch checked={showCleared} onChange={setShowCleared} />
          Show cleared alarms
        </Space>
        <Table
          className="AlarmsList_Table"
          size={"small"}
          columns={columns}
          dataSource={data.filter((item) => {
            if (showCleared) {
              return moment(item.received_at).isSameOrAfter(
                filterData
                  .find(({ key }) => key === dateSelectKey)
                  .date.format("YYYY-MM-DD")
              );
            }

            return (
              (item.cleared_at !== "" || item.acknowledged_at !== "") &&
              moment(item.received_at).isSameOrAfter(
                filterData
                  .find(({ key }) => key === dateSelectKey)
                  .date.format("YYYY-MM-DD")
              )
            );
          })}
          pagination={pagination}
        />
      </Card>
    </>
  );
}
