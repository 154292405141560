import React from "react";

import RobotNavigation from "../features/robot/RobotNavigation";
import RobotWeldTestReports from "../features/robot/RobotWeldTestReports";

function WeldResultsPage(props) {
  return (
    <>
      <RobotNavigation {...props} />
      <RobotWeldTestReports {...props} />
    </>
  );
}

export default WeldResultsPage;
