import React from "react";
import { useParams } from "react-router-dom";

import RobotSearchFiles from "../features/robot/RobotSearchFiles";
import RobotSearchFilesOld from "../features/robot/RobotSearchFilesOld";
import RobotNavigation from "../features/robot/RobotNavigation";

import Error404Page from "./Error404Page";
import { isStagingRobotInProd } from "../libs/utils";

function SearchFilesPage(props) {
  const { robotName } = useParams();
  const robotId = robotName.match(/(\d+)/) && robotName.match(/(\d+)/)[0];

  if (!robotId) {
    return <Error404Page />;
  }

  return (
    <>
      <RobotNavigation {...props} />
      {
        // This is to display calendar view for R&D units on staging URL as it is connected to correct backend
        isStagingRobotInProd(robotId) ? (
          <RobotSearchFilesOld {...props} />
        ) : (
          <RobotSearchFiles {...props} />
      )}
    </>
  );
}

export default SearchFilesPage;
