import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";

import { push } from "connected-react-router";

import { Button, Input, Layout, Menu } from "antd";

import {
  AlertOutlined,
  BarChartOutlined,
  CalendarOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FundProjectionScreenOutlined,
  GlobalOutlined,
  PlaySquareOutlined,
  ProfileOutlined,
  RobotOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";

import { MenuClosedIcon, MenuOpenIcon } from "./icons/NovarcIcons";

import logo from "../assets/novarc-logo.white.svg";

import { getRobotsList } from "../features/robot/robotSlice";

const { Search } = Input;
const { Sider } = Layout;
const { SubMenu } = Menu;

function LayoutSider(props) {
  const robots = useSelector((state) => state.robot.robots);
  const robotList = useSelector((state) => state.robot.index);
  const { isCustomer } = props;
  const { robotName } = useParams();
  const location = useLocation();

  const currentPath = location.pathname + location.hash;

  const [openSider, setOpenSider] = useState(isCustomer ? true : false);

  const [defaultOpenMenu, setDefaultOpenMenu] = useState([robotName]);
  const [defaultSelectedMenu, setDefaultSelectedMenu] = useState(currentPath);
  const [openMenu, setOpenMenu] = useState([robotName]);

  const [searchText, setSearchText] = useState("");
  const searchLower = searchText.toLowerCase();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!robotList.length) {
      dispatch(getRobotsList());
      return;
    }
    setDefaultOpenMenu([robotName]);
    setOpenMenu([robotName]);
    setDefaultSelectedMenu(currentPath);
  }, [dispatch, robotName, currentPath, robotList]);

  const handleSearch = () => {
    setSearchText(searchText);
  };

  const filterSWR = () => {
    if (searchText === "") {
      return robotList;
    }
    return robotList.filter((id) => {
      const robot = robots[id];
      return (
        String(robot.name).toLowerCase().includes(searchLower) ||
        String(robot.customer).toLowerCase().includes(searchLower) ||
        String(robot.company).toLowerCase().includes(searchLower) ||
        String(robot.location).toLowerCase().includes(searchLower)
      );
    });
  };

  const logoMenu = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          position: !openSider ? "absolute" : "inherit",
        }}
      >
        <Button
          type="text"
          icon
          onClick={() => {
            setOpenSider(!openSider);
          }}
          style={{ margin: "auto 0px", color: "white" }}
        >
          {React.createElement(!openSider ? MenuClosedIcon : MenuOpenIcon)}
        </Button>
        <img
          className="logo"
          alt="logo"
          src={logo}
          style={{ padding: "12px", height: 50, cursor: "pointer" }}
          onClick={() => dispatch(push("/"))}
        />
      </div>
    );
  };

  const menu = (isCustomer) => {
    return filterSWR().map((id) => {
      const robot = robots[id];
      return (
        <SubMenu
          key={robot.name}
          icon={<RobotOutlined />}
          title={robot.name}
          onTitleClick={() => {
            const openMenuIndex = openMenu.indexOf(robot.name);
            openMenuIndex === -1
              ? setOpenMenu([...openMenu, robot.name])
              : openMenu.splice(openMenuIndex, 1);
          }}
        >
          {isCustomer && (
            <>
              <Menu.Item
                key={`/${robot.name}/analytics`}
                icon={<BarChartOutlined />}
                onClick={() => {
                  window.scrollTo(0, 0);
                  dispatch(push(`/${robot.name}/analytics`));
                }}
              >
                Analytics
              </Menu.Item>
              <Menu.Item
                key={`/${robot.name}/health`}
                icon={<AlertOutlined />}
                onClick={() => {
                  window.scrollTo(0, 0);
                  dispatch(push(`/${robot.name}/health`));
                }}
              >
                Health
              </Menu.Item>
              <Menu.Item
                key={`/${robot.name}/search#videos_&_daily_reports`}
                icon={<CalendarOutlined />}
                onClick={() => {
                  window.scrollTo(0, 0);
                  dispatch(push(`/${robot.name}/search#videos_&_daily_reports`));
                }}
              >
                Videos & Daily Reports
              </Menu.Item>
              <Menu.Item
                key={`/${robot.name}/weld_test_results`}
                icon={<CalendarOutlined />}
                onClick={() => {
                  window.scrollTo(0, 0);
                  dispatch(push(`/${robot.name}/weld_test_results`));
                }}
              >
                Weld QC Report
              </Menu.Item>
              <Menu.Item
                key={`/${robot.name}/support`}
                icon={
                  // placeholder
                  <SnippetsOutlined />
                }
                onClick={() => {
                  window.scrollTo(0, 0);
                  dispatch(push(`/${robot.name}/support`));
                }}
              >
                Support
              </Menu.Item>
            </>
          )}

          {!isCustomer && (
            <>
              <Menu.Item
                key={`/${robot.name}/analytics`}
                icon={<ProfileOutlined />}
                onClick={() => {
                  window.scrollTo(0, 0);
                  dispatch(push(`/${robot.name}/analytics`));
                }}
              >
                Analytics
              </Menu.Item>
              <Menu.Item
                key={`/${robot.name}/health`}
                icon={<ProfileOutlined />}
                onClick={() => {
                  window.scrollTo(0, 0);
                  dispatch(push(`/${robot.name}/health`));
                }}
              >
                Health
              </Menu.Item>
              <Menu.Item
                key={`/${robot.name}/search`}
                icon={<CalendarOutlined />}
                onClick={() => {
                  window.scrollTo(0, 0);
                  dispatch(push(`/${robot.name}/search`));
                }}
              >
                Videos & Daily Reports
              </Menu.Item>
              <Menu.Item
                key={`/${robot.name}/weld_test_results`}
                icon={<CalendarOutlined />}
                onClick={() => {
                  window.scrollTo(0, 0);
                  dispatch(push(`/${robot.name}/weld_test_results`));
                }}
              >
                Weld QC Report
              </Menu.Item>
              <Menu.Item
                key={`/${robot.name}/videos`}
                icon={<PlaySquareOutlined />}
                onClick={() => {
                  window.scrollTo(0, 0);
                  dispatch(push(`/${robot.name}/videos`));
                }}
              >
                Search Video
              </Menu.Item>
              <Menu.Item
                key={`/${robot.name}/reports`}
                icon={<FilePdfOutlined />}
                onClick={() => {
                  window.scrollTo(0, 0);
                  dispatch(push(`/${robot.name}/reports`));
                }}
              >
                Search Daily Report
              </Menu.Item>
              <Menu.Item
                key={`/${robot.name}/novdata_logs`}
                icon={<FileExcelOutlined />}
                onClick={() => {
                  window.scrollTo(0, 0);
                  dispatch(push(`/${robot.name}/novdata_logs`));
                }}
              >
                Search NovData Log
              </Menu.Item>
              <Menu.Item
                key={`/${robot.name}/noveye_logs`}
                icon={<FileTextOutlined />}
                onClick={() => {
                  window.scrollTo(0, 0);
                  dispatch(push(`/${robot.name}/noveye_logs`));
                }}
              >
                Search NovEye Log
              </Menu.Item>
              <Menu.Item
                key={`/${robot.name}/alarm_list`}
                icon={<AlertOutlined />}
                onClick={() => {
                  window.scrollTo(0, 0);
                  dispatch(push(`/${robot.name}/alarm_list`));
                }}
              >
                Alarm List
              </Menu.Item>
              <Menu.Item
                key={`/${robot.name}/statistics`}
                icon={<FundProjectionScreenOutlined />}
                onClick={() => {
                  window.scrollTo(0, 0);
                  dispatch(push(`/${robot.name}/statistics`));
                }}
              >
                Statistics
              </Menu.Item>
            </>
          )}
        </SubMenu>
      );
    });
  };

  if (!openSider) {
    return logoMenu();
  } else {
    return (
      <Sider trigger={null} collapsible collapsed={false}>
        {logoMenu()}
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[defaultSelectedMenu]}
          defaultOpenKeys={defaultOpenMenu}
          selectedKeys={[defaultSelectedMenu]}
          openKeys={openMenu}
        >
          {!isCustomer && (
            <>
              <Menu.Item
                key="/"
                icon={<GlobalOutlined />}
                onClick={() => dispatch(push("/"))}
                style={{ marginBottom: 1 }}
              >
                Overview
              </Menu.Item>
              {/* <Menu.Item key="search" style={{ paddingLeft: 0 }}> */}

              <Search
                placeholder="Search SWR"
                onSearch={() => {
                  handleSearch();
                }}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                enterButton
              />
            </>
          )}
          {/* </Menu.Item> */}

          {menu(isCustomer)}
        </Menu>
      </Sider>
    );
  }
}

export default LayoutSider;
