import React from "react";

import { Card, Col, Row } from "antd";

import { CARD_CONFIG_STYLE } from "../../libs/constants";

import TopWeldHours from "./TopWeldHours";
import TopWeldInches from "./TopWeldInches";
import TopOperatorsWeldHours from "./TopOperatorsWeldHours";
import TopOperatorsWeldInches from "./TopOperatorsWeldInches";
import LeastWeldHours from "./LeastWeldHours";
import LeastWeldInches from "./LeastWeldInches";
import OfflineRobots from "./OfflineRobots";
import InactiveRobots from "./InactiveRobots";
import PipeSizesStatistics from "./PipeSizesStatistics";

function Performance(props) {
  return (
    <React.Fragment>
      <Row gutter={[4, 4]}>
        <Col span={12}>
          <Card {...CARD_CONFIG_STYLE} title="Top Weld Hours">
            <TopWeldHours></TopWeldHours>
          </Card>
        </Col>
        <Col span={12}>
          <Card {...CARD_CONFIG_STYLE} title="Top Weld Inches (FDI)">
            <TopWeldInches></TopWeldInches>
          </Card>
        </Col>
        <Col span={12}>
          <Card {...CARD_CONFIG_STYLE} title="Top Operators Weld Hours">
            <TopOperatorsWeldHours></TopOperatorsWeldHours>
          </Card>
        </Col>
        <Col span={12}>
          <Card {...CARD_CONFIG_STYLE} title="Top Operators Weld Inches (FDI)">
            <TopOperatorsWeldInches></TopOperatorsWeldInches>
          </Card>
        </Col>

        <Col span={12}>
          <Card {...CARD_CONFIG_STYLE} title="Least Weld Hours">
            <LeastWeldHours></LeastWeldHours>
          </Card>
        </Col>

        <Col span={12}>
          <Card {...CARD_CONFIG_STYLE} title="Least Weld Inches (FDI)">
            <LeastWeldInches></LeastWeldInches>
          </Card>
        </Col>

        <Col span={24}>
          <Card {...CARD_CONFIG_STYLE} title="Statistics on Pipe Size">
            <PipeSizesStatistics></PipeSizesStatistics>
          </Card>
        </Col>
        <Col span={12}>
          <Card {...CARD_CONFIG_STYLE} title="Offline Units">
            <OfflineRobots />
          </Card>
        </Col>
        <Col span={12}>
          <Card {...CARD_CONFIG_STYLE} title="Units without weld">
            <InactiveRobots></InactiveRobots>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Performance;
