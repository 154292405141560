import { createSlice } from "@reduxjs/toolkit";

import {
  getPerformanceWeldHours,
  getPerformanceWeldInches,
  getPerformanceOperatorsWeldHours,
  getPerformanceOperatorsWeldInches,
  getPerformanceWithoutWeldHours,
  getStatisticsPipeSize,
} from "../../services/api/robot";

const performanceSlice = createSlice({
  name: "performance",
  initialState: {
    top_weld_hours: null,
    top_weld_inches: null,
    least_weld_hours: null,
    least_weld_inches: null,
  },

  reducers: {
    fetchTopWeldHoursListRequest: (state) => ({
      ...state,
      fetchTopWeldHoursList: true,
      fetchTopWeldHoursListError: null,
    }),
    fetchTopWeldHoursListFailure: (state, action) => ({
      ...state,
      fetchTopWeldHoursList: false,
      fetchTopWeldHoursListError: action.payload,
    }),
    fetchTopWeldHoursListSuccess: (state, action) => ({
      ...state,
      fetchTopWeldHoursList: false,
      fetchTopWeldHoursListError: null,
      top_weld_hours: action.payload.data,
    }),

    fetchTopWeldInchesListRequest: (state) => ({
      ...state,
      fetchTopWeldInchesList: true,
      fetchTopWeldInchesListError: null,
    }),
    fetchTopWeldInchesListFailure: (state, action) => ({
      ...state,
      fetchTopWeldInchesList: false,
      fetchTopWeldInchesListError: action.payload,
    }),
    fetchTopWeldInchesListSuccess: (state, action) => ({
      ...state,
      fetchTopWeldInchesList: false,
      fetchTopWeldInchesListError: null,
      top_weld_inches: action.payload.data,
    }),

    fetchTopOperatorsWeldHoursListRequest: (state) => ({
      ...state,
      fetchTopOperatorsWeldHoursList: true,
      fetchTopOperatorsWeldHoursListError: null,
    }),
    fetchTopOperatorsWeldHoursListFailure: (state, action) => ({
      ...state,
      fetchTopOperatorsWeldHoursList: false,
      fetchTopOperatorsWeldHoursListError: action.payload,
    }),
    fetchTopOperatorsWeldHoursListSuccess: (state, action) => ({
      ...state,
      fetchTopOperatorsWeldHoursList: false,
      fetchTopOperatorsWeldHoursListError: null,
      top_operators_weld_hours: action.payload.data,
    }),

    fetchTopOperatorsWeldInchesListRequest: (state) => ({
      ...state,
      fetchTopOperatorsWeldInchesList: true,
      fetchTopOperatorsWeldInchesListError: null,
    }),
    fetchTopOperatorsWeldInchesListFailure: (state, action) => ({
      ...state,
      fetchTopOperatorsWeldInchesList: false,
      fetchTopOperatorsWeldInchesListError: action.payload,
    }),
    fetchTopOperatorsWeldInchesListSuccess: (state, action) => ({
      ...state,
      fetchTopOperatorsWeldInchesList: false,
      fetchTopOperatorsWeldInchesListError: null,
      top_operators_weld_inches: action.payload.data,
    }),

    fetchLeastWeldHoursListRequest: (state) => ({
      ...state,
      fetchLeastWeldHoursList: true,
      fetchLeastWeldHoursListError: null,
    }),

    fetchLeastWeldHoursListFailure: (state, action) => ({
      ...state,
      fetchLeastWeldHoursList: false,
      fetchLeastWeldHoursListError: action.payload,
    }),
    fetchLeastWeldHoursListSuccess: (state, action) => ({
      ...state,
      fetchLeastWeldHoursList: false,
      fetchLeastWeldHoursListError: null,
      least_weld_hours: action.payload.data,
    }),

    fetchLeastWeldInchesListRequest: (state) => ({
      ...state,
      fetchLeastWeldInchesList: true,
      fetchLeastWeldInchesListError: null,
    }),
    fetchLeastWeldInchesListFailure: (state, action) => ({
      ...state,
      fetchLeastWeldInchesList: false,
      fetchLeastWeldInchesListError: action.payload,
    }),
    fetchLeastWeldInchesListSuccess: (state, action) => ({
      ...state,
      fetchLeastWeldInchesList: false,
      fetchLeastWeldInchesListError: null,
      least_weld_inches: action.payload.data,
    }),

    fetchInactiveRobotsListRequest: (state) => ({
      ...state,
      fetchInactiveRobotsList: true,
      fetchInactiveRobotsListError: null,
    }),
    fetchInactiveRobotsListFailure: (state, action) => ({
      ...state,
      fetchInactiveRobotsList: false,
      fetchInactiveRobotsListError: action.payload,
    }),
    fetchInactiveRobotsListSuccess: (state, action) => ({
      ...state,
      fetchInactiveRobotsList: false,
      fetchInactiveRobotsListError: null,
      inactive_units: action.payload.data,
    }),

    fetchStatisticsPipeSizeListRequest: (state) => ({
      ...state,
      fetchStatisticsPipeSizeList: true,
      fetchStatisticsPipeSizeListError: null,
    }),
    fetchStatisticsPipeSizeListFailure: (state, action) => ({
      ...state,
      fetchStatisticsPipeSizeList: false,
      fetchStatisticsPipeSizeListError: action.payload,
    }),
    fetchStatisticsPipeSizeListSuccess: (state, action) => ({
      ...state,
      fetchStatisticsPipeSizeList: false,
      fetchStatisticsPipeSizeListError: null,
      statistics: action.payload.data,
    }),
  },
});

export const {
  fetchTopWeldHoursListRequest,
  fetchTopWeldHoursListFailure,
  fetchTopWeldHoursListSuccess,

  fetchTopWeldInchesListRequest,
  fetchTopWeldInchesListFailure,
  fetchTopWeldInchesListSuccess,

  fetchTopOperatorsWeldHoursListRequest,
  fetchTopOperatorsWeldHoursListFailure,
  fetchTopOperatorsWeldHoursListSuccess,

  fetchTopOperatorsWeldInchesListRequest,
  fetchTopOperatorsWeldInchesListFailure,
  fetchTopOperatorsWeldInchesListSuccess,

  fetchLeastWeldHoursListRequest,
  fetchLeastWeldHoursListFailure,
  fetchLeastWeldHoursListSuccess,

  fetchLeastWeldInchesListRequest,
  fetchLeastWeldInchesListFailure,
  fetchLeastWeldInchesListSuccess,

  fetchInactiveRobotsListRequest,
  fetchInactiveRobotsListFailure,
  fetchInactiveRobotsListSuccess,

  fetchStatisticsPipeSizeListRequest,
  fetchStatisticsPipeSizeListFailure,
  fetchStatisticsPipeSizeListSuccess,
} = performanceSlice.actions;

export default performanceSlice.reducer;

export const getTopWeldHoursList = (payload, query) => async (dispatch) => {
  try {
    dispatch(fetchTopWeldHoursListRequest());

    // FIXME: getLeastWeldHoursList gets identical data as getTopWeldHoursList...
    const data = await getPerformanceWeldHours(payload, query);

    dispatch(fetchTopWeldHoursListSuccess({ data }));
  } catch (err) {
    dispatch(fetchTopWeldHoursListFailure(err.toString()));
  }
};

export const getTopWeldInchesList = (payload, query) => async (dispatch) => {
  try {
    dispatch(fetchTopWeldInchesListRequest());

    // FIXME: getLeastWeldInchesList gets identical data as getTopWeldInchesList...
    const data = await getPerformanceWeldInches(payload, query);

    dispatch(fetchTopWeldInchesListSuccess({ data }));
  } catch (err) {
    dispatch(fetchTopWeldInchesListFailure(err.toString()));
  }
};

export const getTopOperatorsWeldHoursList =
  (payload, query) => async (dispatch) => {
    try {
      dispatch(fetchTopOperatorsWeldHoursListRequest());

      const data = await getPerformanceOperatorsWeldHours(payload, query);

      dispatch(fetchTopOperatorsWeldHoursListSuccess({ data }));
    } catch (err) {
      dispatch(fetchTopOperatorsWeldHoursListFailure(err.toString()));
    }
  };

export const getTopOperatorsWeldInchesList =
  (payload, query) => async (dispatch) => {
    try {
      dispatch(fetchTopOperatorsWeldInchesListRequest());

      const data = await getPerformanceOperatorsWeldInches(payload, query);

      dispatch(fetchTopOperatorsWeldInchesListSuccess({ data }));
    } catch (err) {
      dispatch(fetchTopOperatorsWeldInchesListFailure(err.toString()));
    }
  };

export const getLeastWeldHoursList = (payload, query) => async (dispatch) => {
  try {
    dispatch(fetchLeastWeldHoursListRequest());

    const data = await getPerformanceWeldHours(payload, query);

    dispatch(fetchLeastWeldHoursListSuccess({ data }));
  } catch (err) {
    dispatch(fetchLeastWeldHoursListFailure(err.toString()));
  }
};

export const getLeastWeldInchesList = (payload, query) => async (dispatch) => {
  try {
    dispatch(fetchLeastWeldInchesListRequest());

    const data = await getPerformanceWeldInches(payload, query);

    dispatch(fetchLeastWeldInchesListSuccess({ data }));
  } catch (err) {
    dispatch(fetchLeastWeldInchesListFailure(err.toString()));
  }
};

export const getInactiveRobotsList = (payload, query) => async (dispatch) => {
  try {
    dispatch(fetchInactiveRobotsListRequest());

    const data = await getPerformanceWithoutWeldHours(payload, query);

    dispatch(fetchInactiveRobotsListSuccess({ data }));
  } catch (err) {
    dispatch(fetchInactiveRobotsListFailure(err.toString()));
  }
};

export const getStatisticsPipeSizeList =
  (payload, query) => async (dispatch) => {
    try {
      dispatch(fetchStatisticsPipeSizeListRequest());

      const data = await getStatisticsPipeSize(payload, query);

      dispatch(fetchStatisticsPipeSizeListSuccess({ data }));
    } catch (err) {
      dispatch(fetchStatisticsPipeSizeListFailure(err.toString()));
    }
  };
