import React from "react";

import Performance from "../features/performance/Performance";

function PerformancePage(props) {
  return (
    <>
      <Performance {...props}></Performance>
    </>
  );
}

export default PerformancePage;
