import axios from "axios";

import { API_WRIKE_URL } from "../../libs/constants";

import { getToken } from "../../libs/auth";

// ##############################
// Wrike Tickets
// ##############################
export async function getWrikeData(params, query) {
  const queryParams = new URLSearchParams(query);
  const url = `${API_WRIKE_URL}/${params}?${queryParams.toString()}`;
  const options = {
    headers: { Authorization: `bearer ${getToken()}` },
    url,
  };
  try {
    const { data, status } = await axios(options);

    if (status === 200) {
      return data;
    } else {
      throw new Error(status);
    }
  } catch (err) {
    throw err;
  }
}
