import React from "react";

import { useParams } from "react-router-dom";

import { Tabs } from "antd";
import { filter } from "lodash";

import { getWrikeDataList } from "../../libs/utils";

import TicketsList from "./TicketsList";
import TicketsStatistics from "./TicketsStatistics";

const { TabPane } = Tabs;

function Wrike(props) {
  const { tasks = [], style = { height: 260 } } = props;

  const { robotName } = useParams();

  const robotId = robotName && robotName.match(/(\d+)/)[0];

  const data = filter(getWrikeDataList(tasks), (item) => {
    if (robotId) {
      return robotId === item.unit;
    } else {
      return item;
    }
  });

  return (
    <Tabs defaultActiveKey="2" size={"small"} type="card">
      <TabPane tab={"Statistics"} key="1" style={style}>
        <TicketsStatistics tasks={{ data: data }} />
      </TabPane>
      <TabPane tab={"Tasks List"} key="2" style={style}>
        <TicketsList tasks={data} />
      </TabPane>
    </Tabs>
  );
}
export default Wrike;
