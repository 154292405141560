import { CARD_CONFIG_STYLE } from "../../libs/constants";
import { Card } from "antd";
import { Area } from "@ant-design/charts";
import React from "react";

interface Props {
  trendsData: any[];
}
export default function StatsChartCard(props: Props) {
  const { trendsData } = props;
  // config for Trends Graph
  const config = {
    xField: "date",
    yField: "value",
    smooth: true,
    height: 60,
    width: 300,
  };

  return (
    <>
      {/*@ts-ignore*/}
      <Card {...CARD_CONFIG_STYLE} title="Statistics">
        {/* error/warning/crash statistics */}
        {/* <Card title="" bordered={false}>
          <Statistic
            title={"Error"}
            value={statsData.error.length}
            valueStyle={{ color: "red" }}
          />
        </Card>
        <Card title="" bordered={false}>
          <Statistic
            title={"Warning"}
            value={statsData.warning.length}
            valueStyle={{ color: "orange" }}
          />
        </Card> */}
        <Card title="" bordered={false} type="inner" size="small">
          <Area
            {...{
              ...config,
              seriesField: "type",
              data: trendsData,
              height: 100,
              width: 120,
              showMarkers: true,
              color: ["#fa8c16", "#f5222d"],
              line: {
                size: 1,
              },
              xAxis: {
                label: null,
              },
              yAxis: {
                label: null,
                grid: {
                  line: {
                    style: null,
                  },
                },
              },
            }}
          />
        </Card>
      </Card>
    </>
  );
}
