import React from "react";

import NovDataLogsList from "../features/reports/NovDataLogsList";
import RobotNavigation from "../features/robot/RobotNavigation";

function NovDataLogsPage() {
  return (
    <>
      <RobotNavigation />
      <NovDataLogsList />
    </>
  );
}

export default NovDataLogsPage;
