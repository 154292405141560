import jwtDecode from "jwt-decode";

import { KEY_LOCAL_JWT_TOKEN } from "./constants";

export const isLoggedIn = () => {
  const token = getToken();

  return !!token;
};

export const getToken = () => localStorage.getItem(KEY_LOCAL_JWT_TOKEN);

export const setToken = (token) =>
  localStorage.setItem(KEY_LOCAL_JWT_TOKEN, token);

export const getUser = () => {
  const loggedIn = isLoggedIn();
  if (loggedIn) {
    const token = getToken();
    if (token) {
      try {
        return jwtDecode(token);
      } catch (err) {
        console.log("getUser(): err", err);
      }
    }
  // FIXME no return statement
  }
};

export const clearUser = () => {
  localStorage.removeItem(KEY_LOCAL_JWT_TOKEN);
};

export const isAdmin = () => {
  const user = getUser();

  if (user && user.role === "admin") {
    return true;
  }

  return false;
};

export const isSupport = () => {
  const user = getUser();

  if (user && user.role === "support") {
    return true;
  }

  return false;
};

export const isCustomer = () => {
  const user = getUser();

  if (user && user.role === "customer") {
    return true;
  }

  return false;
};
