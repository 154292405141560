import axios from "axios";

// ##############################
// Data extraction from S3
// ##############################
export async function getFromS3URL(url) {
  const response = await axios({ url });

  if (response.status === 200) {
    return response.data;
  }
  console.error("Error getting from S3 url", url);
  console.error("Error:", response.status, response);
  return null;
}
