import mockSummary from "./mock/mockSummary.json";
import mockCoordinates from "./mock/mockCoordinates.json";

//this cannot yet be destroyed because some components depend on the hardcoded swr_information data
export const robots_with_coordinates = mockCoordinates.map((item) => {
  const swr_information = mockSummary.find((i) => {

    if (i.key === "SWR-185") {
      return (i.key === "SWR-185") === (item.name === "SWR-1805");
    } else if (i.key === "SWR-1914B") {
      return (i.key === "SWR-1914B") === (item.name === "SWR-1914");
    } else {
      return i.key === item.name;
    }
  });

  return {
    ...item,
    swr_information:
      (swr_information && swr_information.unit_summary.slice(3)) || [],
  };
});

